import { PalletteProps } from './../../types/theme/pallette';
import styled from 'styled-components';

export const Row = styled.div`
  margin: 0 auto;
  width: 100vw;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    box-sizing: border-box;
    margin: 0 auto;
    width: 100vw;
    padding: 0;
    position: relative;
  }

  .categoriesButtonDiv {
    display: flex;
    justify-content: center;
    @media ${({ theme }: PalletteProps) => theme.breakpoints.tabletL} {
      display: none;
    }
    @media ${({ theme }: PalletteProps) => theme.breakpoints.tabletSmax} {
      display: none;
    }
  }
  .searchBarBody {
    @media ${({ theme }: PalletteProps) => theme.breakpoints.laptopMmax} {
      display: flex;
      justify-content: left;
    }
    @media ${({ theme }: PalletteProps) => theme.breakpoints.laptopSmax} {
      justify-content: center;
    }
    @media ${({ theme }: PalletteProps) => theme.breakpoints.tabletSmax} {
      display: none;
    }
  }
`;
