import { Pharagraph, Subtitle, Title, Wrapper } from '../Forms.styled';

import { Anchor } from '../../../shared/Anchor';
import type { state } from '../../../types/store/state';
import { useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';

interface Props {
  email: string;
};

export const EmailConfirm = ({ email }: Props) => {
  const { t } = useTranslation();
  const storeName = useSelector((state: state) => state.ui.name);

  return (
    <Wrapper>
      <Title>
        {t('confirmEmail.title')}
      </Title>
      <Subtitle>
        {t('confirmEmail.thanksJoin.title')}{storeName}!</Subtitle>
      <Pharagraph>
        {t('confirmEmail.thanksJoin.description.plzConfirm')}
        <strong>{email}</strong> {t('confirmEmail.thanksJoin.description.isYourEmail')}
      </Pharagraph>
      <Anchor title={t('confirmEmail.action.goToLogin')} size='big' url='/login/' />
    </Wrapper>
  );
};
