import { Button, Input } from '../../../shared';
import {
  ButtonsWrapper,
  CancelButton,
  Row,
  RowTitle,
  Subtitle,
  Wrapper
} from './ChangePasswordForm.styled';
import { FormEvent, useState } from 'react';
import { changePasswordType, defaultChangePasswordType } from '../types/changePassValidations';

import { ErrorText } from '../Forms.styled';
import { changePassword } from '../../../api/users';
import { toast } from 'react-toastify';
import { useTranslation } from 'next-i18next';

export const ChangePasswordForm = () => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState<changePasswordType>(defaultChangePasswordType);

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setErrors(defaultChangePasswordType);

    const target = event.target as any;

    try {
      await toast.promise(
        changePassword({
          params: {
            oldPassword: target.oldPassword.value,
            newPassword1: target.newPassword1.value,
            newPassword2: target.newPassword2.value,
          }
        }),
        {
          pending: `${t('password.change.toastify.pending')}`,
          success: `${t('password.change.toastify.success')}`,
          error: `${t('password.change.toastify.error')}`
        }
      );
      await target.reset();
    } catch (error: any) {
      if (error.response.status === 400) {
        setErrors({
          ...errors,
          ...error.response.data,
        });
        return;
      }
      throw new Error(error);
    }
  };

  return (
    <form onSubmit={onSubmit} >
      <Wrapper>
        <Subtitle>
          {t('password.information.title')}
        </Subtitle>
        <Row>
          <RowTitle>{t('password.oldPass.title')}</RowTitle>
          <Input
            name="oldPassword"
            type="password"
            placeholder={t('password.oldPass.placeholder')}
            error={errors.oldPassword}
            required
          />
        </Row>
        <Row>
          <RowTitle>{t('password.createNewPass.title')}</RowTitle>
          <Input
            name="newPassword1"
            type="password"
            placeholder={t('password.createNewPass.placeholder')}
            error={errors.newPassword1}
            required
          />
        </Row>
        <Row>
          <RowTitle>{t('password.confirmNewPass.title')}</RowTitle>
          <Input
            name="newPassword2"
            placeholder={t('password.confirmNewPass.placeholder')}
            type="password"
            error={errors.newPassword2}
            required
          />
        </Row>
        {errors.nonFieldErrors.length ? <ErrorText>{errors.nonFieldErrors[0]}</ErrorText> : null}
      </Wrapper>
      <ButtonsWrapper>
        <CancelButton
          title={t('shared.action.cancel')}
          type="reset"
        />
        <Button
          title={t('shared.action.save')}
          type="submit"
        />
      </ButtonsWrapper>
    </form>
  );
};