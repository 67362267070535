import { PalletteProps } from '../../types/theme/pallette';
import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
  color: ${({ theme }: PalletteProps) => theme.gray2};
  padding-left: 44px;
  margin-bottom: 31px;
`;

export const AddressesList = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  justify-items: center;
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    grid-template-columns: repeat(2, 1fr);
    justify-items: space-between;
  }
`;
