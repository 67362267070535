import { ClickButton } from '../../shared/Anchor/Anchor.styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PalletteProps } from '../../types/theme/pallette';
import { QuantityWrapper } from '../../shared/QuantityButton/QuantityButton.styled';
import { Textarea } from '../../shared/Textarea';
import { rgba } from 'polished';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 32px;
  margin: 10px 0px 120px;
  padding: 0px 10px;
  width: 100%;
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    padding: 0px 30px;
  }
  @media ${({ theme }: PalletteProps) => theme.breakpoints.tablet} {
    grid-template-columns: 1fr 1fr;
    grid-gap: 72px;
    margin-bottom: 74px;
    padding: 0px 0px;
  }
  .h3-locations-msg {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 10px;
  }
  .h4-locations-msg {
    border: dashed 2px lightgray;
    padding: 10px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 40px;
  }
`;

export const Detail = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Title = styled.h1`
  font-size: 1.5625rem;
  color: ${({ theme }: PalletteProps) => theme.black};
  line-height: 2.2rem;
  margin-bottom: 24px;
  text-align: left;
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    font-size: 2.125rem;
    margin-bottom: 20px;
  }
`;

export const IsProdActiveTitle = styled.h6`
  color: ${({ theme }: PalletteProps) => theme.nonProductActiveColor};
  line-height: 1.8rem;
  text-align: center;
  margin: 10px 0px;
  background-color: ${({ theme }: PalletteProps) =>
    rgba(theme.nonProductActiveColor, 0.18)};
  border-radius: 10px;
  padding: 3px 10px;
  width: fit-content;
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    padding: 6px 10px;
  }
`;

export const PriceWrapper = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 24px;
  flex-direction: row;
  justify-content: flex-start;
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobileL} {
    justify-content: flex-start;
  }
`;

export const Price = styled.h3`
  font-size: 1.125rem;
  line-height: 1.125;
  font-weight: 700;
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    font-size: 1.875rem;
  }
`;

export const PriceDiscount = styled.h3`
  font-size: 1.5rem;
  font-weight: 700;
  color: ${({ theme }: PalletteProps) => theme.red1};
  text-decoration-line: line-through;
`;

export const Units = styled.p`
  font-size: 0.875rem;
  color: ${({ theme }: PalletteProps) => theme.gray3};
  font-weight: 500;
  font-style: italic;
  margin-bottom: 30px;
  text-align: center;
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobileL} {
    text-align: left;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1.5px;
  background-color: ${({ theme }: PalletteProps) => theme.gray4};
  margin-bottom: 23px;
`;

export const Description = styled.p`
  font-size: 0.875rem;
  text-align: justify;
  font-weight: 300;
  margin-bottom: 23px;
  color: ${({ theme }: PalletteProps) => theme.gray2};
`;

export const ActionItems = styled.div`
  width: inherit;
  position: sticky;
  z-index: 1;
  bottom: 0;
  margin: 10px -15px 0px -15px;
  padding: 1rem;
  background-color: ${({ theme }: PalletteProps) => theme.backgroundColor};
  border-top: solid 1px ${({ theme }: PalletteProps) => theme.gray5};
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  &:hover {
    transition: ease all 500ms;
    border-top: solid 1px ${({ theme }: PalletteProps) => theme.gray6};
  }
  ${ClickButton} {
    max-width: 283px;
    width: 50%;
    padding: 10px 10px;
  }
  ${QuantityWrapper} {
    max-width: 283px;
    width: 50%;
  }
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobileL} {
    gap: 23px;
  }
  @media ${({ theme }: PalletteProps) => theme.breakpoints.tablet} {
    justify-content: flex-start;
    ${ClickButton} {
      max-width: 218px;
    }
    ${QuantityWrapper} {
      max-width: 218px;
    }
  }
`;

export const TextAreaWrapper = styled.div`
  max-width: 100%;
`;

export const TopItems = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 5px;
  color: ${({ theme }: PalletteProps) => theme.black};
  font-size: 0.875rem;
  font-weight: 500;
`;

export const EditObsnIcon = styled(FontAwesomeIcon)`
  background: none;
  border: none;
  width: 1rem;
  height: 1rem;
  font-weight: 600;
  margin-right: 10px;
  color: ${({ theme }: PalletteProps) => theme.gray3};
`;

export const TextAreaTitle = styled.p``;

export const ObsTextArea = styled(Textarea)`
  resize: none;
  margin-bottom: 10px;
  height: 5rem;
  max-width: 100%;
  font-style: italic;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1rem;
  color: ${({ theme }: PalletteProps) => theme.gray3};
  background-color: ${({ theme }: PalletteProps) => theme.gray7};
  border: 1px solid ${({ theme }: PalletteProps) => theme.gray6};
  padding: 13px 13px;
  &:focus {
    outline-style: none;
    font-style: normal;
    color: ${({ theme }: PalletteProps) => theme.black};
  }
  &::placeholder {
    color: ${({ theme }: PalletteProps) => theme.gray3};
  }
`;
