import {
  Action,
  Menu,
} from '../../components/Account/AccountDropDown/AccountDropDown.styled';

import { AnchorButton } from '../../shared/Anchor/Anchor.styled';
import { PalletteProps } from '../../types/theme/pallette';
import styled from 'styled-components';

export const SidebarBackdrop = styled.div`
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 797;
  @media ${({ theme }: PalletteProps) => theme.breakpoints.tablet} {
    display: none;
  }
`;

export const SidebarWrapper = styled.div`
  width: 360px;
  margin-right: 122px;
  z-index: 1000;
  background-color: ${({ theme }: PalletteProps) => theme.white};
  padding-top: 46px;
  box-shadow: 0px 0px 50px rgba(200, 200, 200, 0.2);
  border-radius: 8px;
  ${AnchorButton} {
    padding: 0;
    width: 107px;
    height: 45px;
  }
  ${Action} {
    padding: 0;
    top: 0;
    left: 0;
  }
  ${Menu} {
    top: 10px;
    left: -10px;
    border: 0;
    position: relative;
  }
  @media ${({ theme }: PalletteProps) => theme.breakpoints.laptopLmax} {
    margin-right: 20px;
    width: 320px;
  }
  @media ${({ theme }: PalletteProps) => theme.breakpoints.laptopSmax} {
    display: none;
  }
`;

export const SidebarCloseButton = styled.button`
  position: absolute;
  top: 17px;
  right: 19px;
  font-size: 36px;
  text-decoration: none;
  background: transparent;
  border: none;
  outline: 0;
  cursor: pointer;
  padding: 8px 12px;
  background-color: ${({ theme }: PalletteProps) => theme.gray5};
  color: ${({ theme }: PalletteProps) => theme.darkGray};
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const SidebarDivider = styled.div`
  max-width: 100%;
  height: 1.5px;
  background-color: ${({ theme }: PalletteProps) => theme.gray4};
  margin: 20px;
  margin-left: -10px;
`;

export const CategoriesTitle = styled.h2`
  font-size: 20px;
  font-weight: 900;
  line-height: 24.2px;
  margin-left: 30px;
`;

export const CategoriesSidebarMenu = styled.ul`
  padding: 0;
  padding-bottom: 25px;
  margin-bottom: 40px;
  list-style: none;
  color: ${({ theme }: PalletteProps) => theme.black};
  .sidebarActiveCategory {
    background-color: ${({ theme }: PalletteProps) => theme.gray9};
  }
`;

export const MenuItemSidebar = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 30px;
  font-size: 1rem;
  transition: ease all 200ms;
  &:hover {
    background: ${({ theme }: PalletteProps) => theme.gray10};
  }
  &:active {
    background-color: ${({ theme }: PalletteProps) => theme.gray9};
  }
  .sidebarAngleIcon {
    color: ${({ theme }: PalletteProps) => theme.primaryColor};
  }
`;

export const CategoryProductCount = styled.div`
  color: ${({ theme }: PalletteProps) => theme.gray11};
`;
