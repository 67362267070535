import {
  DetailsWrapper,
  Element,
  Icon,
  ImageWrapper,
  PriceDiscount,
  PriceWrapper,
  ProductName,
  ProductPrice,
} from './SearchResult.styled';

import Image from 'next/image';
import Link from 'next/link';
import { ProductType } from '../../types/product';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

export const SearchResult = (props: ProductType) => {

  return (
    <Link
      href={{
        pathname: '/product/[psid]',
        query: { psid: props.id },
      }}
      passHref
    >
      <Element>
        <ImageWrapper>
          <Image
            src={props.product.images[0].image}
            alt={props.product.name}
            layout="fill"
            objectFit="contain"
          />
        </ImageWrapper>
        <DetailsWrapper>
          <ProductName>{props.product.name}</ProductName>
        </DetailsWrapper>
        <PriceWrapper>
          {props.product.discountPercent > 0 ? (
            <PriceDiscount>
              {`${props.product.currency.symbol} ${props.product.fullPrice}`}
            </PriceDiscount>
          ) : null}
          <ProductPrice>
            {`${props.product.currency.symbol} ${props.product.price}`}
          </ProductPrice>
        </PriceWrapper>
        <Icon icon={faAngleRight} width={12} />
      </Element>
    </Link>
  );
};

SearchResult.defaultProps = {
  product: {
    discountPercent: 0,
    discountPrice: 0,
  },
};
