import { ButtonElement } from '../../../shared/Button/Button.styled';
import { PalletteProps } from '../../../types/theme/pallette';
import { pallette as palletteType } from '../../../types/theme/pallette';
import styled from 'styled-components';

interface props {
  theme: palletteType;
}

export const AddressWrapper = styled.section`
  max-width: 600px;
  width: 100%;
  margin: 0;
  padding: 31px 44px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 0px 50px rgba(200, 200, 200, 0.2);
`;

export const AddressTitle = styled.h1`
  font-size: 1.5rem;
  color: ${({ theme }: props) => theme.gray2};
  margin-bottom: 25px;
  text-align: left;
  font-weight: 600;
`;

export const ButtonWrapper = styled.div`
  max-width: 600px;
  width: 100%;
  margin: 0;
  margin-top: 31px;
  margin-bottom: 150px;
  display: flex;
  flex-direction: row;
  justify-content: right;
  ${ButtonElement} {
    width: 113px;
    padding: 11px 37px;
    text-transform: none;
    font-weight: 700;
    font-size: 16px;
    line-height: 19.36px;
  }
  #cancelButton {
    background-color: ${({ theme }: props) => theme.gray4};
    color: ${({ theme }: props) => theme.gray2};
    margin-right: 9px;
    &:hover {
      color: ${({ theme }: PalletteProps) => theme.gray2};
    }
  }
`;

export const SubmitButton = styled.button`
  color: ${({ theme }: props) => theme.primaryColor};
  border: none;
  background-color: transparent;
  font-size: 1rem;
  font-weight: bold;
  &:hover {
    cursor: pointer;
  }
`;
export const CancelButton = styled.button`
  color: ${({ theme }: props) => theme.gray2};
  border: none;
  background-color: transparent;
  font-size: 1rem;
  &:hover {
    cursor: pointer;
  }
`;

export const AddAddressTitle = styled.p`
  font-size: 1rem;
  color: ${({ theme }: props) => theme.primaryColor};
  margin-left: 33px;
  margin-top: 40px;
  margin-bottom: 73px;
  font-weight: 700;
  line-height: 19.36px;
  &:hover {
    cursor: pointer;
    user-select: none;
  }
`;


export const AddressList = styled.ul`
  position: absolute;
  width: 100%;
  background: white;
  box-shadow: 0px 4px 50px rgba(200, 200, 200, 0.2);
  max-height: 75vh;
  border-radius: 5px;
  padding-left: 0;
  z-index: 10;
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    max-height: 542px;
  }
  li {
    list-style: none;
    padding: .5rem 1rem;
    transition: ease all 200ms;
    &:hover {
      background-color: ${({ theme }: props) => theme.lightGray};
      cursor: pointer
    }
  }
`;