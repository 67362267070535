import {
  AnchorButton,
  ClickButton,
} from '../../../shared/Anchor/Anchor.styled';

import { NotificationWrapper } from '../../Notification/FlowChekoutAndCartNotification.styled';
import { PalletteProps } from '../../../types/theme/pallette';
import { Wrapper as WrapperStep } from '../Step/Step.styled';
import styled from 'styled-components';

export const Main = styled.section`
  width: 100%;
`;

export const Top = styled.div`
  margin-bottom: 51px;
`;

export const TopRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 136px;
  margin-bottom: 13px;
  align-items: center;
  padding-left: 0px;
  gap: 28px;
  ${AnchorButton} {
    width: auto;
    padding: 10px 6px;
    font-size: 0.625rem;
    .faIcon {
      width: 12px;
    }
  }
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    grid-template-columns: 2fr 195px;
    ${AnchorButton} {
      padding: 10px;
      font-size: 0.75rem !important;
      .faIcon {
        width: 16px;
      }
    }
  }
`;

export const Title = styled.h1`
  font-size: 1.5625rem;
  font-weight: 900;
  color: ${({ theme }: PalletteProps) => theme.gray2};
  padding-left: 26px;
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    font-size: 1.875rem;
  }
`;

export const Content = styled.div`
  ${WrapperStep} {
    padding: 20px 10px;
  }
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    ${WrapperStep} {
      padding: 48px 70px;
    }
  }
  @media ${({ theme }: PalletteProps) => theme.breakpoints.tablet} {
    display: grid;
    grid-template-columns: 8fr 4fr;
    grid-gap: 45px;
  }
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 27px;
`;

export const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  gap: 27px;
  ${ClickButton} {
    width: 340px;
    align-self: center;
    margin: 16px 0;
  }
  ${AnchorButton} {
    width: auto;
    margin: 0;
    padding: 10px 15px;
    text-transform: capitalize;
    font-weight: 600;
    align-self: center;
    .faIcon {
      margin-right: 8px;
      width: 15px;
    }
  }
  ${NotificationWrapper} {
    padding: 15px 10px 0px 10px;
    font-size: 0.75rem;
  }
  @media ${({ theme }: PalletteProps) => theme.breakpoints.tablet} {
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    gap: 27px;
    ${ClickButton} {
      width: 100%;
    }
    ${AnchorButton} {
      padding: 10px 50px;
      .faIcon {
        width: 20px;
      }
    }
    ${NotificationWrapper} {
      padding: 15px 10px 48px 10px;
      font-size: 0.875rem;
    }
  }
`;
