import { Button, Input } from '../../../shared';
import {
  EditUserType,
  defaultEditUserType,
} from '../../../components/Account/types/editUserValidations';
import {
  ErrorText,
  FullRow,
  Pharagraph,
  RecoverPassAnchor,
  Title,
  Wrapper,
} from '../Forms.styled';
import { FormEvent, useState } from 'react';

import Link from 'next/link';
import { resetPassword } from '../../../api/users';
import { useTranslation } from 'next-i18next';

export const RequestRecoverPasswordForm = () => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState<EditUserType>(defaultEditUserType);
  const [isSent, setIsSent] = useState(false);

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const origin =
      typeof window !== 'undefined' && window.location.origin
        ? window.location.origin
        : '';
    const addURL = '/reset-password';
    const targetURL = `${origin}${addURL}`;

    setErrors(defaultEditUserType);

    const target = event.target as any;

    try {
      await resetPassword({
        params: {
          email: target.email.value,
          redirectUrl: targetURL,
        },
      });
      await setIsSent(true);
    } catch (error: any) {
      if (error.response.status >= 400) {
        setErrors({
          ...error,
          ...error.response.data,
        });
        return;
      }
      throw new Error(error);
    }
  };

  return (
    <Wrapper $isResetPass>
      {isSent ? (
        <>
          <Title>{t('login.recover.requestSent.title')}</Title>
          <Pharagraph $isSent>
            {t('login.recover.requestSent.description')}
          </Pharagraph>
        </>
      ) : (
        <>
          <Title>{t('login.recover.request.title')}</Title>
          <Pharagraph>{t('login.recover.request.description')}</Pharagraph>
          <form onSubmit={onSubmit}>
            <FullRow>
              <Input
                name="email"
                type="email"
                placeholder={t('login.recover.request.placeholder')}
                error={errors.email}
                required
              />
            </FullRow>
            {errors.nonFieldErrors?.length ? (
              <ErrorText>{errors.nonFieldErrors[0]}</ErrorText>
            ) : null}
            <Button
              title={t('login.recover.request.action.sendRequest')}
              type="submit"
            />
            <Link href="/login" passHref>
              <RecoverPassAnchor>
                {t('login.recover.action.backLogin')}
              </RecoverPassAnchor>
            </Link>
          </form>
        </>
      )}
    </Wrapper>
  );
};
