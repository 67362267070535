import { Note, NotificationWrapper } from './FlowChekoutAndCartNotification.styled';

import { useTranslation } from 'next-i18next';

export const FlowChekoutAndCartNotification = () => {
  const { t } = useTranslation();

  return (
    <NotificationWrapper>
      <Note>
        {t('flow.checkout.cart.notification.handleCosts')}
      </Note>
      <Note>
        {t('flow.checkout.cart.notification.disclosure')}
      </Note>
      <Note>
        {t('flow.checkout.cart.notification.time')}
      </Note>
    </NotificationWrapper>
  );
};