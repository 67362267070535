import { deleteRequest, getRequest, postRequest, putRequest } from '../services/http';

import type { dataOptions } from './types/data';

export const retreiveUserAddresses = async () => {
  const response = await getRequest({
    url: 'auth/user/addresses/',
    protected: true,
    urlGeoapi: '',
  });
  return response;
};

export const setPrimaryAddress = async (data: dataOptions) => {
  const response = await postRequest({
    url: `auth/user/addresses/${data.id}/make-primary/`,
    protected: true,
    urlGeoapi: '',
  });
  return response;
};

export const createAddress = async (data: dataOptions) => {
  const response = await postRequest({
    url: 'auth/user/addresses/',
    protected: true,
    data: data.params,
    urlGeoapi: '',
  });
  return response;
};

export const editAddress = async (data: dataOptions) => {
  const response = await putRequest({
    url: 'auth/user/addresses/',
    protected: true,
    data: data.params,
    urlGeoapi: '',
  });
  return response;
};

export const deleteAddress = async (data: dataOptions) => {
  const response = await deleteRequest({
    url: `addresses/${data.id}/`,
    protected: true,
    urlGeoapi: '',
  });
  return response;
};

export const getLocationFromZipcode = async (data: dataOptions) => {
  const response = await postRequest({
    url: 'addresses/zipcode/',
    data: data.params,
    protected: true,
    urlGeoapi: '',
  });
  return response;
};

export const searchAddress = async (data: dataOptions) => {
  const response = await getRequest({
    url: `addresses/autocomplete_address/?query=${data.query}`,
    protected: true,
    urlGeoapi: '',
  });
  return response;
};

export const getLocationFromCoords = async (data: any) => {
  const response = await getRequest({
    url: `locations/latitude_longitude/?longitude=${data.query.longitude}&latitude=${data.query.latitude}`,
    geoapi: true,
    protected: true,
    urlGeoapi: '',
  });
  return response;
};

