import styled from 'styled-components';

export const Wrapper = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 38px;
  max-width: 600px;
  width: 100%;
  margin: 60px auto 42px auto;
`;
