import {
  AttrBox,
  AttrElement,
  AttrTitle,
  AttrWrapper,
  Content,
  ImageWrapper,
  Item,
  Price,
  PriceDiscount,
  PriceWrapper,
  Title,
  Top,
} from './CartProduct.styled';

import { AddToCart } from '../AddToCart';
import { CartObservation } from '../CartObservation/CartObservation';
import { DeleteProductOnCart } from '../DeleteProductOnCart/DeleteProductOnCart';
import { Fragment } from 'react';
import Image from 'next/image';
import type { ProductType } from '../../../types/product';
import { useTranslation } from 'next-i18next';

export const CartProduct = (props: ProductType) => {
  const { t } = useTranslation();

  return (
    <Item>
      <Top>
        <ImageWrapper>
          <Image
            src={props.product.images[0].image}
            alt={props.product.name}
            layout="fill"
            objectFit="contain"
          />
        </ImageWrapper>
        <Content>
          <Title>{props.product.name}</Title>
          {/* <Units>{t('shared.product.unit.meassure')}pz</Units> */}
        </Content>
        <AddToCart
          storeBranchProductId={props.product.storeBranchProductId}
          customCuantity={props.quantity}
          added={props.quantity > 0}
          orderBranchProductId={props.id}
          observation={props.observation}
          dishAttributes={props.attributes}
          isDish={props.product.isDish}
        />
        <PriceWrapper>
          {props.product.discountPercent > 0 ? (
            <PriceDiscount>
              {`${props.product.currency.symbol}${props.product.fullPrice}`}
            </PriceDiscount>
          ) : null}
          <Price>
            {`${props.product.currency.symbol}${props.product.price.toFixed(
              2
            )}`}
          </Price>
        </PriceWrapper>
      </Top>
      <AttrWrapper>
        {Object.keys(props.attributes).map((attr: string) => (
          <AttrBox key={attr}>
            <AttrTitle>{t(`DishAttributes.${attr}.title`)}</AttrTitle>
            {props.attributes[attr].map((elem, index: number) => {
              return (
                <AttrElement key={elem.name}>
                  {`${elem.name}`}{' '}
                  {attr === 'extras'
                    ? `${props.product.currency.symbol}${elem.price}`
                    : null}
                  {index < props.attributes[attr].length - 1 ? ',' : ' '}
                </AttrElement>
              );
            })}
          </AttrBox>
        ))}
      </AttrWrapper>
      <Fragment key={props.product.storeBranchProductId}>
        <DeleteProductOnCart {...props} />
      </Fragment>
      <Fragment key={props.observation}>
        <CartObservation {...props} />
      </Fragment>
    </Item>
  );
};
