import React, { useEffect } from 'react';

import { Checkbox } from '../../../shared/Checkbox';
import { Wrapper, } from './Wallet.styled';
import { retrieveWallet } from '../../../api/wallet';
import { useTranslation } from 'next-i18next';

interface Props {
  useWallet: boolean;
  setUseWallet: (useWallet: boolean) => void;
  walletAmmount: number;
  setWalletAmmount: (walletAmmount: number) => void;
}

export const Wallet = ({ useWallet, setUseWallet, walletAmmount, setWalletAmmount }: Props) => {
  const { t } = useTranslation();

  const walletTitle = t('checkout.wallet.title', { balance: walletAmmount});

  useEffect(() => {
    getWallet();
  }, []);

  const getWallet = async () => {
    try {
      const response = await retrieveWallet();
      setWalletAmmount(response.balance);
    } catch (error: any) {
      console.error(error);
    }
  };

  const onChange = () => {
    setUseWallet(!useWallet);
  };

  return (
    <Wrapper>
      <Checkbox
        name="shapya-wallet"
        id="shapya-wallet"
        label={walletTitle}
        onChange={() => onChange()}
        checked={useWallet}
      />
    </Wrapper>
  );
};
