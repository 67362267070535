import type { OrderCost, OrderDeliveryType } from '../../../types/orders';
import { TipsWrapper, Title, TotalText, Wrapper } from './Tips.styled';
import { useEffect, useState } from 'react';

import type { MouseEvent } from 'react';
import { TipSquare } from './TipSquare';
import { useTranslation } from 'next-i18next';

type TipOptions = 0 | 10 | 15 | 20 | 25;
interface Props {
  orderType: OrderDeliveryType;
  calculatedTip: number;
  setCalculatedTip: (elem: number) => void;
  orderCost: OrderCost | null;
}
const options: Array<TipOptions> = [0, 10, 15, 20, 25];

export const Tips = ({ orderType, calculatedTip, setCalculatedTip, orderCost }: Props) => {
  const { t } = useTranslation();
  const [tip, setTip] = useState<TipOptions>(15);

  useEffect(() => {
    if (orderCost) {
      const amount = (tip * orderCost.productsCost) / 100;
      setCalculatedTip(amount);
    }
  }, [orderCost?.productsCost, tip]);

  useEffect(() => {
    if (orderType === 'pickup') {
      setTip(0);
    } else {
      setTip(15);
    }
  }, [orderType]);

  if (!orderCost) {
    return null;
  }

  const handleClick = (event: MouseEvent, option: TipOptions) => {
    event.preventDefault();
    const amount = (tip * orderCost.productsCost) / 100;
    setCalculatedTip(amount);
    setTip(option);
  };

  const totalWithTip = (calculatedTip + orderCost.productsCost).toFixed(2);

  return (
    <Wrapper>
      <Title>{t('checkout.tipSelect.title')}</Title>
      <TipsWrapper>
        {options.map((element) =>
          <TipSquare key={element} option={element} tip={tip} handleClick={handleClick} />
        )}
      </TipsWrapper>
      <TotalText>
        {tip === 0
          ?
          `${t('checkout.withoutTipSubtotal.title')} ${orderCost.currency.symbol}${totalWithTip}`
          : `${t('checkout.tipSubtotal.title')} ${orderCost.currency.symbol}${totalWithTip}`
        }
      </TotalText>
    </Wrapper>
  );
};
