import { ErrorText, FormInput, Label, Wrapper } from './Input.styled';
import { FC, InputHTMLAttributes } from 'react';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  error?: Array<string>;
}

export const Input: FC<InputProps> = ({
  name,
  label = '',
  error = [],
  ...rest
}) => {
  return (
    <Wrapper>
      {label.length ? <Label htmlFor={name}>{label}</Label> : null}
      <FormInput id={name} {...rest} />
      {error.length ? <ErrorText>{error[0]}</ErrorText> : null}
    </Wrapper>
  );
};
