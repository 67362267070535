import {
  FooterBottom,
  FooterBsDetailText,
  FooterColumnsTitles,
  FooterDivider,
  FooterInner,
  FooterMain,
  FooterWrapper,
  StoreLogo,
} from './Footer.styled';

import { FooterBsSchedule } from './FooterBsSchedule';
import { FooterBsSocialMedia } from './FooterBsSocialMedia';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import { imagePath } from '../../utils';
import type { state } from '../../types/store/state';
import { useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';

export const Footer = () => {
  const { t } = useTranslation();
  const mainLogo = useSelector((bsMainLogo: state) => bsMainLogo.ui.mainLogo);

  const branchStoreDetail = useSelector((state: state) => state.branchStore);

  return (
    <FooterWrapper>
      <FooterInner>
        <FooterMain>
          <StoreLogo>
            <Image
              src={imagePath(mainLogo?.attributes?.url)}
              alt={branchStoreDetail.store.name}
              layout="fill"
              objectFit="contain"
            />
          </StoreLogo>
          <div>
            <FooterColumnsTitles>
              {t('footer.address.title')}
            </FooterColumnsTitles>
            <FooterBsDetailText>
              {branchStoreDetail?.address?.address}
            </FooterBsDetailText>
          </div>
          <FooterBsSchedule />
          <FooterBsSocialMedia />
        </FooterMain>
        <FooterDivider />
        <FooterBottom>
          <p>
            {t('footer.bottom.title.poweredBy')}{' '}
            <Link href="https://shapya.com/" passHref>
              <a target="_blank">
                <u>{t('footer.bottom.title.shapyaInc')}</u>
              </a>
            </Link>
            {t('footer.bottom.title.copyRight')}
          </p>
        </FooterBottom>
      </FooterInner>
    </FooterWrapper>
  );
};
