import type { DishAttrType, DishAttributesType } from '../../../types/product';
import { MouseEvent, useEffect, useState } from 'react';
import { addOrUpdateCart, deleteProductCart } from '../../../api/cart';
import { useDispatch, useSelector } from 'react-redux';

import { Anchor } from '../../../shared/Anchor';
import { QuantityButton } from '../../../shared/QuantityButton';
import { setCart } from '../../../store/cart/cartSlice';
import type { state } from '../../../types/store/state';
import { toast } from 'react-toastify';
import { useTranslation } from 'next-i18next';

interface Props {
  orderBranchProductId?: number;
  storeBranchProductId: number;
  customCuantity?: number;
  added?: boolean;
  dishAttributes?: DishAttrType;
  observation?: string;
  isDish?: boolean;
  productUrl?: number;
  setDishAttributes?: (attr: DishAttrType) => void;
  setObservation?: (attr: string) => void;
  attributes: DishAttributesType;
  disabled?: boolean;
}

export const AddDishToCart = ({
  customCuantity,
  added,
  storeBranchProductId,
  observation,
  dishAttributes,
  setDishAttributes,
  setObservation,
  orderBranchProductId,
  attributes,
  disabled,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const cartReference = useSelector((state: state) => state.cart.reference);

  const [count, setCount] = useState(1);
  const [, setAdded] = useState(false);

  useEffect(() => {
    if (customCuantity) {
      setCount(1);
      setDishAttributes?.({});
      setObservation?.('');
    }
    if (added) setAdded(added);
  }, [customCuantity, setDishAttributes, setObservation, added]);

  const validateMandatory = () => {
    if (!dishAttributes) {
      return true;
    }

    const mandatoryItems = attributes ? Object.keys(attributes).filter((attr) => attributes[attr].mandatory === 'true') : [];
    const selectedItems = Object.keys(dishAttributes).sort();

    if (!mandatoryItems.length) {
      return true;
    }

    if (mandatoryItems.length > 0 && (mandatoryItems.length <= selectedItems.length)) {
      let mandatorySelected = 0;
      for (let i = 0; i < mandatoryItems.length; i++) {
        const elem = selectedItems.filter((attr) => attr === mandatoryItems[i]);
        if (elem?.length && dishAttributes[elem[0]].length) {
          mandatorySelected += 1;
        }
      }
      if (mandatorySelected === mandatoryItems.length) {
        return true;
      }
    }
    return false;
  };

  const handleCart = async (event: MouseEvent) => {
    event.preventDefault();
    if (!validateMandatory()) {
      toast.warning(`${t('DishAttributes.mustSelectAttr.toastify.error.title')}`, { icon: '⚠️' },);
      return;
    }
    setAdded(true);
    let cart;

    if (count <= 0) {
      cart = await toast.promise(
        deleteProductCart({
          id: cartReference,
          params: { orderBranchProduct: orderBranchProductId },
        }),
        {
          pending: { render: () => t('cart.product.delete.toastify.pending'), icon: '⚠️' },
          success: { render: () => t('cart.product.delete.toastify.success'), icon: '✅' },
          error: { render: ({ data }: any) => `${data.response?.status > 400 ? t('cart.product.delete.toastify.error') : data.message}`, icon: '❗' },
        }
      );
      setAdded(false);
      setCount(1);
      dispatch(setCart(cart));
      return;
    }

    cart = await addOrUpdateCart({
      id: cartReference,
      params: {
        storeBranchProduct: storeBranchProductId,
        quantity: count,
        observation: observation,
        attributes: dishAttributes || {},
      },
    });

    setDishAttributes?.({});
    setObservation?.('');
    setCount(1);
    dispatch(setCart(cart));

  };

  return (
    <>
      <QuantityButton
        handleAddToCart={() => { }}
        setCount={setCount}
        count={count}
        setAdded={setAdded}
        disabled={disabled}
      />
      {count > 0 && (
        <Anchor
          title={t('shared.product.action.addToCart')}
          size="medium"
          onClick={handleCart}
          disabled={disabled}
        />
      )}
    </>
  );
};
