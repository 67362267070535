import type { dataOptions } from './types/data';
import { getRequest } from '../services/http';

export const searchProducts = async (data: dataOptions) => {
  const response = await getRequest({
    url: `branch-stores/${data.id}/products/?search=${data.term}`,
    urlGeoapi: '',
  });

  return response;
};

export const retreiveBranchStoreDetail = async (data: dataOptions) => {
  const response = await getRequest({
    url: `branch-stores/${data.id}/`,
    urlGeoapi: '',
  });

  return response;
};

export const retreiveBranchStoreSchedule = async (data: dataOptions) => {
  const response = await getRequest({
    url: `branch-stores/${data.id}/schedules/`,
    urlGeoapi: '',
  });

  return response;
};

export const retreiveBranchStoreNearby = async (data: dataOptions) => {
  const response = await getRequest({
    url: `branch-stores/nearby/?store_id=${data.id}`,
    protected: true,
    urlGeoapi: '',
    sessionCookie: data.sessionCookie,
  });

  return response;
};
