import { AddressesList, Title, Wrapper } from './AddressesWrapper.styled';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AddressCreateForm } from '../Forms/Address';
import { AddressItem } from './AddressItem';
import type { UserAdressType } from '../../types/address';
import { retreiveUserAddresses } from '../../api/addresses';
import { setAddresses } from '../../store/addresses/addressesSlice';
import type { state } from '../../types/store/state';
import { useTranslation } from 'next-i18next';

interface Props {
  condensed: boolean;
}

export const AddressesWrapper = ({ condensed }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const addresses = useSelector(({ addresses }: state) => addresses.results);

  const [shouldEdit, setShoulEdit] = useState(false);
  const [addressToEdit, setAddressToEdit] = useState<UserAdressType>({
    id: 0,
    name: '',
    primary: false,
    address: {
      id: 0,
      address: '',
      description: '',
      city: '',
      state: '',
      zipcode: '',
      coordLat: '',
      coordLong: '',
    },
  });

  useEffect(() => {
    if (!condensed) {
      retreiveUserAddresses()
        .then((res) => dispatch(setAddresses(res)))
        .catch((error) => {
          throw new Error(error);
        });
    }
  }, []);

  return (
    <Wrapper>
      <Title>{t('addresses.title')}</Title>
      <AddressesList>
        {addresses.map((userAddresses) => (
          <Fragment key={userAddresses.id}>
            <AddressItem
              {...userAddresses}
              setOpenEditAddressForm={setShoulEdit}
              openEditAddressForm={shouldEdit}
              setAddressToEdit={setAddressToEdit}
            />
          </Fragment>
        ))}
      </AddressesList>
      {shouldEdit ? (
        <AddressCreateForm
          shouldEdit={shouldEdit}
          addressToEdit={addressToEdit}
        />
      ) : null}
      <AddressCreateForm />
    </Wrapper>
  );
};
