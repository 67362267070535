import { CartProduct } from '../../Cart/CartProduct';
import { Fragment } from 'react';
import type { ProductType } from '../../../types/product';
import type { state } from '../../../types/store/state';
import { useSelector } from 'react-redux';

export const OrderProducts = () => {
  const cart = useSelector(({ cart }: state) => cart);

  return (
    <div>
      {cart.productsCount > 0 ? (
        <div>
          {cart.branchStores[0].products.map((product: ProductType) => (
            <Fragment key={product.id}>
              <CartProduct {...product} />
            </Fragment>
          ))}
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};
