export const PaginationLogic = (
  currentPage: number,
  totalPages: number,
  maxLength: number
) => {

  const results: Array<number> = [];


  if (totalPages <= maxLength) {
    for (let i = 1; i <= totalPages; i++) {
      results.push(i);
    }
  }
  else {
    const firstPage: number = 1;
    const confirmedPagesCount: number = 3;
    const deductedMaxLength: number = maxLength - confirmedPagesCount;
    const sideLength: number = deductedMaxLength / 2;

    if (
      currentPage - firstPage < sideLength || totalPages - currentPage < sideLength
    ) {
      for (let j = 1; j <= sideLength + firstPage; j++) {
        results.push(j);
      }

      results.push(NaN);

      for (let k = totalPages - sideLength; k <= totalPages; k++) {
        results.push(k);
      }
    }
    else if (
      currentPage - firstPage >= deductedMaxLength &&
      totalPages - currentPage >= deductedMaxLength
    ) {
      const deductedSideLength = sideLength - 1;

      results.push(1);
      results.push(NaN);

      for (
        let l = currentPage - deductedSideLength;
        l <= currentPage + deductedSideLength;
        l++
      ) {
        results.push(l);
      }

      results.push(NaN);
      results.push(totalPages);
    }
    else {
      const isNearFirstPage = currentPage - firstPage < totalPages - currentPage;
      let remainingLength = maxLength;

      if (isNearFirstPage) {
        for (let m = 1; m <= currentPage + 1; m++) {
          results.push(m);
          remainingLength -= 1;
        }

        results.push(NaN);
        remainingLength -= 1;

        for (let n = totalPages - (remainingLength - 1); n <= totalPages; n++) {
          results.push(n);
        }

      } else {
        for (let o = totalPages; o >= currentPage - 1; o--) {
          results.unshift(o);
          remainingLength -= 1;
        }

        results.unshift(NaN);
        remainingLength -= 1;

        for (let p = remainingLength; p >= 1; p--) {
          results.unshift(p);
        }
      }
    }
  }
  return results;
};
