import {
  CategoriesButton,
  CategoriesIcon,
} from '../CategoriesButton/CategoriesButton.styled';
import {
  CategoryRow,
  ViewAllButton,
} from '../../components/CategoryProducts/CategoryProducts.styled';
import { Divider, ProductsWrapper, Title, Wrapper } from './ProductsRow.styled';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment } from 'react';
import Link from 'next/link';
import { MainWrap } from '../../layouts';
import { Product } from '../Product';
import type { ProductType } from '../../types/product';
import { SearchBar } from '../../components';
import { Sidebar } from '../../base';
import { SidebarCategories } from '../../base/SidebarCategories';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { useTranslation } from 'next-i18next';

interface Props {
  title: string;
  elements: Array<ProductType>;
  idHomeCat?: number;
}
const defaultProps = {
  title: '',
  elements: [],
  idHomeCat: 0,
};

export const ProductsRow = (props: Props = defaultProps) => {
  const { t } = useTranslation();
  const [openSidebar, setOpenSidebar] = useState(false);

  return (
    <Wrapper>
      <CategoryRow>
        <Title>{props.title}</Title>
        {props.idHomeCat && props.idHomeCat > 0 ? (
          <Link href={`/category/${props.idHomeCat}`} passHref>
            <a>
              <ViewAllButton>{t('shared.action.viewAll')}</ViewAllButton>
            </a>
          </Link>
        ) : null}
      </CategoryRow>
      {props.title === '' ? null : <Divider />}
      <MainWrap>
        <SidebarCategories />
        <div>
          <div className="categoriesButtonDiv">
            <CategoriesButton
              onClick={() => {
                setOpenSidebar(!openSidebar);
              }}
            >
              <CategoriesIcon>
                <FontAwesomeIcon icon={faList} width="14" />
              </CategoriesIcon>
              {t('header.categories.selector.title')}
            </CategoriesButton>
          </div>
          <div className="searchBarBody">
            <SearchBar />
          </div>
          <ProductsWrapper>
            {props.elements?.length
              ? props.elements.map((element: ProductType) => (
                  <Fragment key={element.id}>
                    <Product {...element} />
                  </Fragment>
                ))
              : null}
          </ProductsWrapper>
        </div>
      </MainWrap>
      {openSidebar ? <Sidebar setOpenSidebar={setOpenSidebar} /> : null}
    </Wrapper>
  );
};
