export interface EditUserType {
  nonFieldErrors: Array<string>;
  username: Array<string>;
  firstName: Array<string>;
  lastName: Array<string>;
  phoneNumber: Array<string>;
  email: Array<string>;
};

export const defaultEditUserType = {
  nonFieldErrors: [],
  username: [],
  firstName: [],
  lastName: [],
  phoneNumber: [],
  email: [],
};
