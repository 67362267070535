import { PalletteProps } from '../../types/theme/pallette';
import styled from 'styled-components';

export const CategoriesButton = styled.button`
  color: ${({ theme }: PalletteProps) => theme.gray2};
  border: none;
  font-size: 25px;
  font-weight: 700;
  line-height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 21px 69px;
  background-color: ${({ theme }: PalletteProps) => theme.white};
  box-shadow: 0px 0px 50px rgba(200, 200, 200, 0.2);
  border-radius: 8px;
  margin-bottom: 62px;
  align-self: center;

  &:hover {
    cursor: pointer;
    box-shadow: 0px 0px 50px rgba(200, 200, 200, 0.9);
  }

  &:active {
    background-color: ${({ theme }: PalletteProps) => theme.gray8};
  }
`;

export const CategoriesIcon = styled.span`
  width: 28px;
  height: 28px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }: PalletteProps) => theme.gray5};
  margin-right: 6px;
`;
