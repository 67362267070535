import { OrderDeliveryType, OrderStatusType } from '../../types/orders';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PalletteProps } from '../../types/theme/pallette';
import { getOrderStatusColor } from '../../utils/getOrderStatusColor';
import { rgba } from 'polished';
import styled from 'styled-components';

interface TypeProps {
  type: OrderDeliveryType;
}
interface StatusProps {
  status: OrderStatusType;
}

export const Wrapper = styled.article`
  background-color: white;
  box-shadow: 0px 0px 50px rgba(200, 200, 200, 0.2);
  border-radius: 5px;
  padding: 29px 0;
`;

export const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 4px 26px 4px;
  padding-bottom: 26px;
  border-bottom: solid 1px ${({ theme }: PalletteProps) => theme.lightGray};
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 38px 26px 38px;
  }
`;

export const TopLeft = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    margin-bottom: 0px;
  }
`;

export const Reference = styled.h3`
  color: black;
  font-weight: 700;
  font-size: 1.125rem;
  margin-right: 16px;
  &:hover {
    cursor: pointer;
    .rightEase {
      transform: translate(4px, 0px);
    }
  }
  .noLink {
    &:hover {
      cursor: default;
    }
  }
`;

export const RightArrowButton = styled.button`
  background: none;
  border: none;
  transition: all 0.2s ease-out;
  &:hover {
    cursor: pointer;
  }
`;

export const RightArrowIcon = styled(FontAwesomeIcon)`
  margin-left: 3px;
  width: 14px;
  height: 14px;
  color: ${({ type }: TypeProps) =>
    type === 'delivery'
      ? ({ theme }: PalletteProps) => theme.orderDeliveryTypeColor
      : ({ theme }: PalletteProps) => theme.orderPickupTypeColor};
`;

export const Type = styled.span`
  color: white;
  padding: 3px 9px;
  border-radius: 5px;
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: capitalize;
  background-color: ${({ type }: TypeProps) =>
    type === 'delivery'
      ? ({ theme }: PalletteProps) => theme.orderDeliveryTypeColor
      : ({ theme }: PalletteProps) => theme.orderPickupTypeColor};
`;

export const Status = styled.p`
  background: ${({ status }: StatusProps) =>
    rgba(getOrderStatusColor(status), 0.18)};
  color: ${({ status }: StatusProps) => getOrderStatusColor(status)};
  border-radius: 5px;
  padding: 3px 9px;
  font-size: 0.75rem;
  font-weight: 500;
`;

export const MiddleSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 26px 6px 6px 6px;
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    padding: 26px 38px 0 38px;
  }
`;

export const MiddleLeft = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DestinationWrapper = styled.div`
  margin-bottom: 21px;
`;

export const DestinationTitle = styled.p`
  color: ${({ theme }: PalletteProps) => theme.gray2};
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 11px;
`;

export const Destination = styled.p`
  color: ${({ theme }: PalletteProps) => theme.gray3};
  font-size: 1rem;
  font-weight: 400;
`;

export const Row = styled.div`
  display: flex;
  gap: 34px;
`;

export const RowElem = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 16px;
`;

export const RowElemTitle = styled.div`
  font-size: 1rem;
  font-weight: 500;
  ${({ theme }: PalletteProps) => theme.gray2};
`;

export const RowElemDescription = styled.div`
  font-size: 1rem;
  font-weight: 400;
  color: ${({ theme }: PalletteProps) => theme.gray3};
`;

export const MiddleRight = styled.div`
  justify-self: flex-end;
`;

export const ProductsImages = styled.div`
  display: flex;
  margin-top: 16px;
`;

export const Items = styled.p`
  color: ${({ theme }: PalletteProps) => theme.middleGray};
  font-size: 1rem;
  font-weight: 700;
  text-align: right;
  margin: 8px 0 13px 0;
`;

export const Total = styled.p`
  font-size: 1.25rem;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  margin-top: 8px;
  color: ${({ theme }: PalletteProps) => theme.primaryColor};
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    text-align: right;
  }
`;

export const BottomSection = styled.div`
  display: grid;
  grid-gap: 22px;
  padding: 26px 38px 0 38px;
`;
