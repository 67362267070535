import { PalletteProps } from '../../../types/theme/pallette';
import { rgba } from 'polished';
import styled from 'styled-components';

interface ItemProps extends PalletteProps {
  active: boolean;
  disabled?: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;


export const Tabs = styled.div`
  max-width: 300px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  background-color: ${({ theme }: PalletteProps) => theme.lightGray};
  border: 1px solid ${({ theme }: PalletteProps) => theme.gray7};
  border-radius: 10px;
  padding: 5px;
  gap: 5px;
  margin-bottom: 10px;
`;

export const TabItem = styled.button<ItemProps>`
  width: 150px;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;
  align-items: center;
  justify-items: center;
  padding: 10px;
  background: ${({ active, theme, disabled }) => 
    disabled ? theme.lightGray : (active ? theme.primaryColor : 'transparent')};
  border: ${({ active, theme, disabled }) => 
    disabled ? `1px dashed ${(theme.gray4)}` : (active ? theme.primaryColor : 'transparent')};
  padding: 10px 20px;
  font-size: 1rem;
  font-family: 'Inter', sans-serif;
  color: ${({ theme, active, disabled }) => 
    disabled ? theme.gray4 : (active ? theme.white : theme.gray2)};
  border-radius: 7px;
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
  position: relative;
  .faIcon {
    width: auto;
    height: 1.2rem;
    padding: 0 .5rem;
  }

  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    background: ${({ active, theme, disabled }) => 
    disabled ? theme.lightGray : (active ? theme.primaryColor : rgba(theme.primaryColor, 0.15))};
    color:${({ active, theme, disabled }) => 
    disabled ? theme.gray4 : (active ? theme.white : theme.gray2)};
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: ${({ active }) => (active ? '0' : '100%')};
    width: 100%;
    height: 100%;
    background-color: ${({ theme, active, disabled }) => 
      disabled ? 'transparent' : (active ? theme.primaryColor : 'transparent')};
    border-radius: 7px;
    transition: left 0.3s ease-in-out;
    z-index: -1;
  }
`;

export const PickupText = styled.p`
  font-size: 1rem;
  color: ${({ theme }: PalletteProps) => theme.gray3};
  font-style: italic;
  margin-bottom: 1rem;
`;

export const NotAvailableText = styled.span`
  background-color: ${({ theme }: PalletteProps) => theme.white};
  border: 1px solid ${({ theme }: PalletteProps) => theme.gray7};
  border-radius: 25px;
  padding: 2px 6px;
  font-size: 0.625rem;
  color: ${({ theme }: PalletteProps) => rgba(theme.nonProductActiveColor, 0.5)};
  grid-column: 1 / span 2;
  position: absolute;
  z-index: 1;
  top: 74%;
`;