import type { options, reqOptions } from './types/request';

import { AxiosRequestHeaders } from 'axios';
import { getToken } from '../auth';

const API_URL =
  process.env.NEXT_PUBLIC_API_URL != undefined
    ? process.env.NEXT_PUBLIC_API_URL
    : '/api';

const HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const setOptions = (options: reqOptions): options => {
  let altHeaders: AxiosRequestHeaders = {};

  let authToken = '';
  if (typeof window !== 'undefined' && getToken().length && getToken() !== 'guest-user') {
    authToken = getToken();
  } else if (options.sessionCookie?.length && options.sessionCookie !== 'guest-user') {
    authToken = options.sessionCookie;
  }

  if (options.protected && authToken.length) {
    altHeaders = { Authorization: `Token ${authToken}` };
  }

  return {
    headers: {
      ...HEADERS,
      ...altHeaders,
    },
    params: options.data ? { ...options.data } : {},
    url: `${API_URL}/${options.url}`,
    urlGeoapi: `https://locations.shapya.com/api/${options.url}`,
  };
};
