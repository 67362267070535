import { PalletteProps } from './../../types/theme/pallette';
import styled from 'styled-components';

export const Row = styled.div`
  box-sizing: border-box;
  margin: 0 auto !important;
  max-width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    box-sizing: border-box;
    margin: 0 auto !important;
    max-width: 100%;
    position: relative;
  }
`;
