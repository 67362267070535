import { createCart, retreiveCart } from '../api/cart';
import { deleteToken, getToken, setToken } from '../services/auth';

import { retreiveUser } from '../api/users';
import { setCart } from '../store/cart/cartSlice';
import { setUser } from '../store/user/userSlice';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

export const useGetUserInfo = (setLogged: (action: boolean) => void) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!getToken().length) {
      setToken('guest-user');
    }
    getUserInfo();
  }, []);
  
  const getOrCreateCart = async (id: string | null) => {
    let cart;
    try {
      if (!id || !id.length) {
        cart = await createCart();
        await dispatch(setCart(cart));
      } else {
        cart = await retreiveCart({ id });
        await dispatch(setCart(cart));
      }
    } catch (error: any) {
      if (error.response.status === 404) {
        cart = await createCart();
        await dispatch(setCart(cart));
        return cart;
      }
      throw new Error(error);
    }
    return cart;
  };

  const getUserInfo = async () => {
    try {
      if (getToken() === 'guest-user') {
        const cart = await getOrCreateCart(localStorage.getItem('guest-user-cart'));
        await localStorage.setItem('guest-user-cart', cart.reference);
        return;
      }
      const user = await retreiveUser();
      await dispatch(setUser(user));
      await setLogged(true);
      await getOrCreateCart(user.currentUserCart);
    } catch (error: any) {
      if (error.response.status === 401) {
        deleteToken();
        return;
      }
      throw new Error(error);
    }
  };
};
