import { ClickButton } from '../../../shared/Anchor/Anchor.styled';
import { ImageWrapper } from '../../Cart/CartProduct/CartProduct.styled';
import { PalletteProps } from '../../../types/theme/pallette';
import styled from 'styled-components';

export const UnderProductWrapper = styled.div`
width: 100%;
margin-bottom: 25px;
display: flex;
flex-wrap: nowrap;
justify-content: flex-start;
align-items: flex-start;
align-content: center;
flex-direction: row;
gap: 10px;
`;

export const DeleteProductCTA = styled.div`
width: fit-content;
${ClickButton} {
  width: fit-content;
  height: 21px;
  padding: 3px 6px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  background-color: transparent;
  border: none;
  color: ${({ theme }: PalletteProps) => theme.gray3};
  border-radius: 0px;
  font-weight: 100;
  line-height: 1;
  transition: ease all 200ms;
  box-shadow: none;
  text-transform: capitalize;
  .faIcon {
    margin-right: 4px;
  }
  &:hover {
    cursor: pointer;
    text-decoration: underline;
    border-radius: 5px;
    background-color: ${({ theme }: PalletteProps) => theme.gray3};
    color: ${({ theme }: PalletteProps) => theme.white};
  }
  
}
`;

export const ImageContainer = styled(ImageWrapper)`
  width: 140px;
  height: 140px;
  margin: 20px 0px;
`;