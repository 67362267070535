import { CountIcon, Icon, IconWrapper } from './CartIcon.styled';

import { CartSidebar } from '../CartSidebar';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';
import type { state } from '../../../types/store/state';
import { useSelector } from 'react-redux';
import { useState } from 'react';

export const CartIcon = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const cart = useSelector((state: state) => state.cart);

  return (
    <>
      <IconWrapper onClick={() => setSidebarOpen(!sidebarOpen)}>
        <Icon icon={faCartShopping} width={24} />
        {cart.productsCount ? (
          <CountIcon>{cart.productsCount}</CountIcon>
        ) : null}
      </IconWrapper>
      {sidebarOpen ? <CartSidebar setOpenSidebar={setSidebarOpen}/> : null}
    </>
  );
};
