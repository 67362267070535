export interface ValidationRegisterType {
  nonFieldErrors: Array<string>;
  firstName: Array<string>;
  lastName: Array<string>;
  email: Array<string>;
  password1: Array<string>;
  password2: Array<string>;
  username: Array<string>;
}

export const defaultRegisterState = {
  nonFieldErrors: [],
  firstName: [],
  lastName: [],
  username: [],
  email: [],
  password1: [],
  password2: [],
};

export interface ValidationLoginType {
  nonFieldErrors: Array<string>;
  password: Array<string>;
  username: Array<string>;
}

export const defaultLoginState = {
  nonFieldErrors: [],
  password: [],
  username: [],
};

export interface ValidationCreateAddressType {
  nonFieldErrors: Array<string>;
  name: Array<string>;
  address: Array<string>;
  zipcode: Array<string>;
  state: Array<string>;
  city: Array<string>;
  instructions: Array<string>;
  description: Array<string>;
  coordLat: Array<string>;
  coordLong: Array<string>;
}

export const defaultCreateAddressState = {
  nonFieldErrors: [],
  name: [],
  address: [],
  zipcode: [],
  state: [],
  city: [],
  instructions: [],
  description: [],
  coordLat: [],
  coordLong: [],
};

export interface ValidationResetPasswordType {
  nonFieldErrors: Array<string>;
  newPassword1: Array<string>;
  newPassword2: Array<string>;
}

export const defaultResetPasswordState = {
  nonFieldErrors: [],
  newPassword1: [],
  newPassword2: [],
};