export interface changePasswordType {
  nonFieldErrors: Array<string>;
  oldPassword: Array<string>;
  newPassword1: Array<string>;
  newPassword2: Array<string>;
}

export const defaultChangePasswordType = {
  nonFieldErrors: [],
  oldPassword: [],
  newPassword1: [],
  newPassword2: [],
};