import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PalletteProps } from '../../types/theme/pallette';
import styled from 'styled-components';

interface StarProps extends PalletteProps {
  primary: boolean;
}

export const Item = styled.div`
  background-color: white;
  box-shadow: 0px 0px 50px rgba(200, 200, 200, 0.2);
  border-radius: 5px;
  padding: 23px 30px;
  width: 100%;
`;

export const Top = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 30px;
`;

export const StarButton = styled.button`
  background: none;
  border: none;
  &:hover {
    cursor: pointer;
  }
`;

export const StarIcon = styled(FontAwesomeIcon)`
  color: ${({ theme, primary }: StarProps) =>
    primary ? '#F2C94C' : theme.gray2};
`;

export const Name = styled.p`
  color: ${({ theme }: PalletteProps) => theme.gray2};
  font-size: 1.125rem;
  font-weight: 600;
  flex: 1;
`;

export const EditButton = styled.button`
  color: ${({ theme }: PalletteProps) => theme.primaryColor};
  background: none;
  font-weight: 600;
  border: none;
  font-size: 1rem;
  &:hover {
    cursor: pointer;
  }
`;

export const Address = styled.p`
  color: ${({ theme }: PalletteProps) => theme.gray3};
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 16px;
`;

export const DeleteButton = styled.button`
  float: right;
  background: none;
  border: none;
  &:hover {
    cursor: pointer;
  }
`;

export const DeleteIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }: PalletteProps) => theme.gray4};
`;
