import * as React from 'react';

import { Button, Input } from '../../../shared';
import {
  ErrorText,
  FullRow,
  Pharagraph,
  RecoverPassAnchor,
  Title,
  Wrapper,
} from '../Forms.styled';
import { FormEvent, useState } from 'react';
import {
  ValidationResetPasswordType,
  defaultResetPasswordState,
} from '../types/userValidations';

import Link from 'next/link';
import { confirmResetPassword } from '../../../api/users';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

export const ResetPassword = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { uid, token } = router.query;
  const [errors, setErrors] = useState<ValidationResetPasswordType>(
    defaultResetPasswordState
  );
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [isSent, setIsSent] = useState(false);

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      await confirmResetPassword({
        params: {
          uid: uid,
          token: token,
          newPassword1: newPassword,
          newPassword2: confirmNewPassword,
        },
      });
      await setIsSent(true);
    } catch (error: any) {
      if (error.response.status >= 400) {
        setErrors({
          ...error,
          ...error.response.data,
        });
        return;
      }
      throw new Error(error);
    }
  };

  return (
    <Wrapper $isResetPass>
      {isSent ? (
        <>
          <Title>{t('login.recover.resetSuccess.title')}</Title>
          <Pharagraph $isSent>
            {t('login.recover.resetSuccess.description')}
          </Pharagraph>
        </>
      ) : (
        <>
          <Title>{t('login.recover.reset.title')}</Title>
          <Pharagraph>{t('login.recover.reset.description')}</Pharagraph>
          <form onSubmit={onSubmit}>
            <FullRow>
              <Input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder={t('login.recover.reset.placeholder.newpassword')}
                name="newPassword1"
                error={errors.newPassword1}
                required
              />
            </FullRow>
            <FullRow>
              <Input
                type="password"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                placeholder={t(
                  'login.recover.reset.placeholder.confirmNewpassword'
                )}
                name="newPassword2"
                error={errors.newPassword2}
                required
              />
            </FullRow>
            {errors.nonFieldErrors?.length ? (
              <ErrorText>{errors.nonFieldErrors[0]}</ErrorText>
            ) : null}
            <Button
              title={t('login.recover.reset.action.resetPassword')}
              type="submit"
            />
            <Link href="/login" passHref>
              <RecoverPassAnchor>
                {t('login.recover.action.backLogin')}
              </RecoverPassAnchor>
            </Link>
          </form>
        </>
      )}
    </Wrapper>
  );
};
