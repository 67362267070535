import { Bottom, ButtonsWrapper, CancelButton, ObsTextContainer } from './CartObservation.styled';
import { EditObsnIcon, ObsTextArea, TextAreaWrapper, TopItems } from '../../ProductDetail/ProductDetail.styled';
import { FormEvent, useState } from 'react';
import { ObservationType, defaultObservationType } from '../types/observationValidation';
import { faEdit, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '../../../shared';
import type { ProductType } from '../../../types/product';
import { addOrUpdateObservation } from '../../../api/cart';
import { setCart } from '../../../store/cart/cartSlice';
import type { state } from '../../../types/store/state';
import { useTranslation } from 'next-i18next';

export const CartObservation = (props: ProductType) => {
  const { t } = useTranslation();
  const [editObservation, setEditObservation] = useState(false);
  const [errors, setErrors] = useState<ObservationType>(defaultObservationType);
  const cartReference = useSelector(({ cart }: state) => cart.reference);
  const dispatch = useDispatch();

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setErrors(defaultObservationType);

    const target = event.target as any;

    try {
      const obsResult = await addOrUpdateObservation({
        id: cartReference,
        params: {
          orderBranchProduct: props.id,
          observation: target.observationNote.value,
        },
      });
      await dispatch(setCart(obsResult));
    } catch (error: any) {
      if (error.response.status === 400) {
        setErrors({
          ...errors,
          ...error.response.data,
        });
        return;
      }
      throw new Error(error);
    }
    setEditObservation(!editObservation);
  };

  const handleEditObservation = () => {
    setEditObservation(!editObservation);
  };

  return (
    <form onSubmit={onSubmit}>
      <Bottom>
        {!editObservation ? <>
          <TextAreaWrapper>
            <TopItems>
              <EditObsnIcon icon={faPencilAlt} />
              <ObsTextContainer onClick={handleEditObservation}>
                <span>
                  {t('cart.shared.observationBox.title')}
                </span>
                {props.observation ? props.observation : t('product.detail.observations.textarea.placeholder')}
              </ObsTextContainer>
            </TopItems>
          </TextAreaWrapper>
        </> :
          <>
            <TextAreaWrapper>
              <TopItems>
                <EditObsnIcon icon={faEdit} />
                {t('product.detail.editObservations.textarea.title')}
              </TopItems>
              <ObsTextArea
                name='observationNote'
                placeholder={t('product.detail.observations.textarea.placeholder')}
                defaultValue={props.observation}
              />
            </TextAreaWrapper>
            <ButtonsWrapper>
              <CancelButton
                title={t('shared.action.cancel')}
                type="reset"
                onClick={handleEditObservation}
              />
              <Button
                title={t('shared.action.save')}
                type="submit"
              />
            </ButtonsWrapper>
          </>
        }
      </Bottom>
    </form>
  );
};