import { PalletteProps } from '../../../types/theme/pallette';
import { QuantityWrapper } from '../../../shared/QuantityButton/QuantityButton.styled';
import styled from 'styled-components';

export const Item = styled.div`
  padding: 24px 0;
  &:not(:last-child) {
    border-bottom: 1px solid rgb(244, 244, 244);
  }
`;

export const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    gap: 20px;
  }
  ${QuantityWrapper} {
    width: 80px;
    justify-content: space-between;
    padding: 3px 2px;
    border-radius: 5px;
    line-height: 1;
    margin: 4px;
    @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
      width: 100px;
      padding: 10px 12px;
      border-radius: 10px;
      font-weight: 900;
      line-height: 1;
    }
    @media ${({ theme }: PalletteProps) => theme.breakpoints.tablet} {
      width: 170px;
      padding: 12px 15px;
    }
  }
`;

export const Content = styled.div`
  flex: 1;
  margin: 4px;
`;

export const Title = styled.p`
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }: PalletteProps) => theme.black};
  margin: 4px;
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    font-size: 1.125rem;
  }
`;

export const ImageWrapper = styled.figure`
  background-color: ${({ theme }: PalletteProps) => theme.white};
  position: relative;
  width: 73px;
  height: 73px;
  margin: 4px;
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    width: 75px;
    height: 75px;
  }
`;

export const PriceWrapper = styled.div`
  display: flex;
  grid-gap: 6px;
  align-items: center;
  justify-content: flex-end;
  width: 3rem;
  margin: 0px 3px 2px 18px;
`;

export const Price = styled.p`
  font-size: 1.125rem;
  color: ${({ theme }: PalletteProps) => theme.black};
  line-height: 1.125;
  font-weight: 700;
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobileL} {
    font-size: 1.125rem;
  }
`;

export const PriceDiscount = styled.p`
  font-size: 0.875rem;
  color: ${({ theme }: PalletteProps) => theme.red1};
  text-decoration-line: line-through;
  margin-bottom: 10px;
`;

export const Units = styled.p`
  font-size: 0.875rem;
  color: ${({ theme }: PalletteProps) => theme.gray3};
  font-weight: 500;
  font-style: italic;
  text-align: left;
  margin-bottom: 10px;
`;

export const AttrWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 2px;
  margin-bottom: 19px;
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    flex-direction: row;
    gap: 8px;
    margin-bottom: 10px;
  }
`;

export const AttrBox = styled.div`
  text-transform: capitalize;
  padding: 0px 6px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
`;

export const AttrTitle = styled.p`
  font-size: .75rem;
  font-weight: 500; 
  color: ${({ theme }: PalletteProps) => theme.gray2};
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
     font-size: .875rem;
  }
`;

export const AttrElement = styled.p`
  font-size: .625rem;
  font-weight: 400;
  color: ${({ theme }: PalletteProps) => theme.gray3};
  padding-top: 2px;
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
     font-size: .75rem;
  }
`;