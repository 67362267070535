import { PalletteProps } from '../../types/theme/pallette';
import styled from 'styled-components';

interface props extends PalletteProps {
  disabled?: boolean;
}

export const QuantityWrapper = styled.div<props>`
  display: flex;
  text-align: center;
  justify-content: space-between;
  padding: 7px 30px;
  background-color: ${({ theme }) => theme.white};
  border: solid 1px
    ${({ theme, disabled }) =>
      disabled ? theme.secondaryColor : theme.primaryColor};
  color: ${({ theme, disabled }) =>
    disabled ? theme.secondaryColor : theme.primaryColor};
  border-radius: 10px;
  font-weight: 900;
  line-height: 1;
  transition: ease all 200ms;
`;

export const MinusButton = styled.button<props>`
  background-color: transparent;
  outline: 0;
  border: none;
  color: ${({ theme, disabled }) =>
    disabled ? theme.secondaryColor : theme.primaryColor};
  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }
`;

export const PlusButton = styled.button<props>`
  background-color: transparent;
  outline: 0;
  border: none;
  color: ${({ theme, disabled }) =>
    disabled ? theme.secondaryColor : theme.primaryColor};
  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }
`;

export const CounterNumber = styled.p<props>`
  font-weight: 400;
  font-size: 1rem;
  line-height: 17px;
  color: ${({ theme }: PalletteProps) => theme.gray2};
  cursor: default;
  text-align: center;
  align-self: center;
`;
