import { PalletteProps } from '../../types/theme/pallette';
import { Wrapper } from '../../components/ProductDetail/ProductDetail.styled';
import styled from 'styled-components';

export const Card = styled.div`
  border-radius: 8px;
  overflow: hidden;
  background: ${({ theme }: PalletteProps) => theme.white};
  box-shadow: 0px 0px 50px rgba(200, 200, 200, 0.2);
  width: 100%;
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    padding: 26px 30px;
    max-width: 278px;
  }
  .AnchorButton {
    width: 90%;
    margin: 0 auto;
    @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
      width: unset;
      margin: unset;
    }
  }
`;

export const ImageWrapper = styled.figure`
  background-color: ${({ theme }: PalletteProps) => theme.white};
  position: relative;
  height: 125px;
  margin-bottom: 13px;
  background-repeat: no-repeat;
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    width: 218px;
    height: 170px;
    margin: 0 auto 17px auto;
  }
`;

export const Title = styled.h3`
  font-size: 1rem;
  color: ${({ theme }: PalletteProps) => theme.black};
  line-height: 1.125;
  font-weight: 700;
  margin-bottom: 13px;
  text-align: center;
  min-height: 60px;
  padding: 0 8px;
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    padding: 0;
  }
`;

export const PriceWrapper = styled.div`
  display: flex;
  grid-gap: 6px;
  align-items: center;
  justify-content: center;
  margin-bottom: 2px;
  padding: 0 8px;
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    padding: 0;
  }
`;

export const Price = styled.p`
  font-size: 1rem;
  color: ${({ theme }: PalletteProps) => theme.black};
  line-height: 1.125;
  font-weight: 700;
  margin-bottom: 13px;
`;

export const PriceDiscount = styled.p`
  font-size: 0.875rem;
  color: ${({ theme }: PalletteProps) => theme.red1};
  text-decoration-line: line-through;
  margin-bottom: 10px;
`;

export const Units = styled.p`
  font-size: 0.75rem;
  color: ${({ theme }: PalletteProps) => theme.gray3};
  font-weight: 500;
  font-style: italic;
  text-align: center;
  margin-bottom: 10px;
`;

export const ProductOverlay = styled.div`
  position: absolute;
  bottom: 0;
  background: rgb(255, 255, 255);
  background: rgba(255, 255, 255, 0.5);
  color: ${({ theme }: PalletteProps) => theme.gray8};
  width: 100%;
  height: 100%;
  transition: 0.5s ease;
  opacity: 0;
  color: ${({ theme }: PalletteProps) => theme.white};
  font-size: 20px;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${ImageWrapper}:hover & {
    opacity: 1;
  }
`;

export const TooltipText = styled.p`
  visibility: visible;
  background-color: ${({ theme }: PalletteProps) => theme.gray2};
  width: 84px;
  font-size: 11px;
  color: ${({ theme }: PalletteProps) => theme.white};
  text-align: center;
  border-radius: 10px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  bottom: 125px;
  left: 50%;
  margin-left: -42px;
  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${({ theme }: PalletteProps) => theme.gray2} transparent
      transparent transparent;
  }
`;

export const IconProductOverlay = styled.div`
  width: 44px;
  height: 44px;
  border-radius: 50px;
  background-color: ${({ theme }: PalletteProps) => theme.gray3};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ModalOpenButton = styled.button`
  cursor: pointer;
  border: none;
  background-color: unset;
  width: 100%;
`;

export const ModalWrapper = styled.div`
  display: block;
  position: fixed;
  z-index: 1;
  padding-top: 150px;
  padding-left: 15px;
  padding-right: 10px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.5);
  @-webkit-keyframes animatetop {
    from {
      top: -300px;
      opacity: 0;
    }
    to {
      top: 0;
      opacity: 1;
    }
  }
  @keyframes animatetop {
    from {
      top: -300px;
      opacity: 0;
    }
    to {
      top: 0;
      opacity: 1;
    }
  }
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    padding-left: 25px;
    padding-right: 20px;
  }
`;

export const ModalContent = styled.div`
  position: relative;
  max-width: 1264px;
  background-color: ${({ theme }: PalletteProps) => theme.gray8};
  margin: auto;
  padding: 0;
  border: 1px solid ${({ theme }: PalletteProps) => theme.gray3};
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
  ${Wrapper} {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 80px;
  }
  ${Title} {
    font-size: 1.25rem;
    color: ${({ theme }: PalletteProps) => theme.black};
    line-height: 1.5;
    margin-bottom: 17px;
    text-align: center;
    @media ${({ theme }: PalletteProps) => theme.breakpoints.mobileL} {
      font-size: 2.125rem;
      text-align: left;
    }
  }
  ${PriceWrapper} {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    margin-bottom: 31px;
    @media ${({ theme }: PalletteProps) => theme.breakpoints.mobileL} {
      justify-content: flex-start;
    }
  }
  ${Price} {
    font-size: 1.875rem;
    color: ${({ theme }: PalletteProps) => theme.black};
    line-height: 1.125;
    font-weight: 700;
  }
  ${PriceDiscount} {
    font-size: 1.5rem;
    font-weight: 700;
    color: ${({ theme }: PalletteProps) => theme.red1};
    text-decoration-line: line-through;
  }
  ${Units} {
    font-size: 0.875rem;
    color: ${({ theme }: PalletteProps) => theme.gray3};
    font-weight: 500;
    font-style: italic;
    margin-bottom: 30px;
    text-align: center;
    @media ${({ theme }: PalletteProps) => theme.breakpoints.mobileL} {
      text-align: left;
    }
  }
`;

export const ModalCloseButton = styled.span`
  color: ${({ theme }: PalletteProps) => theme.gray2};
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 58px;
  padding: 0px 10px;
  &:hover,
  &:focus {
    color: ${({ theme }: PalletteProps) => theme.gray3};
    text-decoration: none;
    cursor: pointer;
  }
`;
