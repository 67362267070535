import React from 'react';
import { useTranslation } from 'next-i18next';
import type { state } from '../../types/store/state';
import { useSelector } from 'react-redux';
import { FooterColumnsTitles, FooterSocialMediaIcon } from './Footer.styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faFacebook,
  faInstagram,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';

export const FooterBsSocialMedia = () => {
  const { t } = useTranslation();
  const branchStoreDetail = useSelector((state: state) => state.branchStore);

  let socialMediaIcon = '';

  const SocialMediaLinks = (socialMediaIcon: string) => {
    switch (socialMediaIcon) {
      case 'facebook':
        return faFacebook;
      case 'instagram':
        return faInstagram;
      case 'youtube':
        return faYoutube;

      default:
        return null;
    }
  };

  return (
    <div>
      <FooterColumnsTitles>{t('footer.sociaMedia.title')}</FooterColumnsTitles>
      <div>
        <p>
          {branchStoreDetail.socialMedia.map((branchStoreSocialMedia) => (
            <span key={branchStoreSocialMedia.id}>
              {(socialMediaIcon = branchStoreSocialMedia.name) &&
                branchStoreSocialMedia.name != 'website'}
              {branchStoreSocialMedia.name == socialMediaIcon ? (
                <FooterSocialMediaIcon
                  href={branchStoreSocialMedia.url}
                  target="_blank"
                >
                  <FontAwesomeIcon
                    icon={SocialMediaLinks(socialMediaIcon) as IconProp}
                    height={25}
                  />
                </FooterSocialMediaIcon>
              ) : null}
            </span>
          ))}
        </p>
        <h4>
          {branchStoreDetail.socialMedia.map((branchStoreSocialMedia) => (
            <span key={branchStoreSocialMedia.id}>
              {branchStoreSocialMedia.name === 'phone' ? (
                <FooterSocialMediaIcon
                  href={`tel:${branchStoreSocialMedia.url.replace(
                    /[^0-9]+/g,
                    ''
                  )}`}
                >
                  <br />
                  &#9742;&nbsp;
                  {branchStoreSocialMedia.url}
                </FooterSocialMediaIcon>
              ) : null}
            </span>
          ))}
        </h4>
      </div>
    </div>
  );
};
