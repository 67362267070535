import { getRequest } from '../services/http';

export const retrieveWallet = async () => {
  const response = await getRequest({
    url: 'auth/user/wallet/',
    protected: true,
    urlGeoapi: '',
  });

  return response;
};