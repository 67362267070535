import { Fragment, useEffect, useState } from 'react';
import { NewPaymentMethod, PaymentMethodsList, Title, Wrapper } from './PaymentMethodsWrapper.styled';
import { useDispatch, useSelector } from 'react-redux';

import { CreatePaymentMethod } from '../Forms';
import { PaymentMethodItem } from './PaymentMethodItem';
import { retreivePaymentMethods } from '../../api/paymentMethods';
import { setPaymentMethods } from '../../store/paymentMethods/paymentMethodsSlice';
import type { state } from '../../types/store/state';
import { useTranslation } from 'next-i18next';

interface Props {
  condensed: boolean;
}

export const PaymentMethodsWrapper = ({ condensed }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const paymentMethods = useSelector(({ paymentMethods }: state) => paymentMethods.results);

  const [showAddPayment, setShowAddPayment] = useState(false);

  const handleClick = (event: any) => {
    event.preventDefault();
    setShowAddPayment(!showAddPayment);
  };

  useEffect(() => {
    if (!condensed) {
      retreivePaymentMethods()
        .then((res) => dispatch(setPaymentMethods(res)))
        .catch((error) => {
          throw new Error(error);
        });
    }
  }, []);

  return (
    <Wrapper>
      {!condensed ? (
        <Title>
          {t('payment.methods.title')}
        </Title>
      ) : null}
      <PaymentMethodsList>
        {paymentMethods.map((method) =>
          <Fragment key={method.id}>
            <PaymentMethodItem {...method} condensed={condensed} />
          </Fragment>
        )}
      </PaymentMethodsList>
      <NewPaymentMethod onClick={handleClick}>
        {showAddPayment ? '-' : '+'} {t('payment.action.addPayMethods')}
      </NewPaymentMethod>
      {showAddPayment ? (
        <CreatePaymentMethod setShowAddPayment={setShowAddPayment} />
      ) : null}
    </Wrapper>
  );
};
