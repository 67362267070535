import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PalletteProps } from '../../../types/theme/pallette';
import { rgba } from 'polished';
import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
`;

export const Action = styled.button`
  display: flex;
  align-items: center;
  background: none;
  border: none;
  &:hover {
    cursor: pointer;
  }
`;
export const Icon = styled(FontAwesomeIcon)`
  color: ${({ theme }: PalletteProps) => theme.gray3};
  margin-right: 8px;
`;

export const Menu = styled.ul`
  position: absolute;
  left: -200px;
  top: 2.5rem;
  margin: 0;
  padding: 0;
  border: solid 1px ${({ theme }: PalletteProps) => theme.gray5};
  background: white;
  border-radius: 5px;
  list-style: none;
  width: 242px;
  max-height: 270px;
  z-index: 1001;
`;

export const MenuItem = styled.button`
  display: flex;
  align-items: center;
  padding: 12px 17px;
  font-size: 1rem;
  transition: ease all 200ms;
  width: 100%;
  border: none;
  background: none;
  color: ${({ theme }: PalletteProps) => theme.gray2};
  .faIcon {
    margin-right: 12px;
  }
  &:hover {
    cursor: pointer;
    background: ${({ theme }: PalletteProps) => rgba(theme.primaryColor, 0.1)};
  }
`;
