import { Title, Wrapper } from './ChangePasswordWrapper.styled';

import { ChangePasswordForm } from '../Forms';
import { useTranslation } from 'next-i18next';

export const ChangePasswordWrapper = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Title>
        {t('password.change.title')}
      </Title>
      <ChangePasswordForm />
    </Wrapper>
  );
};