import { PalletteProps } from '../../types/theme/pallette';
import styled from 'styled-components';

export const NotificationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  font-size: 0.875rem;
  font-style: italic;
  text-align: center;
  padding: 5px 10px 48px 10px;
`;

export const Note = styled.p`
  color: ${({ theme }: PalletteProps) => theme.gray3};
`;