import { PalletteProps } from '../../types/theme/pallette';
import { rgba } from 'polished';
import styled from 'styled-components';

export const ButtonElement = styled.button`
    display: flex;
    text-align: center;
    justify-content: center;
    font-size: .875rem;
    padding: 18px 30px;
    background-color: ${({ theme }: PalletteProps) => theme.primaryColor};
    color: white;
    border-radius: 10px;
    font-weight: 900;
    line-height: 1;
    transition: ease all 200ms;
    box-shadow: 0px 0px 50px rgba(200, 200, 200, 0.2);
    border: none;
    width: 100%;
    text-transform: uppercase;
    &:hover {
      cursor: pointer;
      background: ${({ theme }: PalletteProps) => rgba(theme.primaryColor, 0.15)};
      color: ${({ theme }: PalletteProps) => theme.gray2};
    }
`;
