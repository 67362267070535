import {
  CategoriesSidebarMenu,
  CategoriesTitle,
  CategoryProductCount,
  MenuItemSidebar,
  SidebarCloseButton,
  SidebarDivider,
  SidebarWrapper,
  StyledBackdropWrapper,
} from './Sidebar.styled';
import { useEffect, useState } from 'react';

import { AccountDropDown } from '../../components';
import { Anchor } from '../../shared/Anchor';
import type { CategoryType } from '../../types/category';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'next/link';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import type { state } from '../../types/store/state';
import { useGetUserInfo } from '../../hooks/useGetUserInfo';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';

interface BackdropOverlayProps {
  setOpenSidebar: (value: boolean) => void;
}

export const Sidebar: React.FC<BackdropOverlayProps> = (props) => {
  const { t } = useTranslation();
  const router = useRouter();
  const categories = useSelector((state: state) => state.categories);

  const handleSidebarClose = () => {
    props.setOpenSidebar(false);
  };

  useEffect(() => {
    const handleEscKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleSidebarClose();
      }
    };
    document.addEventListener('keydown', handleEscKeyPress);
    return () => {
      document.removeEventListener('keydown', handleEscKeyPress);
    };
  }, []);

  const [logged, setLogged] = useState(false);
  useGetUserInfo(setLogged);

  const AllProductsTotalNumber = categories
    .map((item) => item.productCount)
    .reduce((prev, curr) => prev + curr, 0);

  return (
    <>
      <StyledBackdropWrapper
        onClick={handleSidebarClose}
      ></StyledBackdropWrapper>
      <SidebarWrapper>
        <SidebarCloseButton onClick={handleSidebarClose}>
          <FontAwesomeIcon icon={faClose} width={12} />
        </SidebarCloseButton>
        {logged ? (
          <div>
            <AccountDropDown />
          </div>
        ) : (
          <div>
            <Anchor url="/login" title={t('header.action.logged.logIn')} />
          </div>
        )}
        <SidebarDivider />
        <CategoriesTitle>
          {t('header.categories.selector.title')}
        </CategoriesTitle>
        <CategoriesSidebarMenu>
          <li onClick={handleSidebarClose}>
            <Link href="/products" passHref>
              <MenuItemSidebar
                className={
                  router.asPath === '/products' ? 'sidebarActiveCategory' : ''
                }
              >
                <div>{t('sidebar.li.all')}</div>
                <CategoryProductCount>
                  {AllProductsTotalNumber}
                </CategoryProductCount>
              </MenuItemSidebar>
            </Link>
          </li>
          {categories.map((category: CategoryType, index: number) => (
            <li key={index} onClick={handleSidebarClose}>
              <Link href={`/category/${category.id}`} passHref>
                <MenuItemSidebar
                  className={
                    router.asPath === `/category/${category.id}`
                      ? 'sidebarActiveCategory'
                      : ''
                  }
                >
                  <div>{category.name}</div>
                  <CategoryProductCount>
                    {category.productCount}
                  </CategoryProductCount>
                </MenuItemSidebar>
              </Link>
            </li>
          ))}
        </CategoriesSidebarMenu>
      </SidebarWrapper>
    </>
  );
};
