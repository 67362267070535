import {
  ButtonsWrapper,
  CancelButton,
  ModalCloseButton,
  ModalCloseIcon,
  ModalContent,
  ModalExclamationIcon,
  ModalWrapper,
  Title,
} from '../../../shared/DeleteConfirmationModal/DeleteConfirmationModal.styled';
import { CancelOrderCTA, UnderProductWrapper } from './CancelOrder.styled';
import {
  Description,
  Wrapper,
} from '../../ProductDetail/ProductDetail.styled';
import { MouseEvent, useState } from 'react';
import { faExclamationCircle, faTimes } from '@fortawesome/free-solid-svg-icons';

import { Anchor } from '../../../shared/Anchor';
import { Button } from '../../../shared';
import type { OrderType } from '../../../types/orders';
import { cancelOrder } from '../../../api/orders';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { setCart } from '../../../store/cart/cartSlice';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

export const CancelOrder = (props: OrderType) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [confirmCancelModal, setConfirmCancelModal] = useState(false);
  const router = useRouter();

  const handleConfirmAndCloseModal = async (event: MouseEvent) => {
    setConfirmCancelModal(false);
    await handleCancelOrder(event);
  };

  const handleConfirmModal = () => {
    setConfirmCancelModal(!confirmCancelModal);
  };

  const handleCancelOrder = async (event: MouseEvent) => {
    let paramsToSend = {
      userType: 'client',
    };
    try {
      const cart = await toast.promise(
        cancelOrder({
          id: props.id,
          params: paramsToSend,
        }),
        {
          pending: { render: () => t('orders.cancel.toastify.pending'), icon: '⚠️' },
          success: { render: () => t('orders.cancel.toastify.success'), icon: '✅' },
          error: { render: ({ data }: any) => `${data?.response?.status >= 400 ? t('orders.cancel.toastify.error') : data?.message}`, icon: '❗' },
        },
      );
      await dispatch(setCart(cart));
      await router.push('/orders/');
    } catch (error: any) {
      throw new Error(error);
    }
  };

  return (
    <UnderProductWrapper>
      <CancelOrderCTA>
        <Anchor
          title={t('orders.cancelOderCta.&.modal.title')}
          onClick={handleConfirmModal}
          icon={faTrashAlt}
          size="small"
          variation="secondary"
          disabled={!props.status?.canCancel}
        />
      </CancelOrderCTA>
      {confirmCancelModal ? (
        <ModalWrapper>
          <ModalContent>
            <ModalCloseButton onClick={handleConfirmModal}>
              <ModalCloseIcon icon={faTimes} />
            </ModalCloseButton>
            <Wrapper>
              <ModalExclamationIcon icon={faExclamationCircle} />
              <Title>{t('orders.cancelOderCta.&.modal.title')}</Title>
              <Description>
                {t('orders.modal.cancelOderDescription', { orderRef: props.reference })}
              </Description>
              <ButtonsWrapper>
                <Button
                  title={t('orders.modal.cta.confirm')}
                  onClick={handleConfirmAndCloseModal}
                />
                <CancelButton
                  title={t('orders.modal.cta.cancel')}
                  type="reset"
                  onClick={() => setConfirmCancelModal(false)}
                />
              </ButtonsWrapper>
            </Wrapper>
          </ModalContent>
        </ModalWrapper>
      ) : null}
    </UnderProductWrapper>
  );
};