import {
  CounterNumber,
  MinusButton,
  PlusButton,
  QuantityWrapper,
} from './QuantityButton.styled';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MouseEvent } from 'react';
import debounce from 'lodash.debounce';
import { useTranslation } from 'next-i18next';

interface AnchorType {
  count: number;
  setAdded?: (added: boolean) => void;
  setCount: (count: number) => void;
  handleAddToCart: (event: MouseEvent, quantity: number) => void;
  isDish?: boolean;
  disabled?: boolean;
}

const debouncedQuantity = debounce((event: MouseEvent, func: Function) => {
  func(event);
}, 1100);

export const QuantityButton = (props: AnchorType) => {
  const { t } = useTranslation();

  const decrementCount = (event: MouseEvent) => {
    if (props.count > 0) {
      props.setCount(props.count - 1);
      debouncedQuantity(event, updateDecrementCount);
    }
  };

  const updateDecrementCount = (event: MouseEvent) => {
    props.handleAddToCart(event, props.count - 1);
  };

  const incrementCount = (event: MouseEvent) => {
    props.setCount(props.count + 1);
    debouncedQuantity(event, updateIncrementCount);
  };

  const updateIncrementCount = (event: MouseEvent) => {
    props.handleAddToCart(event, props.count + 1);
  };

  return (
    <QuantityWrapper disabled={props.disabled}>
      <MinusButton
        onClick={decrementCount}
        title={t('shared.product.action.decrementQuantity')}
        disabled={props.disabled || props.count === 0}
      >
        <FontAwesomeIcon icon={faMinus} height={18} />
      </MinusButton>
      <CounterNumber>{props.count}</CounterNumber>
      <PlusButton
        onClick={incrementCount}
        title={t('shared.product.action.incrementQuantity')}
        disabled={props.disabled}
      >
        <FontAwesomeIcon icon={faPlus} height={18} />
      </PlusButton>
    </QuantityWrapper>
  );
};
