import { ButtonElement } from '../../../shared/Button/Button.styled';
import { PalletteProps } from '../../../types/theme/pallette';
import styled from 'styled-components';

export const Wrapper = styled.form`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-end;
  justify-content: center;
  background-color: ${({ theme }: PalletteProps) => theme.white};
  padding: 23px 35px;
  border-radius: 5px;
  box-shadow: 0px 0px 50px rgba(200, 200, 200, 0.2);
  gap: 20px;
  label {
    margin-bottom: 8px;
    padding-left: 0;
    font-size: 1.125rem;
    span {
      left: unset;
      right: 0;
      width: 18px;
      height: 18px;
      &:after {
        top: 1px;
        left: 5px;
      }
    }
  }
  ${ButtonElement} {
    width: 90px;
    height: 45px;
    text-transform: capitalize;
    font-family: "Inter";
    font-weight: 700;
    font-size: 1rem;
    padding: 15px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #inputCode {
    width: 167px;
    height: 45px;
    &::placeholder {
      text-transform: capitalize;
    }
  }
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobileL} {
    flex-direction: column;
    align-items: center;
    gap: 10px;
    ${ButtonElement} {
      width: 113px;
    }
    #inputCode {
      width: 226px;
    }
  }
  @media ${({ theme }: PalletteProps) => theme.breakpoints.tabletL} {
    flex-direction: row;
    align-items: flex-end;
    gap: 20px;
    ${ButtonElement} {
      width: 90px;
    }
    #inputCode {
      width: 167px;
    }
  }
`;
