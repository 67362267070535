import { AnchorButton, ClickButton } from './Anchor.styled';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface AnchorType {
  disabled?: boolean;
  productClass?: boolean;
  url?: string | null;
  title: string;
  variation?: 'primary' | 'secondary' | 'alternative';
  size?: 'small' | 'medium' | 'big';
  icon?: IconProp;
  onClick?: (event: React.MouseEvent) => void;
}

export const Anchor = (props: AnchorType) => {
  const {
    disabled,
    url = '',
    title,
    variation = 'primary',
    size = 'medium',
    onClick,
  } = props;

  if (url?.length) {
    return (
      <AnchorButton
        disabled={disabled}
        variation={variation}
        size={size}
        className='AnchorButton'
        onClick={() => {
          window.location.pathname = url;
        }}
      >
        {props.icon ? (
          <FontAwesomeIcon icon={props.icon} width={12} className="faIcon" />
        ) : null}
        {title}
      </AnchorButton>
    );
  } else {
    return (
      <ClickButton
        disabled={disabled}
        variation={variation}
        size={size}
        onClick={onClick}
      >
        {props.icon ? (
          <FontAwesomeIcon icon={props.icon} width={12} className="faIcon" />
        ) : null}
        {title}
      </ClickButton>
    );
  }
};
