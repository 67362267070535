import { getRequest, postRequest } from '../services/http';

import type { dataOptions } from './types/data';

export const registerUser = async (data: dataOptions) => {
  const response = await postRequest({
    url: 'auth/registration/',
    data: data.params,
    urlGeoapi: '',
  });

  return response;
};

export const userLogin = async (data: dataOptions) => {
  const response = await postRequest({
    url: 'auth/login/',
    data: data.params,
    urlGeoapi: '',
  });

  return response;
};

export const retreiveUser = async () => {
  const response = await getRequest({
    url: 'auth/user/',
    protected: true,
    urlGeoapi: '',
  });

  return response;
};

export const logoutUser = async () => {
  const response = await postRequest({
    url: 'auth/logout/',
    protected: true,
    urlGeoapi: '',
  });

  return response;
};

export const retreiveUserStores = async () => {
  const response = await getRequest({
    url: 'user/branch-stores/',
    protected: true,
    urlGeoapi: '',
  });

  return response;
};

export const updateUser = async (data: dataOptions) => {
  const response = await postRequest({
    url: 'auth/user/~update/',
    data: data.params,
    protected: true,
    urlGeoapi: '',
  });

  return response;
};

export const changePassword = async (data: dataOptions) => {
  const response = await postRequest({
    url: 'auth/password/change/',
    data: data.params,
    protected: true,
    urlGeoapi: '',
  });

  return response;
};

export const requestCodeToPhone = async (data: dataOptions) => {
  const response = await postRequest({
    url: 'auth/user/request-code/',
    data: data.params,
    protected: true,
    urlGeoapi: '',
  });

  return response;
};

export const verifyPhoneCode = async (data: dataOptions) => {
  const response = await postRequest({
    url: 'auth/user/validate-code/',
    data: data.params,
    protected: true,
    urlGeoapi: '',
  });

  return response;
};

export const resetPassword = async (data: dataOptions) => {
  const response = await postRequest({
    url: 'auth/password/reset/',
    data: data.params,
    protected: true,
    urlGeoapi: '',
  });

  return response;
};

export const confirmResetPassword = async (data: any) => {
  const response = await postRequest({
    url: `auth/password/reset/confirm/${data.params.uid}${data.params.token}/`,
    data: data.params,
    protected: true,
    urlGeoapi: '',
  });

  return response;
};

export const userLocation = async (data: dataOptions) => {
  const response = await postRequest({
    url: 'auth/user/locations/',
    protected: true,
    data: data.params,
    urlGeoapi: '',
  });

  return response;
};
