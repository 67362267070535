import { MouseEvent, useEffect, useState } from 'react';
import { addOrUpdateCart, deleteProductCart } from '../../../api/cart';
import { useDispatch, useSelector } from 'react-redux';

import { Anchor } from '../../../shared/Anchor';
import type { DishAttrType } from '../../../types/product';
import { QuantityButton } from '../../../shared/QuantityButton';
import { setCart } from '../../../store/cart/cartSlice';
import type { state } from '../../../types/store/state';
import { toast } from 'react-toastify';
import { useTranslation } from 'next-i18next';

interface Props {
  orderBranchProductId?: number;
  storeBranchProductId?: number;
  customCuantity?: number;
  added?: boolean;
  dishAttributes?: DishAttrType;
  observation?: string;
  isDish?: boolean;
  productUrl?: number;
  setDishAttributes?: (attr: DishAttrType) => void;
  setObservation?: (attr: string) => void;
  showQuantity?: boolean;
}

export const AddToCart = (props: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const cartReference = useSelector((state: state) => state.cart.reference);

  const [count, setCount] = useState(1);
  const [added, setAdded] = useState(false);

  useEffect(() => {
    if (props.customCuantity) {
      setCount(props.customCuantity);
    }
    if (props.added) {
      setAdded(props.added);
    }
  }, [props.customCuantity, props.added]);

  const handleCart = async (event: MouseEvent, count: number = 1) => {
    event.preventDefault();
    setAdded(true);
    let cart;
    let paramsToSend = {
      storeBranchProduct: props.storeBranchProductId,
      quantity: count,
      observation: props.observation,
      attributes: props.dishAttributes || {},
    };
    let orderBranchHandleParam = {
      orderBranchProduct: props.orderBranchProductId,
    };

    if (props.isDish) {
      if (count === 0) {
        delete paramsToSend.storeBranchProduct;
      }
      paramsToSend = {
        ...paramsToSend,
        ...orderBranchHandleParam,
      };
    }

    if (count > 0) {
      cart = await addOrUpdateCart({
        id: cartReference,
        params: paramsToSend,
      });

      if (
        props.setDishAttributes !== undefined &&
        props.setObservation !== undefined
      ) {
        props.setDishAttributes({});
        props.setObservation('');
      }
    } else {
      cart = await toast.promise(
        deleteProductCart({
          id: cartReference,
          params: paramsToSend,
        }),
        {
          pending: { render: () => t('cart.product.delete.toastify.pending'), icon: '⚠️' },
          success: { render: () => t('cart.product.delete.toastify.success'), icon: '✅' },
          error: { render: ({ data }: any) => `${data.response?.status > 400 ? t('cart.product.delete.toastify.error') : data.message}`, icon: '❗' },
        }
      );
      setAdded(false);
      setCount(1);
    }

    await dispatch(setCart(cart));
  };

  if (!added || props.customCuantity === 0) {
    return (
      <>
        <Anchor
          title={t('product.card.cta.details.title')}
          url={`/product/${props.productUrl}/`}
          size="medium"
          variation="primary"
        />
      </>
    );
  }

  return (
    <>
      {props.isDish && props.showQuantity === true ? (
        <Anchor
          title={t('product.card.cta.details.title')}
          url={`/product/${props.productUrl}/`}
          size="medium"
          variation="primary"
          productClass={true}
        />
      ) : (
        <QuantityButton
          handleAddToCart={handleCart}
          setCount={setCount}
          count={count}
          setAdded={setAdded}
        />
      )}
    </>
  );
};
