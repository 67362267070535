import {
  CategoriesSidebarMenu,
  CategoriesTitle,
  CategoryProductCount,
  MenuItemSidebar,
  SidebarWrapper,
} from './SidebarCategories.styled';

import type { CategoryType } from '../../types/category';
import Link from 'next/link';
import type { state } from '../../types/store/state';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';

export const SidebarCategories = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const categories = useSelector((state: state) => state.categories);

  const AllProductsTotalNumber = categories
    .map((item) => item.productCount)
    .reduce((prev, curr) => prev + curr, 0);

  return (
    <>
      <div>
        <SidebarWrapper>
          <CategoriesTitle>
            {t('header.categories.selector.title')}
          </CategoriesTitle>
          <CategoriesSidebarMenu>
            <Link href="/products/" passHref>
              <MenuItemSidebar
                className={
                  router.asPath === '/products' ? 'sidebarActiveCategory' : ''
                }
              >
                {t('sidebar.li.all')}
                <CategoryProductCount>
                  {AllProductsTotalNumber}&nbsp;
                </CategoryProductCount>
              </MenuItemSidebar>
            </Link>
            {categories.map((category: CategoryType, index: number) => (
              <li key={index}>
                <Link href={`/category/${category.id}`} passHref>
                  <MenuItemSidebar
                    className={
                      router.asPath === `/category/${category.id}`
                        ? 'sidebarActiveCategory'
                        : ''
                    }
                  >
                    {category.name}
                    <CategoryProductCount>
                      {category.productCount}&nbsp;
                    </CategoryProductCount>
                  </MenuItemSidebar>
                </Link>
              </li>
            ))}
          </CategoriesSidebarMenu>
        </SidebarWrapper>
      </div>
    </>
  );
};
