import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from '../services/http';

import type { dataOptions } from './types/data';

export const retreiveCart = async (data: dataOptions) => {
  const response = await getRequest({
    url: `cart/${data.id}/`,
    protected: true,
    urlGeoapi: '',
  });
  return response;
};

export const createCart = async () => {
  const response = await postRequest({
    url: 'cart/',
    protected: true,
    urlGeoapi: '',
  });
  return response;
};

export const addOrUpdateCart = async (data: dataOptions) => {
  const response = await postRequest({
    url: `cart/${data.id}/products/`,
    protected: true,
    data: data.params,
    urlGeoapi: '',
  });
  return response;
};

export const deleteProductCart = async (data: dataOptions) => {
  const response = await deleteRequest({
    url: `cart/${data.id}/products/`,
    protected: true,
    data: data.params,
    urlGeoapi: '',
  });
  return response;
};

export const cartCheckout = async (data: dataOptions) => {
  const response = await postRequest({
    url: `cart/${data.id}/checkout/?orderRemotely=${data.query}&source=web`,
    protected: true,
    data: data.params,
    urlGeoapi: '',
  });
  return response;
};

export const cleanCart = async (data: dataOptions) => {
  const response = await deleteRequest({
    url: `cart/${data.id}/clean-cart/`,
    protected: true,
    urlGeoapi: '',
  });
  return response;
};

export const addOrUpdateObservation = async (data: dataOptions) => {
  const response = await putRequest({
    url: `cart/${data.id}/products/`,
    protected: true,
    data: data.params,
    urlGeoapi: '',
  });
  return response;
};

export const calculateDelivery = async (data: dataOptions) => {
  const response = await postRequest({
    url: `cart/${data.id}/calculate-delivery/`,
    protected: true,
    urlGeoapi: '',
  });
  return response;
};