import {
  BottomSection,
  Destination,
  DestinationTitle,
  DestinationWrapper,
  Items,
  MiddleLeft,
  MiddleRight,
  MiddleSection,
  ProductsImages,
  Reference,
  RightArrowButton,
  RightArrowIcon,
  Row,
  RowElem,
  RowElemDescription,
  RowElemTitle,
  Status,
  TopLeft,
  TopSection,
  Total,
  Type,
  Wrapper
} from './OrderElement.styled';

import { CancelOrder } from './CancelOrder/CancelOrder';
import { Fragment } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { OrderCost } from '../Checkout/OrderCost';
import type { OrderType } from '../../types/orders';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import dayjs from 'dayjs';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import type { state } from '../../types/store/state';
import { useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';

interface Props extends OrderType {
  showDetails?: boolean | false;
}

export const OrderElement = (props: Props) => {
  const branchStore = useSelector(({ branchStore }: state) => branchStore );
  const { t } = useTranslation();

  return (
    <Wrapper>
      <TopSection>
        <TopLeft>
          {!props.showDetails ?
            <Link href={`/orders/${props.id}`} passHref>
              <Reference data-tip data-for='moreOrderDetails'>
                {t('orders.orderId.title')}
                {props.reference}
                <RightArrowButton className='rightEase'>
                  <RightArrowIcon type={props.orderType} icon={faChevronRight} />
                </RightArrowButton>
              </Reference>
            </Link>
            :
            <Reference >
              <span className="noLink">
                {t('orders.orderId.title')}
                {props.reference}
              </span>
            </Reference>
          }
          <ReactTooltip id="moreOrderDetails" type="dark" place="top" effect="float">
            {t('orders.orderId.tooltip.title')}
          </ReactTooltip>
          <Type type={props.orderType}>{props.orderType}</Type>
        </TopLeft>
        {props.status ? (
          <Status status={props.status.description}>
            {(props.status.description).replaceAll('_', ' ')}
          </Status>
        ) : null}
      </TopSection>
      <MiddleSection>
        <MiddleLeft>
          <DestinationWrapper>
            <DestinationTitle>
              {props.orderType === 'delivery'
                ? `${t('orders.detail.destination.title')}`
                : `${t('orders.detail.pickupAt.title')}`
              }
            </DestinationTitle>
            <Destination>
              {props.orderType === 'delivery'
                ? `${props.deliveryAddress?.address.address}`
                : `${branchStore.address.address}`}
            </Destination>
          </DestinationWrapper>
          <Row>
            <RowElem>
              <RowElemTitle>{t('orders.detail.date.title')}</RowElemTitle>
              <RowElemDescription>
                {dayjs(
                  props.deliveryDatetime
                    ? props.deliveryDatetime
                    : props.createdAt
                ).format('MM/DD/YYYY')}
              </RowElemDescription>
            </RowElem>
            {props.deliveredBy ? (
              <RowElem>
                <RowElemTitle>{t('orders.detail.shapyer.title')}</RowElemTitle>
                <RowElemDescription>{props.deliveredBy}</RowElemDescription>
              </RowElem>
            ) : null}
          </Row>
        </MiddleLeft>
        <MiddleRight>
          {props.productsCount > 0 && (
            <ProductsImages>
              {props.branchStores[0].products.slice(0, 3).map(({ product }) => (
                <Image
                  key={product.sku}
                  src={product.images[0].image}
                  alt={product.name}
                  width={60}
                  height={60}
                  objectFit="contain"
                />
              ))}
            </ProductsImages>
          )}
          {props.productsCount > 3 && (
            <Items>
              +{props.productsCount - 3}{' '}
              {t('orders.detail.productsCount.title')}
            </Items>
          )}
          {!props.showDetails && props.orderCost ? (
            <Total>
              {t('orders.status.tag.resume.total.title')}{' '}
              {props.orderCost.currency.symbol}
              {props.orderCost.total}
            </Total>
          ) : null}
        </MiddleRight>
      </MiddleSection>
      {props.showDetails ? (
        <BottomSection>
          <OrderCost orderCost={props.orderCost} orderType={props.orderType} isLarge />
          <Fragment key={props.id} >
            <CancelOrder {...props}/>
          </Fragment>
        </BottomSection>
      ) : null}
    </Wrapper>
  );
};
