import { Description, Wrapper } from '../../components/ProductDetail/ProductDetail.styled';

import { Button } from '../Button';
import { ButtonElement } from '../Button/Button.styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PalletteProps } from '../../types/theme/pallette';
import styled from 'styled-components';

export const Card = styled.div`
  padding: 26px 30px;
  border-radius: 5px;
  background: ${({ theme }: PalletteProps) => theme.white};
  max-width: 285px;
  width: 100%;
  box-shadow: 0px 0px 50px rgba(200, 200, 200, 0.2);
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    max-width: unset;
  }
`;

export const Title = styled.h3`
  font-size: 2rem;
  color: black;
  line-height: 1.125;
  font-weight: 700;
  text-align: center;
`;

export const ModalOpenButton = styled.button`
  cursor: pointer;
  border: none;
  background-color: unset;
  width: 100%;
`;

export const ModalWrapper = styled.div`
  display: block;
  position: fixed;
  z-index: 1;
  padding-top: 150px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.5);
  @-webkit-keyframes animatetop {
    from {
      top: -300px;
      opacity: 0;
    }
    to {
      top: 0;
      opacity: 1;
    }
  }
  @keyframes animatetop {
    from {
      top: -300px;
      opacity: 0;
    }
    to {
      top: 0;
      opacity: 1;
    }
  }
`;

export const ModalContent = styled.div`
  position: relative;
  max-width: 450px;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
  ${Wrapper} {
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    grid-template-columns: 1fr;
    grid-gap: 40px;
    margin-bottom: 50px;
    justify-items: center;
    ${Description} {
      font-size: 1rem;
      text-align: center;
      font-weight: 300;
      margin-bottom: 0;
      color: #4f4f4f;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px 25px;
      span {
        font-size: .875rem;
        font-weight: 500;
      }
    }
  }
`;

export const ModalCloseButton = styled.span`
  color: ${({ theme }: PalletteProps) => theme.gray2};
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 58px;
  padding: 0px 10px;
  &:hover,
  &:focus {
    color: ${({ theme }: PalletteProps) => theme.gray3};
    text-decoration: none;
    cursor: pointer;
  }
`;

export const ButtonsWrapper = styled.div`
  max-width: 600px;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  ${ButtonElement} {
    width: 130px;
    height: 41px;
    text-transform: capitalize;
    font-family: 'Inter';
    font-weight: 700;
    font-size: 1rem;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;
  }
`;

export const CancelButton = styled(Button)`
  background-color: ${({ theme }: PalletteProps) => theme.gray4};
  color: ${({ theme }: PalletteProps) => theme.gray2};
  &:hover {
    background: ${({ theme }: PalletteProps) => theme.gray4};
  }
`;

export const ModalCloseIcon = styled(FontAwesomeIcon)`
  background: none;
  border: none;
  width: 2.3rem;
  height: 2.3rem;
  font-weight: 600;
  &:hover {
    cursor: pointer;
  }
`;

export const ModalExclamationIcon = styled(FontAwesomeIcon)`
  color: #f2c94c;
  background: none;
  border: none;
  width: 5rem;
`;
