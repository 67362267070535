import axios from 'axios';
import { setOptions } from './setOptions';
import type { reqOptions } from './types/request';

export const postRequest = async (options: reqOptions) => {
  const serviceOptions = setOptions(options);

  const response = await axios.post(serviceOptions.url, serviceOptions.params, {
    headers: serviceOptions.headers,
  });

  return response.data;
};

export const fortisPostRequest = async (options: reqOptions) => {
  const response = await axios.post(
    'https://api.sandbox.fortis.tech/v1/elements/' + options.url,
    {
      location_id: options.locationId,
    }, {
    headers: {
      'user-id': options.userId ? options.userId : '',
      'user-api-key': options.apiKey ? options.apiKey : '',
      'developer-id': options.developerId ? options.developerId : '',
    },
  });

  return response.data;
};

export const getRequest = async (options: reqOptions) => {
  const serviceOptions = setOptions(options);

  const response = await axios.get(serviceOptions.url, {
    headers: serviceOptions.headers,
  });

  return response.data;
};

export const deleteRequest = async (options: reqOptions) => {
  const serviceOptions = setOptions(options);

  const response = await axios.delete(serviceOptions.url, {
    data: serviceOptions.params,
    headers: serviceOptions.headers,
  });

  return response.data;
};

export const putRequest = async (options: reqOptions) => {
  const serviceOptions = setOptions(options);

  const response = await axios.put(serviceOptions.url, serviceOptions.params, {
    headers: serviceOptions.headers,
  });

  return response.data;
};

export const postRequestLocations = async (options: reqOptions) => {
  const serviceOptions = setOptions(options);

  const response = await axios.post(
    serviceOptions.urlGeoapi,
    serviceOptions.params,
    {
      headers: serviceOptions.headers,
    }
  );

  return response.data;
};

export const getRequestLocations = async (options: reqOptions) => {
  const serviceOptions = setOptions(options);

  const response = await axios.get(serviceOptions.urlGeoapi, {
    headers: serviceOptions.headers,
    data: serviceOptions.params,
  });

  return response.data;
};
