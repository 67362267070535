import styled from 'styled-components';

export const BackdropWrapper = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;