import { PalletteProps } from '../../types/theme/pallette';
import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
  color: ${({ theme }: PalletteProps) => theme.gray2};
  padding-left: 44px;
  margin-bottom: 31px;
`;

export const Subtitle = styled.h3`
  font-size: 1.125rem;
  font-weight: 600;
  color: ${({ theme }: PalletteProps) => theme.gray2};
  margin-bottom: 16px;
`;

export const SectionWrapper = styled.div`
  background-color: white;
  box-shadow: 0px 0px 50px rgba(200, 200, 200, 0.2);
  border-radius: 5px;
  padding: 20px 10px;
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    padding: 31px 53px;
  }
`;

export const ContentWrapper = styled.div`
  display: grid;
  grid-gap: 20px;
`;
