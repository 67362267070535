import {
  CategoryRow,
  ViewAllButton,
} from '../../components/CategoryProducts/CategoryProducts.styled';
import { Divider, ProductsWrapper, Title, Wrapper } from './ProductsRow.styled';

import { Fragment } from 'react';
import Link from 'next/link';
import { Product } from '../Product';
import type { ProductType } from '../../types/product';
import { useTranslation } from 'next-i18next';

interface Props {
  title: string;
  elements: Array<ProductType>;
  idHomeCat?: number;
}
const defaultProps = {
  title: '',
  elements: [],
  idHomeCat: 0,
};

export const ProductRow = (props: Props = defaultProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <CategoryRow>
        <Title>{props.title}</Title>
        {props.idHomeCat && props.idHomeCat > 0 ? (
          <Link href={`/category/${props.idHomeCat}`} passHref>
            <a>
              <ViewAllButton>{t('shared.action.viewAll')}</ViewAllButton>
            </a>
          </Link>
        ) : null}
      </CategoryRow>
      <Divider />
      <ProductsWrapper>
        {props.elements?.length
          ? props.elements.map((element: ProductType) => (
              <Fragment key={element.id}>
                <Product {...element} />
              </Fragment>
            ))
          : null}
      </ProductsWrapper>
    </Wrapper>
  );
};
