import {
  Bottom,
  Button,
  ButtonsWrapper,
  CartCloseButton,
  EmptyIcon,
  EmptySubtitle,
  EmptyTitle,
  EmptyWrapper,
  Head,
  HeadTitle,
  ProductsWrapper,
  StyledBackdropWrapper,
  SubtotalAmount,
  SubtotalText,
  SubtotalWrapper,
  Wrapper,
} from './CartSidebar.styled';
import { Fragment, MouseEvent } from 'react';
import {
  faCartPlus,
  faCartShopping,
  faClose,
  faEye,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';

import { Anchor } from '../../../shared/Anchor';
import { CartProduct } from '../CartProduct';
import { FlowChekoutAndCartNotification } from '../../Notification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'next/link';
import type { ProductType } from '../../../types/product';
import Router from 'next/router';
import { cleanCart } from '../../../api/cart';
import { setCart } from '../../../store/cart/cartSlice';
import type { state } from '../../../types/store/state';
import { useEffect } from 'react';
import { useTranslation } from 'next-i18next';

interface BackdropOverlayProps {
  setOpenSidebar: (value: boolean) => void;
}

export const CartSidebar: React.FC<BackdropOverlayProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const cart = useSelector(({ cart }: state) => cart);

  const handleSidebarClose = () => {
    props.setOpenSidebar(false);
  };

  useEffect(() => {
    const handleEscKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleSidebarClose();
      }
    };
    document.addEventListener('keydown', handleEscKeyPress);
    return () => {
      document.removeEventListener('keydown', handleEscKeyPress);
    };
  }, []);

  const handleCleanCart = async (event: MouseEvent) => {
    event.preventDefault();
    try {
      const result = await cleanCart({ id: cart.reference });
      await dispatch(setCart(result));
      Router.reload();
    } catch (error: any) {
      throw new Error(error);
    }
  };

  return (
    <>
      <StyledBackdropWrapper
        onClick={handleSidebarClose}
      ></StyledBackdropWrapper>
      <Wrapper>
        <CartCloseButton onClick={handleSidebarClose}>
          <FontAwesomeIcon icon={faClose} width={12} />
        </CartCloseButton>
        <Head>
          <HeadTitle>{t('cart.sideView.title')}</HeadTitle>
          <SubtotalWrapper>
            <SubtotalText>{t('cart.shared.subtotal')}</SubtotalText>
            <SubtotalAmount>
              {cart.productsCount
                ? `${cart.orderCost?.currency.symbol
                } ${cart.orderCost?.productsCost.toFixed(2)}`
                : '$ 0.00'}
            </SubtotalAmount>
          </SubtotalWrapper>
        </Head>
        {cart.productsCount ? (
          <ProductsWrapper>
            {cart.branchStores[0].products.map((product: ProductType) => (
              <Fragment key={product.id}>
                <CartProduct {...product} />
              </Fragment>
            ))}
          </ProductsWrapper>
        ) : (
          <EmptyWrapper>
            <EmptyIcon icon={faCartShopping} width={120} />
            <EmptyTitle>{t('cart.sideView.empty.title')}</EmptyTitle>
            <EmptySubtitle>{t('cart.sideView.empty.subTitle')}</EmptySubtitle>
          </EmptyWrapper>
        )}
        {cart.productsCount ? (
          <Bottom>
            <Anchor
              url="/checkout/"
              title={t('cart.shared.action.toCheckout')}
              size="medium"
            />
            <div className="keepBuyingButton">
              <Anchor
                title={t('cart.shared.action.keepBuying')}
                url="/"
                icon={faCartPlus}
                size="small"
                variation="secondary"
              />
            </div>
            <ButtonsWrapper>
              <Button onClick={handleCleanCart}>
                <FontAwesomeIcon icon={faTrashAlt} width={12} />
                {t('cart.shared.action.clean')}
              </Button>
              <Link href="/cart/" passHref>
                <Button>
                  <FontAwesomeIcon icon={faEye} width={12} />
                  {t('cart.shared.action.view')}
                </Button>
              </Link>
            </ButtonsWrapper>
            <FlowChekoutAndCartNotification />
          </Bottom>
        ) : (
          <div />
        )}
      </Wrapper>
    </>
  );
};
