import { ChangeEvent, useState } from 'react';
import {
  Icon,
  IconSpin,
  MobileSearchIconButton,
  MobileSearchIconWrapper,
  ModalCloseButton,
  ModalCloseIcon,
  ModalContent,
  ModalSearchBarWrapper,
  ModalWrapper,
  SearchBarWrapper,
  SearchInput,
  SearchResults,
} from './SearchBar.styled';
import { faCircleNotch, faMagnifyingGlass, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { ProductType } from '../../types/product';
import { SearchResult } from './SearchResult';
import { searchProducts } from '../../api/branchStores';
import type { state } from '../../types/store/state';
import { useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';
import { debouncedSearch } from '../../utils/debounceSearch';

export const SearchBar = () => {
  const { t } = useTranslation();
  const storeId = useSelector((state: state) => state.ui.storeId);
  const [search, setSearch] = useState('');
  const [searching, setSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchModal, setSearchModal] = useState(false);

  const onChange = async (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    debouncedSearch(event.target.value, handleSearch);
  };

  const handleSearch = async (term: string) => {
    if (search.length >= 2) {
      try {
        await setSearching(true);
        const products = await searchProducts({
          id: storeId,
          term: term,
        });
        await setSearchResults(products.results);
        await setSearching(false);
      } catch (error: any) {
        setSearching(false);
        throw new Error(error);
      }
    }
  };

  const clear = () => {
    setSearchResults([]);
    setSearch('');
  };

  const openSearchModal = () => {
    setSearchModal(!searchModal);
    searchModal === false
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'auto');
  };

  return (
    <>
      <MobileSearchIconWrapper>
        <MobileSearchIconButton onClick={openSearchModal}>
          <FontAwesomeIcon icon={faMagnifyingGlass} />
        </MobileSearchIconButton>
      </MobileSearchIconWrapper>

      {searchModal ? (
        <ModalWrapper>
          <ModalContent>
            <ModalCloseButton onClick={openSearchModal}>
              <ModalCloseIcon icon={faTimes} />
            </ModalCloseButton>
            <ModalSearchBarWrapper>
              <SearchInput
                placeholder={t('header.search.placeholder')}
                onChange={onChange}
                value={search}
              />
              {searching ? (
                <IconSpin icon={faCircleNotch} width={20} />
              ) : (
                <Icon
                  icon={searchResults.length ? faTimes : faMagnifyingGlass}
                  width={searchResults.length ? 16 : 20}
                  onClick={clear}
                />
              )}
              {searchResults.length ? (
                <SearchResults>
                  {searchResults.map((elem: ProductType) => (
                    <SearchResult key={elem.id} {...elem} />
                  ))}
                </SearchResults>
              ) : null}
            </ModalSearchBarWrapper>
          </ModalContent>
        </ModalWrapper>
      ) : null}

      <SearchBarWrapper>
        <SearchInput
          placeholder={t('header.search.placeholder')}
          onChange={onChange}
          value={search}
        />
        {searching ? (
          <IconSpin icon={faCircleNotch} width={20} />
        ) : (
          <Icon
            icon={searchResults.length ? faTimes : faMagnifyingGlass}
            width={searchResults.length ? 16 : 20}
            onClick={clear}
          />
        )}
        {searchResults.length ? (
          <SearchResults>
            {searchResults.map((elem: ProductType) => (
              <SearchResult key={elem.id} {...elem} />
            ))}
          </SearchResults>
        ) : null}
      </SearchBarWrapper>
    </>
  );
};
