import {
  Address,
  DeleteButton,
  DeleteIcon,
  EditButton,
  Item,
  Name,
  StarButton,
  StarIcon,
  Top,
} from './AddressItem.styled';
import {
  ButtonsWrapper,
  CancelButton,
  ModalCloseButton,
  ModalCloseIcon,
  ModalContent,
  ModalExclamationIcon,
  ModalWrapper,
  Title,
} from '../../shared/DeleteConfirmationModal/DeleteConfirmationModal.styled';
import {
  Description,
  Wrapper,
} from '../../components/ProductDetail/ProductDetail.styled';
import { deleteAddress, setPrimaryAddress } from '../../api/addresses';
import {
  deleteUserAddress,
  setPrimary,
} from '../../store/addresses/addressesSlice';
import {
  faExclamationCircle,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { faStar, faTrashCan } from '@fortawesome/free-solid-svg-icons';

import { Button } from '../../shared';
import { FormEvent } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import type { UserAdressType } from '../../types/address';
import { faStar as faStarEmpty } from '@fortawesome/free-regular-svg-icons';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { useTranslation } from 'next-i18next';

interface Props extends UserAdressType {
  setOpenEditAddressForm: (open: boolean) => void;
  openEditAddressForm: boolean;
  setAddressToEdit: (item: UserAdressType) => void;
}

export const AddressItem = (props: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const icon = props.primary ? faStar : (faStarEmpty as IconProp);

  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);

  const handleConfirmModal = async () => {
    setConfirmDeleteModal(!confirmDeleteModal);
  };

  const handleDeleteUserAddress = async (event: FormEvent<HTMLElement>) => {
    event.preventDefault();
    try {
      await toast.promise(
        deleteAddress({
          id: props.address.id,
        }),
        {
          pending: {
            render() {
              return `${t('addresses.delete.toastify.pending')}`;
            },
            icon: '⚠️',
          },
          success: {
            render() {
              return `${t('addresses.delete.toastify.success')}`;
            },
            icon: '✅',
          },
          error: {
            render() {
              return `${t('addresses.delete.toastify.error')}`;
            },
            icon: '❗',
          }
        }
      );

      await dispatch(deleteUserAddress({ id: props.id }));
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const handleSetPrimaryAddress = async (event: FormEvent<HTMLElement>) => {
    event.preventDefault();
    try {
      await toast.promise(
        setPrimaryAddress({
          id: props.id,
        }),
        {
          pending: {
            render() {
              return `${t('addresses.setPrimary.toastify.pending')}`;
            },
            icon: '⚠️',
          },
          success: {
            render() {
              return `${t('addresses.setPrimary.toastify.success')}`;
            },
            icon: '✅',
          },
          error: {
            render({data}: any) {
              if (data.response.status > 400) {
                return `${t('addresses.setPrimary.toastify.error')}`;
              }
             return `${data.message}`;
            },
            icon: '❗',
          }
        }
      );

      await dispatch(setPrimary({ id: props.id }));
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const handleEditAddress = (event: FormEvent<HTMLElement>) => {
    event.preventDefault();
    props.setOpenEditAddressForm(!props.openEditAddressForm);
    props.setAddressToEdit({
      id: props.id,
      name: props.name,
      primary: props.primary,
      address: props.address,
    });
  };

  return (
    <>
      <Item>
        <Top>
          <StarButton onClick={handleSetPrimaryAddress}>
            <StarIcon icon={icon} width={23} primary={!!props.primary} />
          </StarButton>
          <Name>{props.name || 'My Address'}</Name>
          <EditButton onClick={handleEditAddress}>
            {t('shared.action.edit')}
          </EditButton>
        </Top>
        <Address>{props.address.address}</Address>
        <DeleteButton onClick={handleConfirmModal}>
          <DeleteIcon icon={faTrashCan} width={14} />
        </DeleteButton>
        {confirmDeleteModal ? (
          <ModalWrapper>
            <ModalContent>
              <ModalCloseButton onClick={handleConfirmModal}>
                <ModalCloseIcon icon={faTimes} />
              </ModalCloseButton>
              <Wrapper>
                <ModalExclamationIcon icon={faExclamationCircle} />
                <Title>{t('addresses.deleteModal.title')}</Title>
                <Description>
                  {t('addresses.deleteModal.description')}
                </Description>
                <ButtonsWrapper>
                  <Button
                    title={t('addresses.deleteModal.cta.delete')}
                    onClick={handleDeleteUserAddress}
                  />
                  <CancelButton
                    title={t('addresses.deleteModal.cta.cancel')}
                    type="reset"
                    onClick={() => setConfirmDeleteModal(false)}
                  />
                </ButtonsWrapper>
              </Wrapper>
            </ModalContent>
          </ModalWrapper>
        ) : null}
      </Item>
    </>
  );
};
