import { AnchorButton } from '../../shared/Anchor/Anchor.styled';
import { PalletteProps } from '../../types/theme/pallette';
import styled from 'styled-components';

interface props extends PalletteProps {
  isFixed: boolean;
}

export const HeaderWrapper = styled.header`
  display: grid;
  grid-template-columns: 2fr repeat(2, 1fr);
  grid-gap: 1rem;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
  background-color: ${({ theme }: PalletteProps) => theme.white};
  box-shadow: 0px 0px 50px rgba(200, 200, 200, 0.2);
  z-index: 99;
  width: 100%;
  max-height: 20vh;
  top: 0;
  position: ${({ isFixed }: props) => (isFixed ? 'fixed' : 'relative')};
  .backToTopIcon {
    color: ${({ theme }: PalletteProps) => theme.primaryColor};
    width: 20px;
  }
  .scroll-to-top {
    background-color: ${({ theme }: PalletteProps) => theme.white};
    right: 20px;
    bottom: 40px;
    position: fixed;
    z-index: 2;
    cursor: pointer;
    border-radius: 50px;
    width: 40px;
    height: 40px;
    transition: opacity 1s ease-in-out;
    box-shadow: 0 9px 25px 0 rgba(132, 128, 177, 0.28);
    border: none;
    outline: none;
  }
  .scroll-to-top:active {
    transform: matrix(0.95, 0, 0, 0.95, 0, 0);
  }
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    grid-template-columns: repeat(3, 1.5fr);
  }
  @media ${({ theme }: PalletteProps) => theme.breakpoints.tabletL} {
    grid-template-columns: 2fr 6fr 1.5fr;
    .scroll-to-top {
      right: 40px;
      width: 60px;
      height: 60px;
    }
  }
`;

export const MiddleSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 50px;
  @media ${({ theme }: PalletteProps) => theme.breakpoints.tablet} {
    display: none;
  }
`;

export const RightSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .languageLink {
    margin-left: 25px;
    text-align: center;
    color: ${({ theme }: PalletteProps) => theme.primaryColor};
    font-weight: 700;
  }
  @media ${({ theme }: PalletteProps) => theme.breakpoints.tabletSmax} {
    .languageLink {
      margin-left: 0px;
      margin-right: 25px;
    }
  }
`;

export const LogoAnchor = styled.a`
  margin: auto;
  &:hover {
    cursor: pointer;
  }
`;

export const AccountState = styled.div`
  display: none;
  @media ${({ theme }: PalletteProps) => theme.breakpoints.tablet} {
    ${AnchorButton}{
      width: 107px;
      padding: 10px 20px;
    }
    display: block;
  }
`;

export const MenuSidebarButton = styled.button`
  display: block;
  border: 0;
  color: ${({ theme }: PalletteProps) => theme.primaryColor};
  background: transparent;
  cursor: pointer;
  &:focus-visible {
    outline: none;
  }
  @media ${({ theme }: PalletteProps) => theme.breakpoints.tablet} {
    display: none;
  }
`;
