import { ProdRow } from './ContainedProductRow.styled';
import { ReactNode } from 'react';

interface props {
  children: ReactNode;
}

export const ContainedProductRow = ({ children }: props) => {
  return <ProdRow>{children}</ProdRow>;
};
