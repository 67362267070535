import { NotAvailableText, PickupText, TabItem, Tabs, Wrapper } from './OrderType.styled';
import { faMotorcycle, faShoppingBag } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';

import { AddressesWrapper } from '../../Addresses/AddressesWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { OrderDeliveryType } from '../../../types/orders';
import type { state } from '../../../types/store/state';
import { useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';

interface Props {
  setOrderType: (type: OrderDeliveryType) => void;
}

export const OrderType = (props: Props) => {
  const { t } = useTranslation();

  const { hasDelivery, hasPickup, address } = useSelector(({ branchStore }: state) => branchStore);
  const pickupText = address
    ? `${t('orders.pickup.tag.description')} ${address.address} ${address.city} ${address.state}`
    : '';

  const [selectedOrderType, setSelectedOrderType] = useState<OrderDeliveryType>(hasPickup && hasDelivery ? 'delivery' : (hasPickup ? 'pickup' : 'delivery'));

  useEffect(() => {
    props.setOrderType(selectedOrderType);
  }, [selectedOrderType]);

  const getContent = () => {
    if (selectedOrderType === 'pickup') {
      return <PickupText>{pickupText}</PickupText>;
    }
    return (
      <>
        <PickupText>{t('orders.delivery.tag.description')}</PickupText>
        <AddressesWrapper condensed={true} />
      </>
    );
  };

  return (
    <Wrapper>
      <Tabs>
        <TabItem
          onClick={() => hasDelivery && setSelectedOrderType('delivery')}
          active={selectedOrderType === 'delivery'}
          disabled={!hasDelivery}
        >
          <FontAwesomeIcon icon={faMotorcycle} width={16} className="faIcon" />
          {t('orders.delivery.tag.title')}
          {!hasDelivery && <NotAvailableText>
            {t('orders.notAvailable.tag.title')}
          </NotAvailableText>}
        </TabItem>
        <TabItem
          onClick={() => hasPickup && setSelectedOrderType('pickup')}
          active={selectedOrderType === 'pickup'}
          disabled={!hasPickup}
        >
          <FontAwesomeIcon icon={faShoppingBag} width={16} className="faIcon" />
          {t('orders.pickup.tag.title')}
          {!hasPickup && <NotAvailableText>
            {t('orders.notAvailable.tag.title')}
          </NotAvailableText>}
        </TabItem>
      </Tabs>
      {getContent()}
    </Wrapper>
  );
};
