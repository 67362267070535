export interface AddPaymentType {
  nonFieldErrors: Array<string>;
  nameOnCard: Array<string>;
  number: Array<string>;
  expMonth: Array<string>;
  cvc: Array<string>;
};

export const defaultAddPaymentType = {
  nonFieldErrors: [],
  nameOnCard: [],
  number: [],
  expMonth: [],
  cvc: [],
};