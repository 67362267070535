import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PalletteProps } from '../../types/theme/pallette';
import styled from 'styled-components';

export const Element = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 10px;
  gap: 5px;
  border-bottom: solid 1px ${({ theme }: PalletteProps) => theme.lightGray};
  transition: ease all 200ms;
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }: PalletteProps) => theme.gray10};
  }
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 17px;
    padding: 12px 18px;
  }
`;

export const ImageWrapper = styled.figure`
  background-color: white;
  position: relative;
  width: 72px;
  height: 72px;
`;

export const DetailsWrapper = styled.div`
  flex: 1;
`;

export const ProductName = styled.h4`
  color: black;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.18;
  margin-bottom: 2px;
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    font-size: 1rem;
  }
`;

export const ProductUnits = styled.p`
  color: ${({ theme }: PalletteProps) => theme.gray3};
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.18;
  font-style: italic;
`;

export const PriceWrapper = styled.div`
  grid-gap: 8px;
`;

export const ProductPrice = styled.p`
  font-size: 1.125rem;
  color: black;
  line-height: 1.125 font-weight 700;
`;

export const PriceDiscount = styled.p`
  font-size: 0.875rem;
  color: ${({ theme }: PalletteProps) => theme.red1};
  text-decoration-line: line-through;
`;

export const Icon = styled(FontAwesomeIcon)`
  color: ${({ theme }: PalletteProps) => theme.gray2};
  display: none;
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    display: block;
  }
`;
