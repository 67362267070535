import { PalletteProps } from '../../types/theme/pallette';
import styled from 'styled-components';

export const ProductsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 8px;
  justify-items: center;
  padding: 0px 8px;
  margin-bottom: 55px;
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    justify-items: space-between;
  }
  @media ${({ theme }: PalletteProps) => theme.breakpoints.tablet} {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 39px;
  }
  @media ${({ theme }: PalletteProps) => theme.breakpoints.tabletL} {
    padding: 0px;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 110px;
  }
  @media ${({ theme }: PalletteProps) => theme.breakpoints.laptopM} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const TopSection = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 0;
`;

export const ItemsCount = styled.p`
  font-size: 0.875rem;
  padding: 0 15px;
  color: ${({ theme }: PalletteProps) => theme.gray2};
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    font-size: 1rem;
  }
`;

export const CategoryRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  column-gap: 20px;
  margin-bottom: 28px;
  padding: 0 30px;
  max-width: 350px;
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    margin-bottom: 38px;
    max-width: 100%;
    justify-content: flex-start;
  }
  @media ${({ theme }: PalletteProps) => theme.breakpoints.tabletL} {
    padding: 0;
  }
`;

export const Title = styled.h2`
  font-size: 1.875rem;
  font-weight: 900;
  line-height: 36.31px;
  color: ${({ theme }: PalletteProps) => theme.gray2};
`;

export const ViewAllButton = styled.button`
  color: ${({ theme }: PalletteProps) => theme.primaryColor};
  border: solid 1px ${({ theme }: PalletteProps) => theme.primaryColor};
  background-color: transparent;
  font-size: 0.525rem;
  font-weight: 900;
  word-spacing: -1px;
  line-height: 12px;
  padding: 0;
  border-radius: 10px;
  margin-left: 0;
  min-width: 60px;
  height: 30px;
  display: block;
  text-transform: uppercase;
  &:hover {
    cursor: pointer;
  }
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    font-size: 0.75rem;
    font-weight: 900;
    line-height: 14.52px;
    padding: 0px 16px;
    margin-left: 30px;
    height: 34px;
    min-width: 100px;
  }
`;
