import {
  Bottom,
  Card,
  CardDots,
  CartTitle,
  DeleteButton,
  DeleteIcon,
  Item,
  Name,
  StarButton,
  StarIcon,
  Top,
} from './PaymentMethodItem.styled';
import {
  ButtonsWrapper,
  CancelButton,
  ModalCloseButton,
  ModalCloseIcon,
  ModalContent,
  ModalExclamationIcon,
  ModalWrapper,
  Title,
} from '../../shared/DeleteConfirmationModal/DeleteConfirmationModal.styled';
import { Description, Wrapper } from '../../components/ProductDetail/ProductDetail.styled';
import { deletePaymentMethod, setPrimary } from '../../store/paymentMethods/paymentMethodsSlice';
import { faExclamationCircle, faStar, faTimes, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { removePaymentMethod, setDefaultMethod } from '../../api/paymentMethods';

import { Button } from '../../shared';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Image from 'next/image';
import type { PaymentMethodsType } from '../../types/paymentMethods';
import { faStar as faStarEmpty } from '@fortawesome/free-regular-svg-icons';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { useTranslation } from 'next-i18next';

interface Props extends PaymentMethodsType {
  condensed: boolean;
}

export const PaymentMethodItem = (props: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const icon = props.primary ? faStar : faStarEmpty as IconProp;

  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);

  const handleConfirmModal = async () => {
    setConfirmDeleteModal(!confirmDeleteModal);
  };

  const handleDelete = async () => {
    try {
      await toast.promise(
        removePaymentMethod({
          id: props.id,
        }),
        {
          pending: {
            render() {
              return `${t('payment.delete.toastify.pending')}`;
            },
            icon: '⚠️',
          },
          success: {
            render() {
              return `${t('payment.delete.toastify.success')}`;
            },
            icon: '✅',
          },
          error: {
            render({data}: any) {
              if (data.response.data.status > 400) {
                return `${t('payment.delete.toastify.error')}`;
              } else
              return `${data.response.data.detail}`;
            },
            icon: '❗',
          }
        }
      );
      await dispatch(deletePaymentMethod({ id: props.id }));
    } catch (error) {
      console.error(error);
    }
  };

  const handlePrimary = async () => {
    try {
      await toast.promise(
        setDefaultMethod({
          id: props.id,
        }),
        {
          pending: {
            render() {
              return `${t('payment.setPrimary.toastify.pending')}`;
            },
            icon: '⚠️',
          },
          success: {
            render() {
              return `${props.brand} ${props.last4} ${t('payment.setPrimary.toastify.success')}`;
            },
            icon: '✅',
          },
          error: {
            render({data}: any) {
              if (data.response.data.status > 400) {
                return `${t('payment.setPrimary.toastify.error')}`;
              } else
              return `${data.response.data.detail}`;
            },
            icon: '❗',
          }
        }
      );
      await dispatch(setPrimary({ id: props.id }));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Item condensed={props.condensed}>
      <Top>
        <StarButton onClick={handlePrimary}>
          <StarIcon icon={icon} width={23} primary={props.primary} />
        </StarButton>
        <Name>{`${props.brand} ${props.last4}`}</Name>
      </Top>
      <CartTitle>
        {t('payment.cardNumber.title')}
      </CartTitle>
      <Card>
        <CardDots>
          •••• •••••
        </CardDots>
        {props.last4}
      </Card>
      <Bottom>
        <Image
          src={props.brandImage}
          alt={props.brandImage}
          width={30}
          height={23}
          objectFit="contain"
        />
        <DeleteButton onClick={handleConfirmModal}>
          <DeleteIcon icon={faTrashCan} width={14} />
        </DeleteButton>
        {confirmDeleteModal ? (
          <ModalWrapper>
            <ModalContent>
              <ModalCloseButton onClick={handleConfirmModal}>
                <ModalCloseIcon icon={faTimes} />
              </ModalCloseButton>
              <Wrapper>
                <ModalExclamationIcon icon={faExclamationCircle} />
                <Title>{t('payment.deleteModal.title')}</Title>
                <Description>{t('payment.deleteModal.description')}</Description>
                <ButtonsWrapper>
                  <Button
                    title={t('payment.deleteModal.cta.delete')}
                    onClick={handleDelete}
                  />
                  <CancelButton
                    title={t('payment.deleteModal.cta.cancel')}
                    type="reset"
                    onClick={() => setConfirmDeleteModal(false)}
                  />
                </ButtonsWrapper>
              </Wrapper>
            </ModalContent>
          </ModalWrapper>
        ) : null}
      </Bottom>
    </Item>
  );
};
