import { OrderDisplayValueTag } from '../types/orders';

export const getOrderDisplayColor = (displayStatus: OrderDisplayValueTag) => {
  switch (displayStatus) {
    case true:
      return '#29bf12';
    case false:
      return '#f21b3f';
    default:
      return '#ff9914';
  }
};
