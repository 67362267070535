import { Button, Input } from '../../../shared';
import React, { FormEvent, useEffect, useState } from 'react';
import { addPromoCode, removePromoCode } from '../../../api/promoCodes';
import { useDispatch, useSelector } from 'react-redux';

import { Wrapper } from './PromoCode.styled';
import { setCart } from '../../../store/cart/cartSlice';
import type { state } from '../../../types/store/state';
import { toast } from 'react-toastify';
import { useTranslation } from 'next-i18next';

export const PromoCode = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [promoCode, setPromoCode] = useState('');
  const [isPromoCodeApplied, setIsPromoCodeApplied] = useState(false);

  const cart = useSelector(({ cart }: state) => cart);

  useEffect(() => {
    setIsPromoCodeApplied(
      cart.promotionalCode !== null &&
      (cart.promotionalCode.status as unknown as number) === 1
    );
    setPromoCode(cart.promotionalCode ? cart.promotionalCode.code : '');
  }, [cart]);

  const handlePromoCodeAction = async (
    action: 'add' | 'remove',
    event: FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    try {
      const response =
        action === 'add'
          ? await toast.promise(
            addPromoCode({ id: cart.reference, params: { code: promoCode } }),
            {
              pending: { render: () => t('checkout.promoCode.toastify.pending'), icon: '⚠️' },
              success: { render: () => t('checkout.promoCode.toastify.apply.success'), icon: '✅' },
            }
          )
          : await toast.promise(
            removePromoCode({ id: cart.reference, params: { code: promoCode } }),
            {
              pending: { render: () => t('checkout.promoCode.toastify.pending'), icon: '⚠️' },
              success: { render: () => t('checkout.promoCode.toastify.remove.success'), icon: '✅' },
            }
          );

      if (action === 'add' && response.promotionalCode.status === 1) {
        setIsPromoCodeApplied(true);
      } else if (action === 'remove' && response.promotionalCode.status !== 1) {
        setPromoCode('');
        setIsPromoCodeApplied(false);
      }

      dispatch(setCart(response));
    } catch (error: any) {
      const errorMessage = t('checkout.promoCode.toastify.error');
      const promotionalCode = error.response?.data?.promotionalCode?.[0];
      const code = error.response?.data?.code?.[0];
      const finalErrorMessage = promotionalCode || code || errorMessage;

      toast.error(finalErrorMessage, { icon: '❗' });
    }
  };

  const handleApplyPromoCode = async (event: FormEvent<HTMLFormElement>) => {
    await handlePromoCodeAction('add', event);
  };

  const handleRemovePromoCode = async (event: FormEvent<HTMLFormElement>) => {
    await handlePromoCodeAction('remove', event);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPromoCode = e.target.value;
    setPromoCode(newPromoCode);
  };

  return (
    <Wrapper onSubmit={isPromoCodeApplied ? handleRemovePromoCode : handleApplyPromoCode}>
      <Input
        id="inputCode"
        label={isPromoCodeApplied
          ? t('checkout.promoCodeAdded.title')
          : t('checkout.promoCodeAdd.title')}
        name="code"
        type="text"
        placeholder={t('checkout.promoCode.placeholder')}
        value={promoCode}
        onChange={handleInputChange}
        disabled={isPromoCodeApplied}
      />
      <Button
        title={isPromoCodeApplied
          ? t('checkout.promoCode.ctaRemove.title')
          : t('checkout.promoCode.ctaApply.title')}
        type="submit"
      />
    </Wrapper>
  );
};
