import {
  ButtonsWrapper,
  CancelButton,
  ModalCloseButton,
  ModalCloseIcon,
  ModalContent,
  ModalExclamationIcon,
  ModalWrapper,
  Title,
} from '../../../shared/DeleteConfirmationModal/DeleteConfirmationModal.styled';
import { DeleteProductCTA, ImageContainer, UnderProductWrapper } from './DeleteProductOnCart.styled';
import {
  Description,
  Wrapper,
} from '../../../components/ProductDetail/ProductDetail.styled';
import { MouseEvent, useState } from 'react';
import { faExclamationCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';

import { Anchor } from '../../../shared/Anchor';
import { Button } from '../../../shared';
import Image from 'next/image';
import type { ProductType } from '../../../types/product';
import { deleteProductCart } from '../../../api/cart';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { setCart } from '../../../store/cart/cartSlice';
import type { state } from '../../../types/store/state';
import { toast } from 'react-toastify';
import { useTranslation } from 'next-i18next';

export const DeleteProductOnCart = (props: ProductType) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const cartReference = useSelector((state: state) => state.cart.reference);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);

  const handleConfirmModal = () => {
    setConfirmDeleteModal(!confirmDeleteModal);
  };

  const handleDelete = async (event: MouseEvent) => {
    let paramsToSend;

    if (props.product.isDish) {
      paramsToSend = {
        orderBranchProduct: props.id,
      };
    } else {
      paramsToSend = {
        storeBranchProduct: props.product.storeBranchProductId,
      };
    }

    try {
      const cart = await toast.promise(
        deleteProductCart({
          id: cartReference,
          params: paramsToSend,
        }),
        {
          pending: {
            render() {
              return `${t('cart.product.delete.toastify.pending')}`;
            },
            icon: '⚠️',
          },
          success: {
            render() {
              return `${t('cart.product.delete.toastify.success')}`;
            },
            icon: '✅',
          },
          error: {
            render({data}: any) {
              if (data.response.status > 400) {
                return `${t('cart.product.delete.toastify.error')}`;
              }
             return `${data.message}`;
            },
            icon: '❗',
          }
        }
      );
      await dispatch(setCart(cart));
    } catch (error: any) {
      throw new Error(error);
    }
  };

  return (
    <UnderProductWrapper>
      <DeleteProductCTA>
        <Anchor
          title={t('cart.shared.action.deleteProduct')}
          onClick={handleConfirmModal}
          icon={faTrashAlt}
          size="small"
          variation="secondary"
        />
      </DeleteProductCTA>
      {confirmDeleteModal ? (
        <ModalWrapper>
          <ModalContent>
            <ModalCloseButton onClick={handleConfirmModal}>
              <ModalCloseIcon icon={faTimes} />
            </ModalCloseButton>
            <Wrapper>
              <ModalExclamationIcon icon={faExclamationCircle} />
              <Title>{t('addresses.deleteModal.title')}</Title>
              <Description>
                {t('cart.product.deleteModal.description')}
                <ImageContainer>
                  <Image
                    src={props.product.images[0].image}
                    alt={props.product.name}
                    layout="fill"
                    objectFit="contain"
                  />
                </ImageContainer>
                <span>{props.product.name}</span>
              </Description>
              <ButtonsWrapper>
                <Button
                  title={t('cart.product.deleteModal.cta.confirm')}
                  onClick={handleDelete}
                />
                <CancelButton
                  title={t('cart.product.deleteModal.cta.cancel')}
                  type="reset"
                  onClick={() => setConfirmDeleteModal(false)}
                />
              </ButtonsWrapper>
            </Wrapper>
          </ModalContent>
        </ModalWrapper>
      ) : null}
    </UnderProductWrapper>
  );
};