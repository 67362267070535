import { PalletteProps } from '../../types/theme/pallette';
import { rgba } from 'polished';
import styled from 'styled-components';

export const CategoriesWrapper = styled.div`
  position: relative;
  display: none;
  @media ${({ theme }: PalletteProps) => theme.breakpoints.tablet} {
    display: block;
  }
`;

export const CategoriesButton = styled.button`
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  color: ${({ theme }: PalletteProps) => theme.primaryColor};
  font-size: 1.125rem;
  margin-right: 8px;
  .faIcon {
    margin-left: 8px;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const Menu = styled.ul`
  position: absolute;
  left: -50%;
  top: 1.75rem;
  margin: 0;
  padding: 0;
  border: solid 1px ${({ theme }: PalletteProps) => theme.gray5};
  background: white;
  border-radius: 5px;
  list-style: none;
  width: 242px;
  max-height: 250px;
  overflow: scroll;
  z-index: 1;
`;

export const MenuItem = styled.a`
  display: block;
  padding: 12px 17px;
  font-size: 1rem;
  transition: ease all 200ms;
  &:hover {
    background: ${({ theme }: PalletteProps) => rgba(theme.primaryColor, 0.1)};
  }
`;
