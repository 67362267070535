import { ReactNode } from 'react';
import { Row } from './ContainedRow.styled';

interface props {
  children: ReactNode;
}

export const ContainedRow = ({ children }: props) => {
  return <Row>{children}</Row>;
};
