import { AttrWrapper, Price, Title, Top } from '../CartProduct/CartProduct.styled';

import { AnchorButton } from '../../../shared/Anchor/Anchor.styled';
import { BackdropWrapper } from '../../../base/BackdropOverlay/BackdropOverlay.styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PalletteProps } from '../../../types/theme/pallette';
import { QuantityWrapper } from '../../../shared/QuantityButton/QuantityButton.styled';
import styled from 'styled-components';

export const StyledBackdropWrapper = styled(BackdropWrapper)`
  top: 100%;
`;

export const CartCloseButton = styled.button`
  position: absolute;
  top: 17px;
  right: 19px;
  font-size: 36px;
  text-decoration: none;
  background: transparent;
  border: none;
  outline: 0;
  cursor: pointer;
  padding: 8px 12px;
  background-color: ${({ theme }: PalletteProps) => theme.gray5};
  color: ${({ theme }: PalletteProps) => theme.darkGray};
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const Wrapper = styled.aside`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  scroll-behavior: smooth;
  top: 100%;
  right: 0;
  height: calc(100vh - 80px);
  background-color: ${({ theme }: PalletteProps) => theme.white};
  box-shadow: 0px 0px 50px rgba(200, 200, 200, 0.2);
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.2), 0 20px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 10px;
  width: 375px;
  z-index: 1000;
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    padding: 20px;
    width: 474px;
  }
`;

export const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 67px 20px 27px 20px;
  border-bottom: solid 2px ${({ theme }: PalletteProps) => theme.lightGray};
`;

export const HeadTitle = styled.h4`
  font-size: 1.5rem;
  font-weight: 700;
  color: ${({ theme }: PalletteProps) => theme.black};
`;

export const SubtotalWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const SubtotalText = styled.p`
  font-size: .875rem;
  font-style: italic;
  color: ${({ theme }: PalletteProps) => theme.gray3};
  margin-right: 9px;
`;

export const SubtotalAmount = styled.p`
  font-size: 1.5rem;
  font-weight: 700;
  color: ${({ theme }: PalletteProps) => theme.primaryColor};
`;

export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  padding: 27px 5px 0 5px;
  border-top: solid 2px ${({ theme }: PalletteProps) => theme.lightGray};
  ${AnchorButton} {
    width: 345px;
  }
  .keepBuyingButton {
      margin-top: 20px;
      ${AnchorButton} {
        width: 280px;
        text-transform: capitalize;
        font-weight: 700;
      }
    }
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    ${AnchorButton} {
      width: 374px;
    }
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  margin-top: 13px;
  margin-bottom: 14px;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  font-size: 1rem;
  border: none;
  background-color: transparent;
  text-decoration: underline;
  width: fit-content;
  color: ${({ theme }: PalletteProps) => theme.primaryColor};
  gap: 4px;
  &:hover {
    cursor: pointer;
  }
`;

export const ProductsWrapper = styled.div`
  flex: 1;
  height: 400px;
  overflow-y: scroll;
  ${Title} {
    font-size: 0.875rem;
    font-weight: 400;
  }
  ${Top} {
    gap: 8px;
    ${QuantityWrapper} {
      width: 85px;
      border-radius: 5px;
      padding: 3px 2px;
    }
  }
  ${Price} {
    font-size: 0.875rem;
    line-height: 1.125;
    font-weight: 700;
  }
  ${AttrWrapper} {
    flex-direction: column;
    gap: 4px;
  }
`;

export const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const EmptyIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }: PalletteProps) => theme.primaryColor};
  margin-bottom: 40px;
`;

export const EmptyTitle = styled.h4`
  font-size: 1.5rem;
  font-weight: 700;
  color: ${({ theme }: PalletteProps) => theme.gray2};
  margin-bottom: 35px;
  text-align: center;
`;

export const EmptySubtitle = styled.p`
  font-size: 0.875rem;
  font-weight: 300;
  color: ${({ theme }: PalletteProps) => theme.gray2};
  text-align: center;
`;
