import { PalletteProps } from '../../types/theme/pallette';
import styled from 'styled-components';

export const ProdRow = styled.div`
  box-sizing: border-box;
  margin: 0 auto !important;
  max-width: 1280px;
  padding: 0 0.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    box-sizing: border-box;
    margin: 0 auto !important;
    max-width: 1310px;
    padding: 0 0.5rem;
    position: relative;
  }
`;
