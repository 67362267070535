import {
  ArrowIcon,
  ArrowSquare,
  PageNumBox,
  Wrapper,
} from './Paginator.styled';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

import Link from 'next/link';
import { PaginationLogic } from './PaginationLogic';

interface Props {
  prev: string;
  next: string;
  prevDisabled: boolean;
  nextDisabled: boolean;
  currentPage: number;
  totalPages: number;
  isCategory: boolean;
  categoryNumber: string | number;
  maxLength: number;
}

export const Paginator = (props: Props) => {
  const filterPageData = () => {
    Math.ceil(props.totalPages / 2);
  };

  const pagesNums: Array<number> = PaginationLogic(
    props.currentPage,
    props.totalPages,
    props.maxLength
  );

  return (
    <Wrapper>
      {props.prevDisabled ? (
        <ArrowSquare disabled>
          <ArrowIcon icon={faAngleLeft} width={9} disabled />
        </ArrowSquare>
      ) : (
        <Link href={props.prev} passHref>
          <ArrowSquare disabled={false}>
            <ArrowIcon icon={faAngleLeft} width={9} disabled={false} />
          </ArrowSquare>
        </Link>
      )}

      {pagesNums.map((pageNum, idx) => {
        return (
          <>
            {props.isCategory ? (
              <Link
                href={`/category/${props.categoryNumber}/?page=${pageNum}`}
                passHref
              >
                <PageNumBox
                  key={idx}
                  active={pageNum === props.currentPage}
                  dotsPageNum={isNaN(pageNum) ? true : false}
                >
                  <a onClick={() => filterPageData()}>
                    {!isNaN(pageNum) ? pageNum : '...'}
                  </a>
                </PageNumBox>
              </Link>
            ) : (
              <Link href={`products/?page=${pageNum}`} passHref>
                <PageNumBox
                  key={idx}
                  active={pageNum === props.currentPage}
                  dotsPageNum={isNaN(pageNum) ? true : false}
                >
                  <a onClick={() => filterPageData()}>
                    {!isNaN(pageNum) ? pageNum : '...'}
                  </a>
                </PageNumBox>
              </Link>
            )}
          </>
        );
      })}

      {props.nextDisabled ? (
        <ArrowSquare disabled>
          <ArrowIcon icon={faAngleRight} width={9} disabled />
        </ArrowSquare>
      ) : (
        <Link href={props.next} passHref>
          <ArrowSquare disabled={false}>
            <ArrowIcon icon={faAngleRight} width={9} disabled={false} />
          </ArrowSquare>
        </Link>
      )}
    </Wrapper>
  );
};

Paginator.defaultProps = {
  prev: '#',
  next: '#',
  prevDisabled: false,
  nextDisabled: false,
  currentPage: 1,
  totalPages: 0,
  isCategory: false,
  categoryNumber: 0,
  maxLength: 0,
};
