import { Anchor } from '../../../shared/Anchor';
import { Wrapper } from './AuthRow.styled';
import { useTranslation } from 'next-i18next';

type Conext = 'login' | 'register';

interface Props {
  context: Conext;
};

export const AuthRow = ({ context }: Props) => {
  const { t } = useTranslation();
  const leftButtonType = context === 'login' ? 'primary' : 'alternative';
  const rightButtonType = context === 'register' ? 'primary' : 'alternative';

  return (
    <Wrapper>
      <Anchor title={t('login.action.login.form')} url='/login/' variation={leftButtonType} />
      <Anchor title={t('register.action.register.form')} url='/register/' variation={rightButtonType} />
    </Wrapper>
  );
};
