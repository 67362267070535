import { PalletteProps } from '../../../types/theme/pallette';
import styled from 'styled-components';

interface CustomElement extends PalletteProps {
  selected: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }: PalletteProps) => theme.white};
  padding: 48px 70px;
  border-radius: 5px;
  box-shadow: 0px 0px 50px rgba(200,200,200,0.2);
  gap: 10px;
`;

export const Title = styled.h3`
  color: ${({ theme }: PalletteProps) => theme.gray2};
  font-size: 1.125rem;
  font-weight: 600;
  flex: 1;
`;

export const TipsWrapper = styled.div`
  width: 200px;
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  gap: 10px;
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    grid-template-columns: repeat(5, 1fr);
  }
  @media ${({ theme }: PalletteProps) => theme.breakpoints.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const TipsElement = styled.button`
  border-radius: 8px;
  border: solid 2px ${({ theme, selected }: CustomElement) => selected ? theme.primaryColor : 'transparent'};
  padding: 10px 20px;
  &:hover {
    cursor: pointer;
  }
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    padding: 20px;
  }
`;

export const TotalText = styled.p`
  font-size: 1rem;
  color: ${({ theme }: PalletteProps) => theme.gray2};
  font-weight: 500;
`;
