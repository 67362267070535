import { FormEvent, MouseEvent, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'next-i18next';
import { Button, Input, Textarea } from '../../../shared';
import { ErrorText, FullRow, HalfRow } from '../Forms.styled';
import { ValidationCreateAddressType, defaultCreateAddressState } from '../types/userValidations';
import { createAddress, editAddress, getLocationFromZipcode, searchAddress } from '../../../api/addresses';
import { addAddresses, editedAddresses } from '../../../store/addresses/addressesSlice';
import { debouncedSearch } from '../../../utils/debounceSearch';
import { AddAddressTitle, AddressTitle, AddressWrapper, ButtonWrapper, AddressList } from './AddressCreateForm.styled';

type AddressCreateFormProps = {
  addressToEdit?: any;
  shouldEdit?: boolean;
};

export const AddressCreateForm = (props: AddressCreateFormProps = { shouldEdit: false }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState<ValidationCreateAddressType>(
    defaultCreateAddressState
  );

  const [openAddressForm, setOpenAddressForm] = useState(props.shouldEdit);
  const [addressList, setAddressList] = useState([]);
  const [addressLocationValue, setAddressLocationValue] = useState('');
  const [zipcodeLocationValue, setZipcodeLocationValue] = useState('');
  const [stateLocationValue, setStateLocationValue] = useState('');
  const [cityLocationValue, setCityLocationValue] = useState('');

  useEffect(() => {
    if (props.shouldEdit) {
      setAddressLocationValue(props.addressToEdit.address.address);
      setZipcodeLocationValue(props.addressToEdit.address.zipcode);
      setStateLocationValue(props.addressToEdit.address.state);
      setCityLocationValue(props.addressToEdit.address.city);
    }
    return () => {
      setAddressLocationValue('');
      setZipcodeLocationValue('');
      setStateLocationValue('');
      setCityLocationValue('');
    };
  }, []);

  const handleInputZipcodeChange = (event: any) => {
    const valueZipcode = event.target.value;
    setZipcodeLocationValue(valueZipcode);
  };

  const handleInputBlur = () => retreiveLocationFromZipCode();

  const handleAddAddress = (event: any) => {
    event.preventDefault();
    setOpenAddressForm(!openAddressForm);
  };

  const searchLocation = async (event: any) => {
    event.preventDefault();
    setAddressLocationValue(event.target.value);
    debouncedSearch(event.target.value, handleSearch);
  };

  const handleSearch = async (term: string) => {
    if (term.length >= 2) {
      try {
        const addresses = await searchAddress({
          query: term,
        });
        setAddressList(addresses);
      } catch (error: any) {
        throw new Error(error);
      }
    }
  };

  const setSearchSelection = (event: MouseEvent, address: any) => {
    event.preventDefault();
    setAddressLocationValue(address.formattedAddress);
    setZipcodeLocationValue(address.zipcode);
    setStateLocationValue(address.state);
    setCityLocationValue(address.city ? address.city : address.state);
    setAddressList([]);
  };

  const retreiveLocationFromZipCode = async () => {
    try {
      const zipAddress = await getLocationFromZipcode({
        params: {
          zipcode: zipcodeLocationValue,
        },
      });
      setAddressLocationValue(zipAddress.formattedAddress);
      setZipcodeLocationValue(zipAddress.zipcode);
      setStateLocationValue(zipAddress.state);
      setCityLocationValue(
        zipAddress.city ? zipAddress.city : zipAddress.state
      );
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setErrors(defaultCreateAddressState);

    const target = event.target as any;

    try {
      let paramsToSend: any = {
        name: target.name.value,
        address: target.address.value,
        zipcode: target.zipcode.value,
        state: target.state.value,
        city: target.city.value,
        instructions: target.description.value,
        description: target.description.value,
      };

      if (props.shouldEdit) {
        paramsToSend = {
          addressId: props.addressToEdit.id,
          ...paramsToSend,
        };
      }

      const addressesCreate = await toast.promise(!props.shouldEdit ? createAddress({ params: paramsToSend }) : editAddress({ params: paramsToSend}),
        {
          pending: {
            render() {
              return `${t('addresses.create.toastify.pending')}`;
            },
            icon: '⚠️',
          },
          success: {
            render() {
              setAddressLocationValue('');
              setZipcodeLocationValue('');
              setStateLocationValue('');
              setCityLocationValue('');
              return `${t('addresses.create.toastify.success')}`;
            },
            icon: '✅',
          },
          error: {
            render() {
              return `${t('addresses.create.toastify.error')}`;
            },
            icon: '❗',
          },
        }
      );

      if (props.shouldEdit) {
        await dispatch(editedAddresses(addressesCreate));;
      } else {
        await dispatch(addAddresses(addressesCreate));
      }
      await setOpenAddressForm(!openAddressForm);
    } catch (error: any) {
      if (error.response.status === 400) {
        setErrors({
          ...errors,
          ...error.response.data,
        });
        return;
      }
      throw new Error(error);
    }
  };

  return (
    <div>
      {!props.shouldEdit ? (
        <AddAddressTitle onClick={handleAddAddress}>
          {openAddressForm ? '-' : '+'} {t('addresses.action.add.address')}
        </AddAddressTitle>
      ) : null}
      {openAddressForm ? (
        <>
          <form onSubmit={onSubmit}>
            <AddressWrapper>
              <AddressTitle>{t(`addresses.${props.shouldEdit ? 'edit' : 'add'}.address.title`)}</AddressTitle>
              <FullRow>
                <Input
                  label={t('addresses.street.address.title')}
                  name="address"
                  value={addressLocationValue}
                  onChange={searchLocation}
                  placeholder={t('addresses.street.address.placeholder')}
                  error={errors.address}
                  required
                />
                {addressList.length ? (
                  <AddressList onMouseLeave={() => setAddressList([])}>
                    {addressList.map((addr: any) => (
                      <li key={addr.placeId} onClick={(event) => setSearchSelection(event, addr)}>{addr.formattedAddress}</li>
                    ))}
                  </AddressList>
                ) : null}
              </FullRow>
              <FullRow>
                <Input
                  label={t('addresses.zipcode.title')}
                  name="zipcode"
                  defaultValue={zipcodeLocationValue}
                  placeholder={t('addresses.zipcode.placeholder')}
                  error={errors.zipcode}
                  onChange={handleInputZipcodeChange}
                  onBlur={handleInputBlur}
                  required
                />
              </FullRow>
              <FullRow>
                <HalfRow>
                  <Input
                    label={t('addresses.state.title')}
                    name="state"
                    defaultValue={stateLocationValue}
                    placeholder={t('addresses.state.placeholder')}
                    error={errors.state}
                    required
                  />
                  <Input
                    label={t('addresses.city.title')}
                    name="city"
                    defaultValue={cityLocationValue}
                    placeholder={t('addresses.city.placeholder')}
                    error={errors.city}
                    required
                  />
                </HalfRow>
              </FullRow>
              <FullRow>
                <Textarea
                  label={t('addresses.delivInstructions.title')}
                  name="description"
                  placeholder={t('addresses.delivInstructions.placeholder')}
                  error={errors.instructions}
                  defaultValue={props.shouldEdit ? props.addressToEdit.address.description : ''}
                  required
                />
              </FullRow>
              <FullRow>
                <Input
                  label={t('addresses.tag.title')}
                  name="name"
                  placeholder={t('addresses.tag.placeholder')}
                  defaultValue={props.shouldEdit ? props.addressToEdit.name : ''}
                  error={errors.name}
                  required
                />
              </FullRow>
            </AddressWrapper>
            {errors.nonFieldErrors.length ? (
              <ErrorText>{errors.nonFieldErrors[0]}</ErrorText>
            ) : null}
            <ButtonWrapper>
              <Button
                title={t('shared.action.cancel')}
                id="cancelButton"
                onClick={handleAddAddress}
              />
              <Button title={t('shared.action.save')} type="submit" />
            </ButtonWrapper>
          </form>
        </>
      ) : null}
    </div>
  );
};
