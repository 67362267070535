import { PalletteProps } from '../../types/theme/pallette';
import styled from 'styled-components';

export const Label = styled.label`
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 24px;
  font-size: 1rem;
  user-select: none;
  &:hover {
    cursor: pointer;
  }
  a {
    text-decoration: underline;
  }
  input:checked ~ span {
    background-color: ${({ theme }: PalletteProps) => theme.primaryColor};
  }
  input:checked ~ span {
    &:after {
      display: block;
    }
  }
`;
export const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;
export const Span = styled.span`
  position: absolute;
  top: 2px;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #EEE;
  &:after {
    content: "";
    position: absolute;
    display: none;
    left: 4px;
    top: 0;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;
