import { Button } from '../../../shared/Button';
import { ButtonElement } from '../../../shared/Button/Button.styled';
import { PalletteProps } from '../../../types/theme/pallette';
import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 970px;
  width: 100%;
  margin: 31px 0 26px 0;
  padding: 31px 44px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 0px 50px rgba(200, 200, 200, 0.2);
`;

export const Subtitle = styled.h3`
  font-size: 1.125rem;
  font-weight: 600;
  color: ${({ theme }: PalletteProps) => theme.gray2};
  margin-bottom: 16px;
`;

export const Row = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  @media ${({ theme }: PalletteProps) => theme.breakpoints.tablet} {
    display: grid;
    grid-template-columns: 1.5fr 3fr;
    align-items: center;
    grid-template-rows: 60px;
    grid-gap: 16px;
  }
`;

export const RowTitle = styled.p`
  font-size: 1rem;
  font-weight: 600;
  color: black;
`;

export const ButtonsWrapper = styled.div`
  max-width: 970px;
  margin: 0 0 26px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  ${ButtonElement} {
    width: 113px;
    height: 41px;
    text-transform: capitalize;
    font-family: 'Inter';
    font-weight: 700;
    font-size: 1rem;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const CancelButton = styled(Button)`
  background-color: ${({ theme }: PalletteProps) => theme.gray4};
  color: ${({ theme }: PalletteProps) => theme.gray2};
  margin-right: 10px;
  &:hover {
    background: ${({ theme }: PalletteProps) => theme.gray4};
  }
`;
