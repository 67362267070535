import {
  CartItems,
  Content,
  Subtitle,
  Subtotal,
  SubtotalText,
  SubtotalWrapper,
  Title,
  Top,
  TopRow,
} from './CartWrapper.styled';
import { Fragment, MouseEvent } from 'react';
import { faCartPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';

import { Anchor } from '../../../shared/Anchor';
import { CartProduct } from '../CartProduct';
import { FlowChekoutAndCartNotification } from '../../Notification';
import type { ProductType } from '../../../types/product';
import { cleanCart } from '../../../api/cart';
import { setCart } from '../../../store/cart/cartSlice';
import type { state } from '../../../types/store/state';
import { toast } from 'react-toastify';
import { useTranslation } from 'next-i18next';

export const CartWrapper = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const cart = useSelector(({ cart }: state) => cart);

  const handleCleanCart = async (event: MouseEvent) => {
    event.preventDefault();
    try {
      const result = await toast.promise(
        cleanCart({
          id: cart.reference,
        }),
        {
          pending: {
            render() {
              return `${t('cart.fullView.cleanCart.toastify.pending')}`;
            },
            icon: '⚠️',
          },
          success: {
            render() {
              return `${t('cart.fullView.cleanCart.toastify.success')}`;
            },
            icon: '✅',
          },
          error: {
            render({ data }: any) {
              if (data.response.status > 400) {
                return `${t('cart.fullView.cleanCart.toastify.error')}`;
              }
              return `${data.message}`;
            },
            icon: '❗',
          },
        }
      );
      await dispatch(setCart(result));
    } catch (error: any) {
      throw new Error(error);
    }
  };

  return (
    <div>
      <Top>
        <TopRow>
          <Title>{t('cart.fullView.title')}</Title>
          {cart.productsCount > 0 ? (
            <Anchor
              title={t('cart.shared.action.clean')}
              onClick={handleCleanCart}
              icon={faTrashAlt}
              size="small"
              variation="secondary"
            />
          ) : null}
        </TopRow>
        <Subtitle>
          {t('cart.fullView.title.description.thereAre')}
          <span>{cart.productsCount}</span>
          {t('cart.fullView.title.description.onCart')}
        </Subtitle>
      </Top>
      <Content>
        {cart.productsCount > 0 ? (
          <CartItems>
            {cart.branchStores[0].products.map((product: ProductType) => (
              <Fragment key={product.id}>
                <CartProduct {...product} />
              </Fragment>
            ))}
          </CartItems>
        ) : (
          <div />
        )}
        <div className="bottomContent">
          <SubtotalWrapper>
            <SubtotalText>{t('cart.shared.subtotal')}</SubtotalText>
            <Subtotal>
              {cart.orderCost?.currency.symbol}
              {cart.orderCost?.productsCost.toFixed(2)}
            </Subtotal>
          </SubtotalWrapper>
          <FlowChekoutAndCartNotification />
          <Anchor title={t('cart.shared.action.toCheckout')} url="/checkout/" />
          <div className="keepBuyingButton">
            <Anchor
              title={t('cart.shared.action.keepBuying')}
              url="/"
              icon={faCartPlus}
              size="small"
              variation="secondary"
            />
          </div>
        </div>
      </Content>
    </div>
  );
};
