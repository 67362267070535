import { Text, Wrapper } from './PhoneVerification.styled';
import { useEffect, useState } from 'react';

import { Anchor } from '../../shared/Anchor';
import { ContainedProductRow } from '../../layouts/ContainedRow';
import { getToken } from '../../services/auth';
import type { state } from '../../types/store/state';
import { useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';

export const PhoneVerification = () => {
  const { t } = useTranslation();
  const [token, setToken] = useState('');

  const phoneNumberVerified = useSelector(
    ({ user }: state) => user.phoneNumberVerified
  );
  const phoneNumber = useSelector(({ user }: state) => user.phoneNumber);
  const userEmail = useSelector(({ user }: state) => user.email);

  useEffect(() => {
    setToken(getToken());
  }, []);

  if (
    token === 'guest-user' ||
    phoneNumberVerified ||
    phoneNumber.length ||
    !userEmail.length
  ) {
    return null;
  }

  return (
    <ContainedProductRow>
      <Wrapper>
        <Text>{t('phone.insertPhone.title')}</Text>
        <Anchor
          title={t('phone.action.addPhone.title')}
          url="/account/verify-number/"
          size='small'
        />
      </Wrapper>
    </ContainedProductRow>
  );
};
