import { Anchor, Button } from '../../../shared';
import {
  Content,
  Main,
  MainContent,
  Sidebar,
  Title,
  Top,
  TopRow,
} from './CheckoutWrapper.styled';
import {
  ModalCloseButton,
  ModalCloseIcon,
  ModalContent,
  ModalWrapper,
} from '../../../shared/ConfirmationLoginModal/ConfirmationLoginModal.styled';
import { calculateDelivery, cartCheckout } from '../../../api/cart';
import { cleanCart, updateDeliveryCost } from '../../../store/cart/cartSlice';
import { faArrowLeft, faCartPlus } from '@fortawesome/free-solid-svg-icons';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { AuthRow } from '../../../components/Forms/Auth/AuthRow';
import { ButtonsWrapper } from '../../Account/AccountRow.styled';
import { CancelButton } from '../../../shared/DeleteConfirmationModal/DeleteConfirmationModal.styled';
import { FlowChekoutAndCartNotification } from '../../Notification';
import { OrderCost } from '../OrderCost';
import type { OrderDeliveryType } from '../../../types/orders';
import { OrderProducts } from '../OrderProducts';
import { OrderType } from '../OrderType';
import { PaymentMethodsWrapper } from '../../PaymentMethods';
import { PromoCode } from '../PromoCode';
import { RegisterForm } from '../../../components/Forms/Auth/RegisterForm';
import { Step } from '../Step';
import { Tips } from '../Tips';
import { Wallet } from '../Wallet';
import { Wrapper } from '../../../components/ProductDetail/ProductDetail.styled';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { getTaxes } from '../../../utils/getTaxes';
import { getToken } from '../../../services/auth';
import { retreivePaymentMethods } from '../../../api/paymentMethods';
import { retreiveUserAddresses } from '../../../api/addresses';
import { setAddresses } from '../../../store/addresses/addressesSlice';
import { setPaymentMethods } from '../../../store/paymentMethods/paymentMethodsSlice';
import type { state } from '../../../types/store/state';
import { toast } from 'react-toastify';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

export const CheckoutWrapper = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const router = useRouter();

  const { hasPickup, hasDelivery, isClosed } = useSelector(({ branchStore }: state) => branchStore);
  const cart = useSelector(({ cart }: state) => cart);
  const primaryAddress = useSelector(({ addresses }: state) =>
    addresses.results.filter((address) => address.primary), shallowEqual
  );
  const primaryPaymentMethod = useSelector(({ paymentMethods }: state) =>
    paymentMethods.results.filter((method) => method.primary)
  );

  let defaultOrderType: OrderDeliveryType = hasPickup && hasDelivery ? 'delivery' : 'pickup';

  if (hasPickup && !hasDelivery) {
    defaultOrderType = 'pickup';
  } else if (!hasPickup && hasDelivery) {
    defaultOrderType = 'delivery';
  }

  const [step, setStep] = useState<'type' | 'date' | 'payment' | 'listProducts'>('type');
  const [orderType, setOrderType] = useState<OrderDeliveryType>(defaultOrderType);
  const [calculatedTip, setCalculatedTip] = useState<number>(0);
  const [confirmLoginModal, setConfirmLoginModal] = useState(false);
  const [locationsModal, setLocationsModal] = useState(false);
  const [orderRemotelyResponse, setOrderRemotelyResponse] = useState('');
  const [errServerResponse, setErrServerResponse] = useState('');
  const [useWallet, setUseWallet] = useState(false);
  const [walletAmmount, setWalletAmmount] = useState(0);

  const handleConfirmModal = () => {
    setConfirmLoginModal(!confirmLoginModal);
  };

  const handleConfirmLocationsModalClose = () => {
    setLocationsModal(false);
  };

  useEffect(() => {
    if (getToken() === 'guest-user') {
      setConfirmLoginModal(true);
    } else {
      retreivePaymentMethods()
        .then((res) => dispatch(setPaymentMethods(res)))
        .catch((error) => {
          throw new Error(error);
        });
      retreiveUserAddresses()
        .then((res) => dispatch(setAddresses(res)))
        .catch((error) => {
          throw new Error(error);
        });
    }
  }, []);

  useEffect(() => {
    if (primaryAddress.length && orderType === 'delivery' && cart.reference) {
      handleCheckDeliveryCost();
    } else {
      dispatch(updateDeliveryCost(0));
    }
  }, [orderType, primaryAddress, cart.reference]);

  const handleNextStep = () => {
    if (step === 'type') {
      setStep('listProducts');
    } else if (step === 'listProducts') {
      setStep('payment');
    }
  };

  const handleCheckDeliveryCost = async () => {
    try {
      const calculatedDeliveryCost = await calculateDelivery({
        id: cart.reference,
      });
      dispatch(updateDeliveryCost(calculatedDeliveryCost.price));
    } catch (error: any) {
      dispatch(updateDeliveryCost(0));
      throw new Error(error);
    }
  };

  const handlePlaceOrder = async (orderRemotely: 0 | 1 = 0) => {
    if (!primaryPaymentMethod.length) {
      toast.error(t('checkout.step.action.continue.toast.noPrimaryPayment'));
      return;
    } else if (hasDelivery && !primaryAddress.length) {
      toast.error(t('checkout.step.action.continue.toast.noPrimaryAddress'));
      return;
    }
    let paramsToSend = {
      tip: calculatedTip.toFixed(2),
      paymentMethodId: primaryPaymentMethod[0].id,
      orderType,
      useWallet: false,
      wallet: false,
    };

    if (useWallet) {
      paramsToSend = {
        ...paramsToSend,
        useWallet: true,
        wallet: true,
      };
    }

    const order = await toast.promise(cartCheckout({ id: cart.reference, params: paramsToSend, query: orderRemotely }), {
      pending: {
        render: () => t('cart.product.checkout.toastify.pending'),
        icon: '⚠️',
      },
      success: {
        render: () => t('cart.product.checkout.toastify.success'),
        icon: '✅',
      },
      error: {
        render: ({ data }: any) => {
          const handleServerErrorResponse = (responseKey: string) => {
            setOrderRemotelyResponse(data.response.data[responseKey]);
            setLocationsModal(!locationsModal);
            return `${data.response.data[responseKey]}`;
          };

          const errorKeyName = Object.keys(data.response.data)[0];

          if (data.response.data.location) {
            return handleServerErrorResponse('location');
          } else if (data.response.data.address) {
            setErrServerResponse(data.response.data.address);
            return handleServerErrorResponse('address');
          } else if (data.response.status >= 400) {
            return `${data.response.data[errorKeyName]}`;
          }
        },
        icon: '❗',
      },
    }
    );
    await dispatch(cleanCart());
    await router.push(`/orders/${order.id}`);
  };

  const deliveryText = primaryAddress.length
    ? `${t('checkout.step.deliveryText.title')} ${primaryAddress[0].address.address}`
    : '';
  const paymentText = primaryPaymentMethod.length
    ? `${t('checkout.step.paymentText.title')} ${primaryPaymentMethod[0].brand} ${primaryPaymentMethod[0].last4}`
    : '';
  const subTitleTotalItems = `${t('checkout.step.orderProducts.subTitle')} ${cart.productsCount}`;

  const generateStepProps = () => ({
    title: hasDelivery && hasPickup
      ? t('checkout.step.deliverySelectOption.title')
      : hasDelivery
        ? t('checkout.step.deliveryDetails.title')
        : t('checkout.step.pickupDetails.title'),
    subtitle: hasDelivery && hasPickup
      ? t('checkout.step.deliveryAndPickupDetails.subTitle')
      : hasDelivery
        ? deliveryText
        : t('checkout.step.pickupDetails.subTitle'),
    open: hasDelivery && hasPickup ? true : step === 'type',
    nextStep: handleNextStep,
  });

  const stepProps = generateStepProps();

  return (
    <Main>
      {confirmLoginModal ? (
        <ModalWrapper>
          <ModalContent>
            <ModalCloseButton onClick={handleConfirmModal}>
              <ModalCloseIcon icon={faTimes} />
            </ModalCloseButton>
            <Wrapper>
              <AuthRow context="register" />
              <RegisterForm />
            </Wrapper>
          </ModalContent>
        </ModalWrapper>
      ) : null}
      <Top>
        <TopRow>
          <Title>{t('checkout.title')}</Title>
          {cart.productsCount < 1 ? null : (
            <Anchor
              title={t('cart.shared.action.toCart')}
              url="/cart/"
              icon={faArrowLeft}
              size="small"
              variation="secondary"
            />
          )}
        </TopRow>
      </Top>
      <Content>
        <MainContent>
          <Step
            orderType={orderType}
            title={stepProps.title}
            subtitle={stepProps.subtitle}
            open={stepProps.open}
            nextStep={stepProps.nextStep}
          >
            <OrderType setOrderType={setOrderType} />
          </Step>
          <Step
            orderType={orderType}
            title={t('checkout.step.orderProducts.title')}
            subtitle={subTitleTotalItems}
            open={step === 'listProducts'}
            nextStep={cart.productsCount < 1 ? undefined : handleNextStep}
          >
            {cart.productsCount < 1 ? (
              <>{t('checkout.step.orderProducts.noItems')}</>
            ) : (
              <OrderProducts />
            )}
          </Step>
          <Step
            orderType={orderType}
            title={t('checkout.step.paymentMethods.title')}
            subtitle={paymentText}
            open={step === 'payment'}
          >
            <PaymentMethodsWrapper condensed={true} />
          </Step>
        </MainContent>
        <Sidebar>
          <OrderCost
            orderCost={cart.orderCost}
            customTip={calculatedTip}
            customTax={cart.orderCost && cart.orderCost?.tax > 0 ? cart.orderCost?.tax : getTaxes(cart.orderCost?.productsCost)}
            orderType={orderType}
            isLarge={false}
            useWallet={useWallet}
            walletAmmount={walletAmmount}
            customTotal
          />
          <Tips
            calculatedTip={calculatedTip}
            setCalculatedTip={setCalculatedTip}
            orderCost={cart.orderCost}
            orderType={orderType}
          />
          <Wallet
            useWallet={useWallet}
            setUseWallet={setUseWallet}
            walletAmmount={walletAmmount}
            setWalletAmmount={setWalletAmmount}
          />
          <PromoCode />
          <FlowChekoutAndCartNotification />
          <Anchor
            disabled={isClosed}
            title={isClosed ? t('checkout.action.storeIsClosed') : t('checkout.action.confirmOrder')}
            onClick={() => handlePlaceOrder()}
          />
          <Anchor
            title={t('cart.shared.action.keepBuying')}
            url="/"
            icon={faCartPlus}
            size="medium"
            variation="secondary"
          />
        </Sidebar>
        {locationsModal ? (
          <ModalWrapper>
            <ModalContent>
              <ModalCloseButton onClick={handleConfirmLocationsModalClose}>
                <ModalCloseIcon icon={faTimes} />
              </ModalCloseButton>
              <Wrapper>
                <div className="h3-locations-msg">
                  <h3>❗{orderRemotelyResponse}</h3>
                </div>
                <div className="h3-locations-msg">
                  <h3>{t('checkout.confirmOrder.locationsModal.continue')}</h3>
                </div>
                {orderRemotelyResponse === errServerResponse
                  ? (
                    <div className="h4-locations-msg">
                      <h4>{t('checkout.confirmOrder.locationsModal.farFromLocation.proceed')}</h4>
                    </div>
                  ) : (
                    <div className="h4-locations-msg">
                      <h4>{t('checkout.confirmOrder.locationsModal.primaryAddressFarFromStore.proceed')}</h4>
                    </div>
                  )}
                <ButtonsWrapper>
                  <Button
                    title={t('checkout.modal.action.confirm')}
                    onClick={() => {
                      handlePlaceOrder(1);
                      handleConfirmLocationsModalClose();
                    }}
                  />
                  <CancelButton
                    title={t('checkout.modal.action.cancel')}
                    onClick={handleConfirmLocationsModalClose}
                  />
                </ButtonsWrapper>
                <br />
              </Wrapper>
            </ModalContent>
          </ModalWrapper>
        ) : null}
      </Content>
    </Main>
  );
};
