import { Footer, Header } from '../base';

import Head from 'next/head';
import { PhoneVerification } from '../components/Notification';
import type { ReactNode } from 'react';
import { imagePath } from '../utils';
import type { imageType } from '../types/ui';

interface Props {
  children: ReactNode;
  title: string;
  description: string;
  twitter: string;
  openGraph: imageType;
  domain: string;
  storeName: string;
  favicon: imageType;
  hideBanner?: boolean;
}

export const BaseLayout = (props: Props) => {
  const { children, title, description, twitter, openGraph, favicon, domain, storeName } = props;

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://${domain}`} />
        <meta property="og:image" content={imagePath(openGraph?.data?.attributes?.url)} />
        <meta property="og:site_name" content={storeName} />
        {/* <meta property="twitter:account_id" content="" /> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content={`@${twitter}`} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:creator" content={`@${twitter}`} />
        <meta name="twitter:image" content={openGraph.data.attributes.url} />
        <meta name="twitter:domain" content={`https://${domain}`} />
        <link rel="icon" href={imagePath(favicon?.data?.attributes?.url)} />
      </Head>
      <Header />
      <main>
        {!props.hideBanner && <PhoneVerification />}
        {children}
      </main>
      <Footer />
    </>
  );
};
