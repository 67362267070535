import {
  Arrow,
  CtaWrapper,
  Head,
  SubTitle,
  Title,
  Wrapper,
} from './Step.styled';
import type { MouseEvent, ReactNode } from 'react';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';

import { Anchor } from '../../../shared/Anchor';
import { OrderDeliveryType } from '../../../types/orders';
import type { state } from '../../../types/store/state';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';

interface Props {
  orderType: OrderDeliveryType;
  children: ReactNode;
  open: boolean;
  title: string;
  subtitle?: string | number;
  nextStep?: () => void | undefined;
}

export const Step = (props: Props) => {
  const { t } = useTranslation();
  const [stepOpen, setStepOpen] = useState<boolean>(false);

  const { hasDelivery } = useSelector(({ branchStore }: state) => branchStore);
  const primaryAddress = useSelector(({ addresses }: state) =>
    addresses.results.filter((address) => address.primary)
  );

  useEffect(() => setStepOpen(props.open), [props.open]);

  const handleStep = (event: MouseEvent) => {
    event.preventDefault();
    setStepOpen(!stepOpen);
  };

  const handleNext = () => {
    if (props.nextStep) {
      if (props.orderType === 'delivery' && hasDelivery) {
        if (!primaryAddress.length) {
          toast.error(t('checkout.step.action.continue.toast.noPrimaryAddress'));
          return;
        }
      }
      setStepOpen(false);
      props.nextStep();
    }
  };

  return (
    <Wrapper>
      <Head role="button" onClick={handleStep}>
        <div>
          <Title>{props.title}</Title>
          <SubTitle>{props.subtitle}</SubTitle>
        </div>
        <Arrow icon={stepOpen ? faAngleUp : faAngleDown} width={17} />
      </Head>
      {stepOpen ? (
        <div>
          {props.children}
          {props.nextStep ? (
            <CtaWrapper>
              <Anchor
                title={t('checkout.step.action.continue')}
                onClick={handleNext}
              />
            </CtaWrapper>
          ) : null}
        </div>
      ) : null}
    </Wrapper>
  );
};
