import { PalletteProps } from '../../types/theme/pallette';
import styled from 'styled-components';

export const Wrapper = styled.section`
  padding-bottom: 50px;
  @media ${({ theme }: PalletteProps) => theme.breakpoints.tabletL} {
    padding-bottom: 0px;
  }
  .categoriesButtonDiv {
    display: flex;
    justify-content: center;
    @media ${({ theme }: PalletteProps) => theme.breakpoints.tabletL} {
      display: none;
    }
    @media ${({ theme }: PalletteProps) => theme.breakpoints.tabletSmax} {
      display: none;
    }
  }
  .searchBarBody {
    margin-bottom: 20px;
    @media ${({ theme }: PalletteProps) => theme.breakpoints.laptopMmax} {
      display: flex;
      justify-content: left;
    }
    @media ${({ theme }: PalletteProps) => theme.breakpoints.laptopSmax} {
      justify-content: center;
    }
    @media ${({ theme }: PalletteProps) => theme.breakpoints.tabletSmax} {
      display: none;
    }
  }
`;

export const Title = styled.h2`
  font-size: 1rem;
  font-weight: 700;
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    font-size: 1.875rem;
  }
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }: PalletteProps) => theme.gray4};
  margin-bottom: 42px;
`;

export const ProductsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 8px;
  justify-items: center;
  padding: 0px 8px;
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    justify-items: space-between;
    grid-gap: 20px;
    padding: 0px 30px;
  }
  @media ${({ theme }: PalletteProps) => theme.breakpoints.tablet} {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 39px;
  }
  @media ${({ theme }: PalletteProps) => theme.breakpoints.tabletL} {
    padding: 0px;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 110px;
  }
  @media ${({ theme }: PalletteProps) => theme.breakpoints.laptopM} {
    grid-template-columns: repeat(4, 1fr);
  }
`;
