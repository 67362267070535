import {
  Card,
  ImageWrapper,
  Price,
  PriceDiscount,
  PriceWrapper,
  Title,
} from './Product.styled';
import { useEffect, useState } from 'react';

import { AddToCart } from '../../components/Cart';
import Image from 'next/image';
import Link from 'next/link';
import type { ProductType } from '../../types/product';
import type { state } from '../../types/store/state';
import { useSelector } from 'react-redux';

export const Product = (props: ProductType) => {
  const cart = useSelector(({ cart }: state) => cart);

  const [itemsOnCart, setItemsOnCart] = useState(0);

  const { product } = props;

  useEffect(() => {
    if (cart.branchStores.length) {
      const cartItems = cart.branchStores[0].products.filter(
        (item: ProductType) => item.product.storeBranchProductId === props.id
      );
      const items = cartItems.length ? cartItems[0].quantity : 0;
      setItemsOnCart(items);
    }
  }, [cart.productsCount, cart.orderCost?.productsCost]);

  return (
    <Card>
      <Link
        href={{
          pathname: '/product/[pid]',
          query: { pid: props.id },
        }}
      >
        <a>
          <ImageWrapper>
            <Image
              src={product.images[0].image}
              alt={product.name}
              layout="fill"
              objectFit="contain"
            />
          </ImageWrapper>
          <Title>{product.name}</Title>
        </a>
      </Link>
      <PriceWrapper>
        {product.discountPercent > 0 ? (
          <PriceDiscount>
            {`${product.currency.symbol}${product.fullPrice}`}
          </PriceDiscount>
        ) : null}
        <Price>{`${product.currency.symbol} ${product.price.toFixed(
          2
        )}`}</Price>
      </PriceWrapper>
      {/* <Units>{t('shared.product.unit.meassure')}Lb</Units> */}
      <AddToCart
        storeBranchProductId={props.product.storeBranchProductId}
        orderBranchProductId={props.id}
        customCuantity={itemsOnCart}
        added={itemsOnCart > 0}
        isDish={props.product.isDish}
        productUrl={props.id}
        showQuantity={true}
      />
    </Card>
  );
};
