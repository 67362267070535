import { PalletteProps } from '../../types/theme/pallette';
import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
  color: ${({ theme }: PalletteProps) => theme.gray2};
  padding-left: 44px;
  margin-bottom: 31px;
`;
