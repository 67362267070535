import { deleteRequest, postRequest } from '../services/http';

import { dataOptions } from './types/data';

export const addPromoCode = async (data: dataOptions) => {
  const response = await postRequest({
    url: `cart/${data.id}/promotionalcode/`,
    data: data.params,
    protected: true,
    urlGeoapi: '',
  });
  return response;
};

export const removePromoCode = async (data: dataOptions) => {
  const response = await deleteRequest({
    url: `cart/${data.id}/promotionalcode/`,
    data: data.params,
    protected: true,
    urlGeoapi: '',
  });
  return response;
};