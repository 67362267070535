import { ButtonHTMLAttributes, FC } from 'react';

import { ButtonElement } from './Button.styled';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  title: string,
}

export const Button: FC<ButtonProps> = ({ title, ...rest }) => {
  return <ButtonElement {...rest}>{title}</ButtonElement>;
};
