import {
  ButtonsWrapper,
  CancelButton,
  Row,
  RowContent,
  RowCta,
  RowTitle,
  SubmitButton,
} from './AccountRow.styled';
import { FormEvent, useEffect, useState } from 'react';

import { Input } from '../../shared/Input';
import { useTranslation } from 'next-i18next';

interface Props {
  title: string;
  name: string;
  content: string;
  ctaTitle: string;
  type: string;
  errors: Array<string>;
  ctaCallback: (key: string, value: string) => void;
}

export const AccountRow = (props: Props) => {
  const { t } = useTranslation();
  const { title, name, content, ctaTitle, type, errors, ctaCallback } = props;
  const [change, setChange] = useState(false);

  useEffect(() => {
    if (errors && errors.length) {
      setChange(true);
    }
  }, [errors]);
  const handleClick = () => setChange(!change);

  const handleSave = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const target = event.target as any;

    await ctaCallback(name, target[name].value);
    await setChange(false);
  };

  if (change) {
    return (
      <form onSubmit={handleSave}>
        <Row>
          <RowTitle>{title}</RowTitle>
          <Input
            name={name}
            placeholder={title}
            defaultValue={content}
            type={type}
            error={errors}
            required
          />
          <ButtonsWrapper>
            <SubmitButton type="submit">
              {t('shared.action.save')}
            </SubmitButton>
            <CancelButton onClick={handleClick}>
              {t('shared.action.cancel')}
            </CancelButton>
          </ButtonsWrapper>
        </Row>
      </form>
    );
  }

  return (
    <Row>
      <RowTitle>{title}</RowTitle>
      <RowContent>{content}</RowContent>
      <RowCta onClick={handleClick}>{ctaTitle}</RowCta>
    </Row>
  );
};
