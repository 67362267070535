export interface ObservationType {
  nonFieldErrors: Array<string>;
  orderBranchProduct: Array<string>;
  observation: Array<string>;
};

export const defaultObservationType = {
  nonFieldErrors: [],
  orderBranchProduct: [],
  observation: [],
};