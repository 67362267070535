import { FormEvent, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';
import { AddPaymentType, defaultAddPaymentType } from '../types/paymentValidations';
import { Button, Input } from '../../../shared';
import { addPaymentMethod } from '../../../store/paymentMethods/paymentMethodsSlice';
import { createPaymentMethod, getFortisClientToken } from '../../../api/paymentMethods';
import { ButtonsWrapper, CancelButton, Title, Wrapper } from './CreatePaymentMethod.styled';
import { ErrorText, FullRow, HalfRow } from '../Forms.styled';
import type { state as StateType } from '../../../types/store/state';

interface Props {
  setShowAddPayment: (value: boolean) => void;
}

export const CreatePaymentMethod = (props: Props) => {
  const { t } = useTranslation();
  const { primaryColor } = useSelector((state: StateType) => state.ui.pallette);
  const { paymentProcessor } = useSelector((state: StateType) => state.branchStore);
  const dispatch = useDispatch();

  const [errors, setErrors] = useState<AddPaymentType>(defaultAddPaymentType);

  useEffect(() => {
    if (paymentProcessor?.name === 'fortis') {
      handleFortisToken();
    }
  }, []);

  const handleSaveCard = async (paramsToSend: any) => {
    try {
      const paymentMethod = await toast.promise(
        createPaymentMethod({
          params: paramsToSend,
        }),
        {
          pending: {
            render() {
              return `${t('payment.create.toastify.pending')}`;
            },
            icon: '⚠️',
          },
          success: {
            render() {
              return `${t('payment.create.toastify.success')}`;
            },
            icon: '✅',
          },
          error: {
            render({data}: any) {
              if (data.response.status > 400) {
                return `${t('payment.create.toastify.error')}`;
              }
             return `${data.response.data.detail}`;
            },
            icon: '❗',
          }
        }
      );
      await dispatch(addPaymentMethod(paymentMethod));
      await props.setShowAddPayment(false);
    }
    catch (error: any) {
      await props.setShowAddPayment(false);
      throw new Error(error);
    }
  };

  const handleFortisToken = async () => {
    try {
      const fortrisCall = await getFortisClientToken({
        locationId: paymentProcessor.config.locationId,
        userId: paymentProcessor.config.userId,
        apiKey: paymentProcessor.config.apiKey,
        developerId: paymentProcessor.config.developerId,
      });
      const CommerceElements = await new (window as any).Commerce.elements(fortrisCall.data.client_token);

      await CommerceElements
        .create({
          container: '#payment_form',
          theme: 'default',
          showReceipt: false,
          environment: process.env.NEXT_PUBLIC_NODE_ENV === 'production' ? 'production' : 'sandbox',
          appearance: {
            colorButtonBackground: primaryColor,
            colorButtonActionBackground: primaryColor,
          },
        });

      await CommerceElements
        .on('done', async (result: any) => {
          const paramsToSend = {
            ticket_id: result.data.id,
          };

          await handleSaveCard(paramsToSend);
        });

      await CommerceElements
        .on('error', (error: any) => toast.error(`${t('payment.create.toastify.error')}`));
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const target = event.target as any;

    try {
      const cardDates = target.expMonth.value.split('-');

      const paramsToSend = {
        number: target.number.value,
        expMonth: cardDates[1],
        expYear: cardDates[0],
        cvc: target.cvc.value,
      };

      await handleSaveCard(paramsToSend);
    } catch (error: any) {
      if (error.response.status === 400) {
        setErrors({
          ...errors,
          ...error.response.data,
        });
        return;
      }
      throw new Error(error);
    }
  };

  if (paymentProcessor?.name === 'fortis') {
    return (
      <div id="payment_form" />
    );
  }


  return (
    <form onSubmit={onSubmit}>
      <Wrapper>
        <Title>
          {t('payment.addPayMethods.title')}
        </Title>
        <FullRow>
          <Input
            label={t('payment.nameOnCard.title')}
            name="nameOnCard"
            type="text"
            placeholder={t('payment.nameOnCard.placeholder')}
            error={errors.nameOnCard}
            required
          />
        </FullRow>
        <FullRow>
          <Input
            label={t('payment.cardNumber.title')}
            name="number"
            type="text"
            placeholder={t('payment.cardNumber.placeholder')}
            error={errors.number}
            required
          />
        </FullRow>
        <HalfRow>
          <Input
            label={t('payment.expDate.title')}
            name="expMonth"
            placeholder={t('payment.expDate.placeholder')}
            type="month"
            error={errors.expMonth}
            required
          />
          <Input
            label={t('payment.cvv.title')}
            name="cvc"
            placeholder={t('payment.cvv.placeholder')}
            type='password'
            error={errors.cvc}
            required
          />
        </HalfRow>
        {errors.nonFieldErrors.length ? <ErrorText>{errors.nonFieldErrors[0]}</ErrorText> : null}
      </Wrapper>
      <ButtonsWrapper>
        <CancelButton
          title={t('shared.action.cancel')}
          type="reset"
          onClick={() => props.setShowAddPayment(false)}
        />
        <Button
          title={t('shared.action.save')}
          type="submit"
        />
      </ButtonsWrapper>
    </form>
  );
};