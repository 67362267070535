import { PalletteProps } from '../../types/theme/pallette';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Label = styled.label`
  margin-bottom: 8px;
  color: black;
  font-size: 1rem;
  font-weight: 500;
`;

export const FormInput = styled.input`
  background-color: ${({ theme }: PalletteProps)=> theme.gray8};
  padding: 13px 24px;
  border-color: transparent;
  font-size: 1rem;
  font-weight: 300;
  border-radius: 10px;
  &:focus {
    outline-color: ${({ theme }: PalletteProps) => theme.primaryColor};
  }
  &::placeholder {
    color: ${({ theme }: PalletteProps) => theme.gray4};
  }
`;

export const ErrorText = styled.p`
  font-size: .875rem;
  margin: 8px 0;
  color: ${({ theme }: PalletteProps) => theme.red1};
`;
