import { AnchorButton } from '../../shared/Anchor/Anchor.styled';
import { PalletteProps } from '../../types/theme/pallette';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: flex;
  justify-items: center;
  grid-gap: 10px;
  box-shadow: 0px 0px 50px rgba(200, 200, 200, 0.2);
  border-radius: 5px;
  border: solid 1px ${({ theme }: PalletteProps) => theme.gray4};
  background-color: ${({ theme }: PalletteProps) => theme.white};
  padding: 14px 20px;
  margin: 10px 0;
  width: auto;
  ${AnchorButton} {
    padding: 8px 20px;
    min-width: 297px;
    width: auto;
  }
`;

export const Text = styled.p`
  color: ${({ theme }: PalletteProps) => theme.black};
  font-size: .875rem;
  font-weight: 500;
  text-align: center;
`;