import { deleteRequest, getRequest, postRequest, fortisPostRequest } from '../services/http';

import type { dataOptions } from './types/data';

export const retreivePaymentMethods = async () => {
  const response = await getRequest({
    url: 'payments/',
    protected: true,
    urlGeoapi: '',
  });

  return response;
};

export const createPaymentMethod = async (data: dataOptions) => {
  const response = await postRequest({
    url: 'payments/',
    data: data.params,
    protected: true,
    urlGeoapi: '',
  });

  return response;
};

export const removePaymentMethod = async (data: dataOptions) => {
  const response = await deleteRequest({
    url: `payments/${data.id}/`,
    protected: true,
    urlGeoapi: '',
  });

  return response;
};

export const setDefaultMethod = async (data: dataOptions) => {
  const response = await postRequest({
    url: `payments/${data.id}/make-primary/`,
    protected: true,
    urlGeoapi: '',
  });

  return response;
};

export const getFortisClientToken = async (data: dataOptions) => {
  const response = await fortisPostRequest({
    url: 'ticket/intention',
    locationId: data.locationId,
    userId: data.userId,
    apiKey: data.apiKey,
    developerId: data.developerId,
  });

  return response;
};
