import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PalletteProps } from '../../../types/theme/pallette';
import styled from 'styled-components';

export const Wrapper = styled.section`
  padding: 48px 70px;
  border-radius: 5px;
  box-shadow: 0px 0px 50px rgba(200, 200, 200, 0.2);
  background-color: ${({ theme }: PalletteProps) => theme.white};
  user-select: none;
`;

export const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    cursor: pointer;
  }
`;

export const Title = styled.h2`
  color: ${({ theme }: PalletteProps) => theme.gray2};
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 10px;
`;

export const SubTitle = styled.h3`
  color: ${({ theme }: PalletteProps) => theme.gray3};
  font-weight: 400;
  font-size: 1rem;
  margin-bottom: 25px;
`;

export const Arrow = styled(FontAwesomeIcon)`
  color: ${({ theme }: PalletteProps) => theme.gray4};
`;

export const CtaWrapper = styled.div`
  float: right;
  max-width: 300px;
  margin-top: 24px;
`;
