import { PalletteProps } from '../../types/theme/pallette';
import styled from 'styled-components';

export const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    display: grid;
    grid-template-columns: 1.5fr 4fr 1.5fr;
    grid-template-rows: 60px;
    align-items: center;
    grid-gap: 16px;
  }
`;

export const RowTitle = styled.p`
  font-size: 1rem;
  font-weight: 600;
  color: black;
`;

export const RowContent = styled.p`
  font-size: 1rem;
  font-weight: 300;
  color: black;
`;

export const RowCta = styled.button`
  font-size: 1rem;
  font-weight: 600;
  justify-self: right;
  position: absolute;
  right: 20px;
  background-color: transparent;
  border: none;
  color: ${({ theme }: PalletteProps) => theme.primaryColor};
  &:hover {
    cursor: pointer;
  }
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    position: relative;
    justify-self: center;
    right: 0;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const SubmitButton = styled.button`
  color: ${({ theme }: PalletteProps) => theme.primaryColor};
  border: none;
  background-color: transparent;
  font-size: 1rem;
  font-weight: bold;
  &:hover {
    cursor: pointer;
  }
`;
export const CancelButton = styled.button`
  color: ${({ theme }: PalletteProps) => theme.gray2};
  border: none;
  background-color: transparent;
  font-size: 1rem;
  &:hover {
    cursor: pointer;
  }
`;
