import { getRequest, postRequest } from '../services/http';

import type { dataOptions } from './types/data';

export const getOrders = async () => {
  const response = await getRequest({
    url: 'orders/?order_by=order_init_at',
    protected: true,
    urlGeoapi: '',
  });
  return response;
};

export const retrieveOrder = async (data: dataOptions) => {
  const response = await getRequest({
    url: `orders/${data.id}/`,
    protected: true,
    urlGeoapi: '',
  });
  return response;
};

export const retrieveOrderSummary = async (data: dataOptions) => {
  const response = await getRequest({
    url: `orders/${data.id}/summary/`,
    protected: true,
    urlGeoapi: '',
  });
  return response;
};

export const cancelOrder = async (data: dataOptions) => {
  const response = await postRequest({
    url: `orders/${data.id}/cancel/`,
    protected: true,
    urlGeoapi: '',
  });
  return response;
};
