import type { MouseEvent } from 'react';
import { TipsElement } from './Tips.styled';

type TipOptions = 0 | 10 | 15 | 20 | 25;
interface Props {
  option: TipOptions;
  tip: TipOptions;
  handleClick: (event: MouseEvent, elem: TipOptions) => void;
}
export const TipSquare = ({ option, tip, handleClick }: Props) => {

  return (
    <TipsElement
      selected={option === tip}
      key={option}
      onClick={(event: MouseEvent) => handleClick(event, option)}
    >
      {option}%
    </TipsElement>
  );
};
