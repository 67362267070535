import { ContentWrapper, SectionWrapper, Subtitle, Title, Wrapper } from './AccountInfo.styled';
import { useDispatch, useSelector } from 'react-redux';

import { AccountRow } from './AccountRow';
import type { EditUserType } from './types/editUserValidations';
import { defaultEditUserType } from './types/editUserValidations';
import { setUser } from '../../store/user/userSlice';
import type { state } from '../../types/store/state';
import { toast } from 'react-toastify';
import { updateUser } from '../../api/users';
import { useState } from 'react';
import { useTranslation } from 'next-i18next';

export const AccountInfo = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user = useSelector(({ user }: state) => user);
  const [errors, setErrors] = useState<EditUserType>(defaultEditUserType);

  const saveInfo = async (key: string, value: string) => {
    const editableUser = {
      username: user.username,
      firstName: user.firstName,
      lastName: user.lastName,
      phoneNumber: user.phoneNumber,
      email: user.email,
    };

    try {
      const editedUser = await toast.promise(
        updateUser({
          params: {
            ...editableUser,
            [key]: value,
          }
        }),
        {
          pending: {
            render() {
              return `${t('account.updateInfo.toastify.pending')}`;
            },
            icon: '⚠️',
          },
          success: {
            render() {
              return `${t('account.updateInfo.toastify.success')}`;
            },
            icon: '✅',
          },
          error: {
            render({data}: any) {
              if (data.response.status > 400) {
                return `${t('account.updateInfo.toastify.error')}`;
              }
             return `${data.response.data.phoneNumber}`;
            },
            icon: '❗',
          }
        }
      );
      await dispatch(setUser(editedUser));
    } catch (error: any) {
      if (error.response.status === 400) {
        setErrors({
          ...error,
          ...error.response.data,
        });
        return;
      }
      throw new Error();
    }
  };

  return (
    <Wrapper>
      <Title>
        {t('account.title')}
      </Title>
      <SectionWrapper>
        <Subtitle>
          {t('account.information.title')}
        </Subtitle>
        <ContentWrapper>
          <AccountRow
            title={t('account.firstName.title')}
            name="firstName"
            type="string"
            content={user.firstName}
            errors={errors.firstName}
            ctaTitle={t('account.action.change')}
            ctaCallback={saveInfo}
          />
          <AccountRow
            title={t('account.lastName.title')}
            name="lastName"
            type="string"
            content={user.lastName}
            errors={errors.lastName}
            ctaTitle={t('account.action.change')}
            ctaCallback={saveInfo}
          />
          <AccountRow
            title={t('account.userName.title')}
            name="username"
            type="string"
            content={user.username}
            errors={errors.username}
            ctaTitle={t('account.action.change')}
            ctaCallback={saveInfo}
          />
          <AccountRow
            title={t('account.email.title')}
            name="email"
            type="email"
            content={user.email}
            errors={errors.email}
            ctaTitle={t('account.action.change')}
            ctaCallback={saveInfo}
          />
          <AccountRow
            title={t('account.phoneNumber.title')}
            name="phoneNumber"
            type="tel"
            content={user.phoneNumber}
            errors={errors.phoneNumber}
            ctaTitle={t('account.action.change')}
            ctaCallback={saveInfo}
          />
        </ContentWrapper>
      </SectionWrapper>
    </Wrapper>
  );
};
