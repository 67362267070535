import { PalletteProps, pallette } from '../../types/theme/pallette';

import styled from 'styled-components';

type sizes = 'small' | 'medium' | 'big';
type variations = 'primary' | 'secondary' | 'alternative';
interface props extends PalletteProps {
  variation: variations;
  size: sizes;
  disabled?: boolean;
}

const getPadding = (size: sizes) => {
  switch (size) {
    case 'small':
      return '8px 30px';
    case 'medium':
      return '10px 30px';
    case 'big':
      return '16px 30px';
    default:
      return 'unset';
  }
};

const getBackground = (variation: variations, theme: pallette) => {
  switch (variation) {
    case 'primary':
      return theme.primaryColor;
    case 'secondary':
      return theme.lightGray;
    case 'alternative':
      return 'white';
    default:
      return 'unset';
  }
};

const getColor = (variation: variations, theme: pallette) => {
  switch (variation) {
    case 'primary':
      return 'transparent';
    case 'secondary':
      return theme.primaryColor;
    case 'alternative':
      return 'transparent';
    default:
      return 'unset';
  }
};

const getFontColor = (variation: variations, theme: pallette) => {
  switch (variation) {
    case 'primary':
      return 'white';
    case 'secondary':
      return theme.primaryColor;
    case 'alternative':
      return theme.darkGray;
    default:
      return 'unset';
  }
};

export const AnchorButton = styled.a`
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  padding: ${({ size }: props) => getPadding(size)};
  background-color: ${({ theme, variation, disabled }: props) =>
    disabled ? theme.secondaryColor : getBackground(variation, theme)};
  border: solid 1px
    ${({ theme, variation }: props) => getColor(variation, theme)};
  color: ${({ theme, variation }: props) => getFontColor(variation, theme)};
  border-radius: 10px;
  font-weight: 700;
  transition: ease all 200ms;
  box-shadow: 0px 0px 50px rgba(200, 200, 200, 0.2);
  text-transform: uppercase;
  .faIcon {
    margin-right: 8px;
  }
  &:hover {
    cursor: ${({ disabled }: props) => (disabled ? 'not-allowed' : 'pointer')};
    background-color: ${({ theme, disabled }: props) =>
      disabled ? theme.secondaryColor : theme.primaryColor};
    opacity: ${({ disabled }: props) => (disabled ? 0.9 : null)};
    color: ${({ theme }: PalletteProps) => theme.white};
  }
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobileL} {
    font-weight: 900;
  }
`;

export const ClickButton = styled.button`
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  padding: ${({ size }: props) => getPadding(size)};
  background-color: ${({ theme, variation, disabled }: props) =>
    disabled ? theme.secondaryColor : getBackground(variation, theme)};
  border: solid 1px
    ${({ theme, variation }: props) => getColor(variation, theme)};
  color: ${({ theme, variation }: props) => getFontColor(variation, theme)};
  border-radius: 10px;
  font-weight: 700;
  transition: ease all 200ms;
  box-shadow: 0px 0px 50px rgba(200, 200, 200, 0.2);
  text-transform: uppercase;
  .faIcon {
    margin-right: 8px;
  }
  &:hover {
    cursor: ${({ disabled }: props) => (disabled ? 'not-allowed' : 'pointer')};
    background-color: ${({ theme, disabled }: props) =>
      disabled ? theme.secondaryColor : theme.primaryColor};
    opacity: ${({ disabled }: props) => (disabled ? 0.9 : null)};
    color: ${({ theme }: PalletteProps) => theme.white};
  }
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobileL} {
    font-weight: 900;
  }
`;
