import {
  AccountMenuIcon,
  ContentSection,
  MenuItem,
  MenuItemAlt,
  MenuSection,
  SectionsWrapper,
  Title,
  Wrapper,
} from './AccountWrapper.styled';
import {
  faArrowRightFromBracket,
  faBagShopping,
  faCreditCard,
  faLocationDot,
  faUserAlt,
  faWrench,
} from '@fortawesome/free-solid-svg-icons';

import Link from 'next/link';
import { ReactNode } from 'react';
import { deleteToken } from '../../../services/auth';
import { state } from '../../../types/store/state';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';

interface Props {
  children: ReactNode;
  active:
    | 'account'
    | 'orders'
    | 'addresses'
    | 'payment-methods'
    | 'change-password';
}

export const AccountWrapper = ({ children, active }: Props) => {
  const { t } = useTranslation();
  const router = useRouter();
  const fullName = useSelector(
    ({ user }: state) => `${user.firstName} ${user.lastName}`
  );

  const closeSession = () => {
    deleteToken();
    if (router.pathname === '/') {
      location.reload();
      return;
    }
    router.push('/');
  };

  return (
    <Wrapper>
      <Title>
        {t('shared.account.userName.title.sayHello')}
        {fullName}
        {t('shared.account.userName.title.exclamation')}
      </Title>
      <SectionsWrapper>
        <MenuSection>
          <Link href="/account/" passHref>
            <MenuItem active={active === 'account'}>
              <AccountMenuIcon icon={faUserAlt} width={16} />
              <span>{t('shared.account.dropDownMenu.action.myAccount')}</span>
            </MenuItem>
          </Link>
          <Link href="/orders/" passHref>
            <MenuItem active={active === 'orders'}>
              <AccountMenuIcon icon={faBagShopping} width={16} />
              <span>{t('shared.account.dropDownMenu.action.myOrders')}</span>
            </MenuItem>
          </Link>
          <Link href="/account/addresses/" passHref>
            <MenuItem active={active === 'addresses'}>
              <AccountMenuIcon icon={faLocationDot} width={16} />
              <span>{t('shared.account.dropDownMenu.action.addresses')}</span>
            </MenuItem>
          </Link>
          <Link href="/account/payment-methods/" passHref>
            <MenuItem active={active === 'payment-methods'}>
              <AccountMenuIcon icon={faCreditCard} width={16} />
              <span>{t('shared.account.dropDownMenu.action.payMethods')}</span>
            </MenuItem>
          </Link>
          <Link href="/account/change-password/" passHref>
            <MenuItem active={active === 'change-password'}>
              <AccountMenuIcon icon={faWrench} width={16} />
              <span>{t('shared.account.dropDownMenu.action.changePass')}</span>
            </MenuItem>
          </Link>
          <MenuItemAlt onClick={closeSession}>
            <AccountMenuIcon icon={faArrowRightFromBracket} width={16} />
            <span>{t('shared.account.dropDownMenu.action.logOut')}</span>
          </MenuItemAlt>
        </MenuSection>
        <ContentSection>{children}</ContentSection>
      </SectionsWrapper>
    </Wrapper>
  );
};
