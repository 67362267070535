import { FC, InputHTMLAttributes } from 'react';
import { Input, Label, Span } from './Checkbox.styled';

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string,
  label?: string,
}

export const Checkbox: FC<CheckboxProps> = (props) => {
  const { name, label = '', ...rest } = props;

  return (
    <Label htmlFor={name}>
      <p dangerouslySetInnerHTML={{ __html: label }}/>
      <Input type="checkbox" {...rest} id={name} required />
      <Span />
    </Label>
  );
};
