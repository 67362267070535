import { PalletteProps } from '../../types/theme/pallette';
import styled from 'styled-components';

export const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }: PalletteProps) =>
    theme.footerBackgroundColor};
  color: ${({ theme }: PalletteProps) => theme.footerTextColor};
  margin-top: 30px;
`;

export const FooterInner = styled.div`
  width: 100%;
`;

export const FooterMain = styled.div`
  display: grid;
  padding: 37px;
  grid-gap: 20px;
  @media ${({ theme }: PalletteProps) => theme.breakpoints} {
    display: grid;
    justify-content: center;
    align-content: center;
    text-align: center;
    padding: 50px 0px;
    grid-gap: 50px;
  }
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    text-align: left;
    padding: 50px 30px;
    grid-gap: 50px;
    justify-content: space-evenly;
  }
  @media ${({ theme }: PalletteProps) => theme.breakpoints.tablet} {
    grid-template-columns: 1.5fr 1fr 1fr 1fr;
    text-align: left;
    padding: 50px 0px;
  }
`;

export const StoreLogo = styled.figure`
  width: 155px;
  height: 86px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    grid-column: 1 / span 3;
    margin-bottom: 15px;
  }
  @media ${({ theme }: PalletteProps) => theme.breakpoints.tablet} {
    grid-column: 1;
    margin-bottom: 0px;
  }
`;

export const FooterDivider = styled.div`
  height: 1px;
  background-color: ${({ theme }: PalletteProps) => theme.gray3};
`;

export const FooterBottom = styled.div`
  text-align: center;
  padding: 40px;
  color: ${({ theme }: PalletteProps) => theme.gray4};
`;

export const FooterColumnsTitles = styled.p`
  font-size: 22px;
  font-weight: 900;
  margin-bottom: 13px;
  color: ${({ theme }: PalletteProps) => theme.footerTextColor};
`;

export const FooterSocialMediaIcon = styled.a`
  color: ${({ theme }: PalletteProps) => theme.gray4};
  &:hover {
    color: ${({ theme }: PalletteProps) => theme.primaryColor};
  }
  margin-right: 16px;
`;

export const FooterBsDetailText = styled.p`
  font-size: 18px;
`;

export const FooterBsScheduleTableWrapper = styled.table`
  display: grid;
  justify-content: center;
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    justify-content: left;
  }
`;

export const FooterBsScheduleTr = styled.tr`
  font-size: 18px;
`;
