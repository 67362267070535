import {
  Action,
  Icon,
  Menu,
  MenuItem,
  Wrapper,
} from './AccountDropDown.styled';
import {
  faAngleDown,
  faAngleUp,
  faArrowRightFromBracket,
  faBagShopping,
  faCreditCard,
  faLocationDot,
  faUserAlt,
  faUserCircle,
  faWrench,
} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'next/link';
import { deleteToken } from '../../../services/auth';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useTranslation } from 'next-i18next';

export const AccountDropDown = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const [open, setOpen] = useState(false);

  const closeSession = () => {
    deleteToken();
    if (router.pathname === '/') {
      location.reload();
      return;
    }
    router.push('/');
  };

  return (
    <Wrapper>
      <Action onClick={() => setOpen(!open)}>
        <Icon icon={faUserCircle} width={37} />
        <Icon icon={open ? faAngleUp : faAngleDown} width={12} />
      </Action>
      {open && (
        <Menu onMouseLeave={() => setOpen(false)}>
          <Link href="/account/" passHref>
            <MenuItem>
              <FontAwesomeIcon icon={faUserAlt} width={12} className="faIcon" />
              {t('shared.account.dropDownMenu.action.myAccount')}
            </MenuItem>
          </Link>
          <Link href="/orders/" passHref>
            <MenuItem>
              <FontAwesomeIcon
                icon={faBagShopping}
                width={12}
                className="faIcon"
              />
              {t('shared.account.dropDownMenu.action.myOrders')}
            </MenuItem>
          </Link>
          <Link href="/account/addresses/" passHref>
            <MenuItem>
              <FontAwesomeIcon
                icon={faLocationDot}
                width={12}
                className="faIcon"
              />
              {t('shared.account.dropDownMenu.action.addresses')}
            </MenuItem>
          </Link>
          <Link href="/account/payment-methods/" passHref>
            <MenuItem>
              <FontAwesomeIcon
                icon={faCreditCard}
                width={12}
                className="faIcon"
              />
              {t('shared.account.dropDownMenu.action.payMethods')}
            </MenuItem>
          </Link>
          <Link href="/account/change-password/" passHref>
            <MenuItem>
              <FontAwesomeIcon icon={faWrench} width={12} className="faIcon" />
              {t('shared.account.dropDownMenu.action.changePass')}
            </MenuItem>
          </Link>
          <MenuItem onClick={closeSession}>
            <FontAwesomeIcon
              icon={faArrowRightFromBracket}
              width={12}
              className="faIcon"
            />
            {t('shared.account.dropDownMenu.action.logOut')}
          </MenuItem>
        </Menu>
      )}
    </Wrapper>
  );
};
