import { PalletteProps } from '../../types/theme/pallette';
import { rgba } from 'polished';
import styled from 'styled-components';

interface CompactProps extends PalletteProps {
  compact: boolean;
}

interface MainBannerProps extends PalletteProps {
  compact: boolean;
  image: string;
}

export const MainBannerWrapper = styled.section`
  background-color: ${({ theme, compact }: MainBannerProps) =>
    compact ? 'transparent' : rgba(theme.primaryColor, 0.2)};
  background-image: ${({ compact, image }: MainBannerProps) =>
    compact ? 'unset' : `url(${image})`};
  margin-bottom: 20px;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobileL} {
    margin-bottom: 20px;
  }
`;

export const ImageFilter = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${({ theme, compact }: CompactProps) =>
    !compact ? rgba(0, 0, 0, 0.4) : rgba(theme.black, 0.08)};
`;

export const Title = styled.h1`
  color: ${({ theme }: PalletteProps) => theme.white};
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 38px;
  text-align: center;
  padding: 0 15px;
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    font-size: 2.5rem;
    line-height: 48px;
    font-weight: 900;
  }
`;

export const InnerBannerWrapper = styled.div`
  width: 100%;
  padding: ${({ compact }: CompactProps) =>
    compact ? '15px 10px 15px 35px' : '20px 0'};
  display: flex;
  justify-content: center;
  align-items: ${({ compact }: CompactProps) =>
    compact ? 'flex-start' : 'center'};
  flex-direction: column;
  gap: 16px;
  @media ${({ theme }: PalletteProps) => theme.breakpoints.tablet} {
    padding: ${({ compact }: CompactProps) => (compact ? '35px 50px' : '75px 0')};
  }
`;

export const BreadcrumbWrapper = styled.div`
  display: flex;
  align-content: center;
`;

export const BreadCrumbTitle = styled.p`
  font-size: 0.875rem;
  line-height: 16px;
  color: ${({ theme, compact }: CompactProps) =>
    compact ? theme.gray2 : theme.white};
  font-weight: normal;
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    font-size: 1rem;
    line-height: 18px;
  }
`;

export const BreadcrumbConnector = styled.span`
  margin: 0 5px;
`;
