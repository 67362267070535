import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PalletteProps } from '../../types/theme/pallette';
import styled from 'styled-components';

interface props extends PalletteProps {
  disabled: boolean;
}
interface propsNumBox extends PalletteProps {
  active: boolean;
  dotsPageNum: boolean;
}

export const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 9px;
  margin-bottom: 158px;
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    grid-gap: 20px;
  }
`;

export const ArrowSquare = styled.a`
  display: flex;
  width: 35px;
  height: 35px;
  align-items: center;
  border: solid 1px
    ${({ theme, disabled }: props) =>
      disabled ? theme.gray4 : theme.primaryColor};
  padding: 8px 12px;
  &:hover {
    cursor: pointer;
  }
`;

export const ArrowIcon = styled(FontAwesomeIcon)`
  color: ${({ theme, disabled }: props) =>
    disabled ? theme.gray4 : theme.primaryColor};
`;

export const PageNumBox = styled.div`
  width: 40px;
  height: 35px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 8px 0px;
  font-size: 0.875rem;
  font-weight: 900;
  background-color: ${({ theme, active }: propsNumBox) =>
    active ? theme.primaryColor : theme.white};
  color: ${({ theme, active }: propsNumBox) =>
    active ? theme.white : theme.gray2};
  border: solid 1px ${({ theme, active }: propsNumBox) =>
    active ? 'transparent' : theme.gray6};
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
    border-color 0.2s ease-in-out;
  pointer-events: ${({ dotsPageNum }: propsNumBox) =>
    dotsPageNum ? 'none' : 'auto' };
  user-select: none;
  &:hover {
    cursor: pointer;
    background: ${({ theme }: PalletteProps) => theme.primaryColor};
    border: solid 1px ${({ theme }: PalletteProps) => theme.primaryColor};
    color: ${({ theme }: PalletteProps) => theme.white};
  }
`;
