import { AccountDropDown, SearchBar } from '../../components';
import {
  AccountState,
  HeaderWrapper,
  LogoAnchor,
  MenuSidebarButton,
  MiddleSection,
  RightSection,
} from './Header.styled';
import { faAngleUp, faBars, faGlobe } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';

import { Anchor } from '../../shared/Anchor';
import { CartIcon } from '../../components/Cart/CartIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Image from 'next/image';
import Link from 'next/link';
import ScrollToTop from 'react-scroll-to-top';
import { Sidebar } from '../Sidebar';
import { imagePath } from '../../utils';
import type { state } from '../../types/store/state';
import { useGetUserInfo } from '../../hooks/useGetUserInfo';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';

export const Header = () => {
  const { t } = useTranslation();
  const mainLogo = useSelector((state: state) => state.ui.mainLogo);
  const { locale } = useRouter();
  const [logged, setLogged] = useState(false);
  const [isFixed, setIsFixed] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(false);
  useGetUserInfo(setLogged);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 72 && !isFixed) {
        setIsFixed(true);
      } else if (window.scrollY === 0) {
        setIsFixed(false);
      }
    });
    return () => {
      window.removeEventListener('scroll', () => {});
    };
  }, []);

  return (
    <>
      <HeaderWrapper isFixed={isFixed}>
        <ScrollToTop
          smooth
          className="scroll-to-top"
          component={
            <FontAwesomeIcon icon={faAngleUp} className="backToTopIcon" />
          }
        />
        <Link href="/" passHref>
          <LogoAnchor>
            {mainLogo.data === null ? (
              'No image found'
            ) : (
              <Image
                src={imagePath(mainLogo?.attributes?.url)}
                alt="Image"
                width="200%"
                height="100%"
                objectFit="contain"
              />
            )}
          </LogoAnchor>
        </Link>
        <div>
          <MiddleSection>
            <SearchBar />
          </MiddleSection>
        </div>
        <RightSection>
          <CartIcon />
          <AccountState>
            {logged ? (
              <AccountDropDown />
            ) : (
              <Anchor url="/login" title={t('header.action.logged.logIn')} />
            )}
          </AccountState>
          <div className="languageLink">
            <Link href="#" locale={locale === 'en' ? 'es' : 'en'}>
              <a>
                <FontAwesomeIcon icon={faGlobe} />{' '}
                {locale === 'en' ? 'ES' : 'EN'}
              </a>
            </Link>
          </div>
          <MenuSidebarButton
            onClick={() => {
              setOpenSidebar(!openSidebar);
            }}
          >
            <FontAwesomeIcon icon={faBars} width={22} />
          </MenuSidebarButton>
        </RightSection>
      </HeaderWrapper>
      {openSidebar ? <Sidebar setOpenSidebar={setOpenSidebar} /> : null}
    </>
  );
};
