import { Button, Checkbox, Input } from '../../../shared';
import { ErrorText, FullRow, HalfRow, Title, Wrapper } from '../Forms.styled';
import { FormEvent, useState } from 'react';
import { ValidationRegisterType, defaultRegisterState } from '../types/userValidations';

import { EmailConfirm } from './EmailConfirm';
import { registerUser } from '../../../api/users';
import { useTranslation } from 'next-i18next';

export const RegisterForm = () => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState<ValidationRegisterType>(defaultRegisterState);
  const [registered, setRegistered] = useState({ email: '', active: false });

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setErrors(defaultRegisterState);

    const target = event.target as any;

    try {
      await registerUser({
        params: {
          firstName: target.firstName.value,
          lastName: target.lastName.value,
          username: target.email.value,
          email: target.email.value,
          password1: target.password1.value,
          password2: target.password2.value,
          termstype: 'client',
        },
      });
      await setRegistered({
        email: target.email.value,
        active: true,
      });
    } catch (error: any) {
      if (error.response.status === 400) {
        setErrors({
          ...errors,
          ...error.response.data,
        });
        return;
      }
      throw new Error(error);
    }
  };

  if (registered.active) {
    return <EmailConfirm email={registered.email} />;
  }

  return (
    <Wrapper>
      <Title>
        {t('register.account.title')}
      </Title>
      <form onSubmit={onSubmit}>
        <HalfRow>
          <Input
            label={t('register.firstName.title')}
            name="firstName"
            placeholder={t('register.firstName.placeholder')}
            error={errors.firstName}
            required
          />
          <Input
            label={t('register.lastName.title')}
            name="lastName"
            placeholder={t('register.lastName.placeholder')}
            error={errors.lastName}
            required
          />
        </HalfRow>
        <FullRow>
          <Input
            label={t('register.userName.title')}
            name="email"
            type="email"
            placeholder={t('register.email.placeholder')}
            error={errors.email}
            required
          />
        </FullRow>
        <HalfRow>
          <Input
            label={t('register.userPass.title')}
            name="password1"
            placeholder={t('register.userPass.placeholder')}
            type="password"
            error={errors.password1}
            required
          />
          <Input
            label={t('register.userPassConfirm.title')}
            name="password2"
            placeholder={t('register.userPassConfirm.placeholder')}
            type="password"
            error={errors.password2}
            required
          />
        </HalfRow>
        <Checkbox
          name="terms"
          label="I agree to the <a href='https://shapya.com/terms/'>Therms & Policy</a>"
        />
        {errors.nonFieldErrors.length ? <ErrorText>{errors.nonFieldErrors[0]}</ErrorText> : null}
        <Button title="Register" type="submit" />
      </form>
    </Wrapper>
  );
};
