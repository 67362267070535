import styled, { keyframes } from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PalletteProps } from '../../types/theme/pallette';

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const SearchInput = styled.input`
  border-radius: 10px;
  padding: 14px 21px;
  width: 100%;
  box-sizing: border-box;
  background-color: #f1f1f1;
  padding: 13px 24px;
  border-color: transparent;
  transition: ease all 200ms;
  font-size: 1rem;
  &:focus {
    outline-color: ${({ theme }: PalletteProps) => theme.primaryColor};
  }
`;

export const Icon = styled(FontAwesomeIcon)`
  color: ${({ theme }: PalletteProps) => theme.gray2};
  position: absolute;
  top: 12px;
  right: 12px;
  &:hover {
    cursor: pointer;
  }
`;

export const IconSpin = styled(FontAwesomeIcon)`
  color: ${({ theme }: PalletteProps) => theme.gray2};
  position: absolute;
  top: 12px;
  right: 12px;
  animation: ${rotate360} 900ms ease-in-out infinite;
`;

export const SearchBarWrapper = styled.div`
  display: none;
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    display: block;
    position: relative;
    max-width: 571px;
    width: 100%;
    z-index: 5;
  }
`;

export const SearchResults = styled.div`
  position: absolute;
  width: 100%;
  background: white;
  box-shadow: 0px 4px 50px rgba(200, 200, 200, 0.8);
  max-height: 75vh;
  overflow: scroll;
  scrollbar-width: thin;
  border-radius: 5px;
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    max-height: 342px;
  }
`;

export const MobileSearchIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    display: none;
  }
`;

export const MobileSearchIconButton = styled.button`
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background-color: transparent;
  height: 24px;
  width: 24px;
  color: ${({ theme }: PalletteProps) => theme.gray2};
  &:hover {
    cursor: pointer;
  }
`;

export const ModalWrapper = styled.div`
  display: block;
  position: fixed;
  z-index: 797;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.5);
  @-webkit-keyframes animatetop {
    from {
      top: -300px;
      opacity: 0;
    }
    to {
      top: 0;
      opacity: 1;
    }
  }
  @keyframes animatetop {
    from {
      top: -300px;
      opacity: 0;
    }
    to {
      top: 0;
      opacity: 1;
    }
  }
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    display: none;
  }
`;

export const ModalContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  padding: 70px 20px;
  margin: 0;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
`;

export const ModalCloseButton = styled.span`
  color: ${({ theme }: PalletteProps) => theme.gray2};
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 58px;
  padding: 0px 10px;
  &:hover,
  &:focus {
    color: ${({ theme }: PalletteProps) => theme.gray3};
    text-decoration: none;
    cursor: pointer;
  }
`;

export const ModalCloseIcon = styled(FontAwesomeIcon)`
  background: none;
  border: none;
  width: 2.3rem;
  height: 2.3rem;
  font-weight: 600;
  &:hover {
    cursor: pointer;
  }
`;

export const ModalSearchBarWrapper = styled.div`
  position: relative;
  width: 100%;
`;
