import { OrderStatusType } from '../types/orders';

export const getOrderStatusColor = (status: OrderStatusType) => {
  switch (status) {
    case 'CREATED':
      return '#2F80ED';
    case 'ON_THE_WAY':
      return '#DE52B7';
    case 'SELECTING_PRODUCTS':
      return '#FF9500';
    case 'BUYING':
      return '#FFCC00';
    case 'PAYING':
      return '#FFCC00';
    case 'DELIVERING':
      return '#5AC8FA';
    case 'DELIVERED':
      return '#34C759';
    case 'CANCELED':
      return '#EB5757';
    default:
      return '#C85B00';
  }
};
