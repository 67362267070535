import { PalletteProps } from '../../types/theme/pallette';
import styled from 'styled-components';

export const Wrapper = styled.div<{ $isResetPass?: boolean; }>`
  margin: ${props => props.$isResetPass ? '50px auto' : '0 auto'};
  max-width: 600px;
  width: 100%;
  padding: 31px 44px;
  background-color: ${({ theme }: PalletteProps) => theme.white};
  border-radius: 5px;
  box-shadow: 0px 0px 50px rgba(200, 200, 200, 0.2);
`;

export const Title = styled.h1`
  margin-bottom: 25px;
  font-size: 1.5rem;
  color: ${({ theme }: PalletteProps) => theme.gray2};
  text-align: center;
  font-weight: 600;
`;

export const Subtitle = styled.h2`
  font-size: black;
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 13px;
  text-align: center;
`;

export const FullRow = styled.div`
  width: 100%;
  margin-bottom: 21px;
  position: relative;
`;

export const HalfRow = styled.div`
  display: grid;
  grid-gap: 15px;
  margin-bottom: 21px;
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    grid-template-columns: 1fr 1fr;
  }
`;

export const ErrorText = styled.p`
  font-size: 0.875rem;
  margin-bottom: 16px;
  color: ${({ theme }: PalletteProps) => theme.red1};
`;

export const Pharagraph = styled.p<{ $isSent?: boolean; }>`
  color: ${({ theme }: PalletteProps) => theme.gray2};
  font-size: 0.875rem;
  margin-bottom: ${props => props.$isSent ? '0px' : '25px'};  
  text-align: center;
`;

export const RecoverPassAnchor = styled.a`
  color: ${({ theme }: PalletteProps) => theme.gray2};
  margin: 20px 0 10px 0;
  text-align: right;
  text-decoration: underline;
  display: block;
  font-size: 0.875rem;
`;
