import { OrderDisplayValueTag } from '../../../types/orders';
import { PalletteProps } from '../../../types/theme/pallette';
import styled from 'styled-components';
import { getOrderDisplayColor } from '../../../utils/getOrderDisplayTagColor';
import { rgba } from 'polished';

interface CustomProps extends PalletteProps {
  isLarge: boolean;
}
interface DisplayStatusProps {
  displayStatus?: OrderDisplayValueTag;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme, isLarge }: CustomProps) =>
    isLarge ? 'transparent' : theme.white};
  padding: ${({ isLarge }: CustomProps) => (isLarge ? 'none' : '48px 70px')};
  border-radius: 5px;
  box-shadow: ${({ isLarge }: CustomProps) =>
    isLarge ? 'none' : '0px 0px 50px rgba(200, 200, 200, 0.2);'};
  gap: 10px;
  .extraClass {
    width: 350px;
    padding: 30px;
    line-height: 1.5em;
    font-size: 14px !important;
    pointer-events: auto !important;
    &:hover {
      visibility: visible !important;
      opacity: 1 !important;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Subtotal = styled.p`
  color: ${({ theme }: PalletteProps) => theme.black};
  font-size: 1.125rem;
  font-weight: 500;
`;

export const CostItem = styled.p`
  color: ${({ theme }: PalletteProps) => theme.middleGray};
  font-size: 1rem;
  font-weight: 500;
`;

export const TotalItem = styled.p`
  color: ${({ theme }: PalletteProps) => theme.primaryColor};
  font-size: 1.25rem;
  font-weight: 700;
  margin-top: 38px;
`;

export const DisplayValue = styled.p<DisplayStatusProps>`
  color: ${({ displayStatus }) => displayStatus ? getOrderDisplayColor(displayStatus) : ''};
  background-color: ${({ displayStatus }) => displayStatus ? rgba(getOrderDisplayColor(displayStatus), 0.18) : ''};
  padding: ${({ displayStatus }) => displayStatus ? '3px 9px 4px 9px' : ''};
  border-radius:${({ displayStatus }) => displayStatus ? '5px' : ''};
  margin-right: ${({ displayStatus }) => displayStatus ? '-9px' : ''};
`;
