import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PalletteProps } from '../../../types/theme/pallette';
import styled from 'styled-components';

export const IconWrapper = styled.button`
  background: none;
  border: none;
  position: relative;
  margin-right: 25px;
  &:hover {
    cursor: pointer;
  }
  &:focus-visible {
    outline: none;
  }
`;

export const Icon = styled(FontAwesomeIcon)`
  color: ${({ theme }: PalletteProps) => theme.gray2};
`;

export const CountIcon = styled.span`
  background-color: ${({ theme }: PalletteProps) => theme.red1};
  border: 1px solid ${({ theme }: PalletteProps) => theme.white};
  color: ${({ theme }: PalletteProps) => theme.white};
  border-radius: 50%;
  padding: 2px 6px;
  position: absolute;
  top: -6px;
  right: -6px;
`;
