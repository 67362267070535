import {
  BreadCrumbTitle,
  BreadcrumbConnector,
  BreadcrumbWrapper,
  ImageFilter,
  InnerBannerWrapper,
  MainBannerWrapper,
  Title,
} from './TopMainBanner.styled';

import { ContainedProductRow } from '../../layouts';
import Link from 'next/link';
import { imagePath } from '../../utils';
import type { state } from '../../types/store/state';
import { useSelector } from 'react-redux';

interface breadcrumb {
  name: string;
  url: string;
}
interface Props {
  title: string;
  breadcrumbs: Array<breadcrumb>;
  isCompact: boolean;
}

export const TopMainBanner = (props: Props) => {
  const storeBackground = useSelector(
    (state: state) => state.ui.storeBackground
  );
  return (
    <MainBannerWrapper
      compact={props.isCompact}
      image={imagePath(storeBackground?.attributes?.url)}
    >
      <ImageFilter compact={props.isCompact} />
      {props.breadcrumbs.length ? (
        <ContainedProductRow>
          <InnerBannerWrapper compact={props.isCompact}>
            {!props.isCompact && <Title>{props.title}</Title>}
            <BreadcrumbWrapper>
              {props.breadcrumbs.map((item: breadcrumb, index: number) => {
                return (
                  <BreadCrumbTitle compact={props.isCompact} key={item.name}>
                    <Link href={item.url}>{item.name}</Link>
                    {index + 1 === props.breadcrumbs.length ? (
                      ''
                    ) : (
                      <BreadcrumbConnector>{'>'}</BreadcrumbConnector>
                    )}
                  </BreadCrumbTitle>
                );
              })}
            </BreadcrumbWrapper>
          </InnerBannerWrapper>
        </ContainedProductRow>
      ) : null}
    </MainBannerWrapper>
  );
};
