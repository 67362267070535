import { PalletteProps } from '../../../types/theme/pallette';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }: PalletteProps) => theme.white};
  padding: 48px;
  border-radius: 5px;
  box-shadow: 0px 0px 50px rgba(200,200,200,0.2);
  gap: 10px;
  label {
    margin-bottom: 0;
    padding-left: 0;
    padding-right: 25px;
    font-size: 1.125rem;
    span {
      left: unset;
      right: 0;
      width: 18px;
      height: 18px;
      &:after {
        top: 1px;
        left: 5px;
      }
    }
  }
`;