import { Fragment, useEffect, useState } from 'react';
import {
  OrdersList,
  Title,
  Wrapper,
} from './OrdersWrapper.styled';

import { OrderElement } from './OrderElement';
import type { OrdersList as OrdersListType } from '../../types/orders';
import { getOrders } from '../../api/orders';
import { useTranslation } from 'next-i18next';

const initialState = {
  count: 0,
  next: null,
  previous: null,
  results: [],
};

export const OrdersWrapper = () => {
  const { t } = useTranslation();
  const [orders, setOrders] = useState<OrdersListType>(initialState);

  useEffect(() => {
    getOrders()
      .then((res: OrdersListType) => setOrders(res))
      .catch((error: any) => {
        throw new Error(error);
      });
  }, []);

  return (
    <Wrapper>
      <Title>{t('orders.myOrders.title')}</Title>
      <OrdersList>
        {orders.results.map((order) => (
          <Fragment key={order.id}>
            <OrderElement {...order} />
          </Fragment>
        ))}
      </OrdersList>
    </Wrapper>
  );
};
