import {
  Wrapper as AccountMenuWrapper,
  Action,
  Menu,
} from '../AccountDropDown/AccountDropDown.styled';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PalletteProps } from '../../../types/theme/pallette';
import { rgba } from 'polished';
import styled from 'styled-components';

interface MenuItemProps extends PalletteProps {
  active: boolean;
}

export const Wrapper = styled.section`
  ${AccountMenuWrapper} {
    margin-left: 20px;
    margin-right: 10px;
    margin-bottom: 30px;
    @media ${({ theme }: PalletteProps) => theme.breakpoints.tablet} {
      display: none;
    }
  }
  ${Action} {
    padding: 0;
    top: 0;
    left: 0;
  }
  ${Menu} {
    top: 10px;
    left: -10px;
    border: 0;
    position: relative;
  }
`;

export const Title = styled.h1`
  display: flex;
  flex-direction: row;
  font-size: 1.875rem;
  margin-left: 10px;
  color: ${({ theme }: PalletteProps) => theme.gray2};
  margin-bottom: 10px;
  @media ${({ theme }: PalletteProps) => theme.breakpoints.tablet} {
    margin-left: 0px;
    margin-bottom: 42px;
  }
`;

export const SectionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  @media ${({ theme }: PalletteProps) => theme.breakpoints.tablet} {
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-gap: 43px;
  }
`;

export const ContentSection = styled.div``;

export const MenuSection = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 20px;
  margin-bottom: 20px;

  @media ${({ theme }: PalletteProps) => theme.breakpoints.tablet} {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

export const AccountMenuIcon = styled(FontAwesomeIcon)`
  margin-bottom: 5px;

  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    margin: 0 9px 0 0;
  }

  @media ${({ theme }: PalletteProps) => theme.breakpoints.tablet} {
    margin: 0 9px 0 0;
  }
`;

export const MenuItem = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding: 5px 4px;
  font-size: 0.5rem;
  border: solid 1px ${({ theme }: PalletteProps) => theme.lightGray};
  transition: ease all 200ms;
  background-color: transparent;
  cursor: pointer;
  color: ${({ theme }: PalletteProps) => theme.gray2};
  box-shadow: 0px 0px 50px rgba(200, 200, 200, 0.2);
  border-radius: 0px;
  color: ${({ theme, active }: MenuItemProps) =>
    active ? 'white' : theme.gray2};
  background-color: ${({ theme, active }: MenuItemProps) =>
    active ? theme.primaryColor : 'white'};

  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    padding: 12px 10px;
    font-size: 0.675rem;
    border-radius: 5px;
    width: auto;
    height: auto;
  }

  @media ${({ theme }: PalletteProps) => theme.breakpoints.tablet} {
    font-size: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    grid-gap: 9px;
    margin-bottom: 23px;
    box-shadow: 0px 0px 50px rgba(200, 200, 200, 0.2);
    border-radius: 5px;
    padding: 25px 5px;
    max-height: 85px;
    color: ${({ theme, active }: MenuItemProps) =>
      active ? 'white' : theme.gray2};
    border: none;
    background-color: ${({ theme, active }: MenuItemProps) =>
      active ? theme.primaryColor : 'white'};
    font-weight: 500;
    font-size: 1.125rem;
    transition: ease all 200ms;
    &:hover {
      cursor: pointer;
      background-color: ${({ theme }: PalletteProps) => theme.primaryColor};
      color: white;
    }
    width: 100%;
    &:hover {
      cursor: pointer;
      background-color: ${({ theme }: PalletteProps) =>
        rgba(theme.primaryColor, 0.15)};
      color: ${({ theme }: PalletteProps) => theme.gray2};
    }
  }
  span {
    display: none;
    @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
      display: block;
    }
  }
`;

export const MenuItemAlt = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 9px;
  border-radius: 5px;
  padding: 25px 37px;
  width: 100%;
  color: ${({ theme }: PalletteProps) => theme.primaryColor};
  background-color: transparent;
  border: none;
  font-weight: 500;
  font-size: 1.125rem;
  transition: ease all 200ms;
  &:hover {
    cursor: pointer;
  }
  display: none;
`;
