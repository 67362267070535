import {
  CostItem,
  Row,
  Subtotal,
  TotalItem,
  Wrapper,
  DisplayValue,
} from './OrderCost.styled';
import type {
  OrderCost as OrderCostType,
  OrderDeliveryType,
} from '../../../types/orders';

import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'next-i18next';

interface Props {
  orderCost: OrderCostType | null;
  isLarge: boolean;
  customTotal?: boolean;
  customTip?: number;
  customTax?: number;
  orderType?: OrderDeliveryType;
  walletAmmount?: number;
  useWallet?: boolean;
}

export const OrderCost = (
  props: Props = {
    orderCost: null,
    isLarge: false,
    customTip: 0,
    customTax: 0,
    customTotal: false,
    walletAmmount: 0,
    useWallet: false,
  }
) => {
  //TODO: Check order cost calculations
  const { t } = useTranslation();
  const { orderCost, customTip, customTax, orderType } = props;

  const isCustomTotal = props.customTotal;
  const isUseWallet = props.useWallet;
  const isDiscount = orderCost?.discount;
  const walletBalance = props.orderCost?.walletBalance || 0;

  if (!orderCost) {
    return null;
  }

  const calculateWallet = () => {
    if (!props.walletAmmount) {
      return 0;
    }

    if (orderCost.productsCost > props.walletAmmount) {
      return props.walletAmmount;
    }

    return orderCost.productsCost;
  };

  let shippingCost = orderType === 'pickup' ? 0 : orderCost.deliveryCost;

  const hasCustomValues = isCustomTotal != null && customTax != null && customTip != null;

  const customTotalSum = hasCustomValues
    ? orderCost.productsCost + orderCost.serviceFee + customTax + customTip
    : orderCost.productsCost + orderCost.serviceFee;

  let total = customTotalSum + (orderType === 'pickup' ? -shippingCost : shippingCost);

  if (isUseWallet) {
    total -= calculateWallet();
  }

  if (isDiscount) {
    total -= isDiscount;
  }

  if (orderType === 'delivery' && !isCustomTotal) {
    total = orderCost.total;
  }

  const calculatedWallet = isCustomTotal && isUseWallet ? calculateWallet() : 0;
  const displayValue = isCustomTotal ? calculatedWallet : walletBalance;

  return (
    <Wrapper isLarge={props.isLarge}>
      <Row>
        <Subtotal>{t('cart.shared.subtotal')}</Subtotal>
        <Subtotal>
          {orderCost?.currency.symbol}
          {orderCost?.productsCost.toFixed(2)}
        </Subtotal>
      </Row>
      <Row>
        <CostItem>{t('cart.shared.discount')}</CostItem>
        <CostItem>
          {orderCost?.currency.symbol}
          {orderCost?.discount}
        </CostItem>
      </Row>
      <Row>
        <CostItem>{t('cart.shared.wallet')}</CostItem>
        <CostItem>
          <DisplayValue displayStatus={isUseWallet || !isCustomTotal}>
            {orderCost?.currency.symbol}
            {displayValue}
          </DisplayValue>
        </CostItem>
      </Row>
      <Row data-tip data-for='serviceFeeDetails'>
        <CostItem>*{t('cart.shared.service')}</CostItem>
        <CostItem>
          {orderCost?.currency.symbol}
          {orderCost?.serviceFee}
        </CostItem>
      </Row>
      <ReactTooltip
        id="serviceFeeDetails"
        type="dark"
        place="top"
        effect="float"
        className='extraClass'
        delayHide={500}
      >
        {t('cart.shared.service.tooltip.disclaimertext')}
      </ReactTooltip>
      <Row>
        <CostItem>{t('cart.shared.estTax')}</CostItem>
        <CostItem>
          {orderCost?.currency.symbol}
          {customTax ? customTax.toFixed(2) : orderCost?.tax}
        </CostItem>
      </Row>
      <Row>
        <CostItem>{t('cart.shared.shipping')}</CostItem>
        <CostItem>
          {orderCost?.currency.symbol}
          {shippingCost}
        </CostItem>
      </Row>
      <Row data-tip data-for='tipDetails'>
        <CostItem>*{t('cart.shared.tip')}</CostItem>
        <CostItem>
          {orderCost?.currency.symbol}
          {customTip ? customTip.toFixed(2) : orderCost?.tip}
        </CostItem>
      </Row>
      <ReactTooltip
        id="tipDetails"
        type="dark"
        place="top"
        effect="float"
        className='extraClass'
        delayHide={500}
      >
        {t('cart.shared.service.tooltip.tipTootipText')}
      </ReactTooltip>
      <Row>
        <TotalItem>{t('cart.shared.total')}</TotalItem>
        <TotalItem>
          {orderCost?.currency.symbol}
          {Number(total).toFixed(2)}
        </TotalItem>
      </Row>
    </Wrapper>
  );
};
