import { Button } from '../../../shared/Button';
import { ButtonElement } from '../../../shared/Button/Button.styled';
import { PalletteProps } from '../../../types/theme/pallette';
import styled from 'styled-components';

export const Bottom = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  gap: 3px 23px;
  width: 100%;
`;

export const EditObsContainer = styled.div`
  width: 152px;
`;

export const ObsTextContainer = styled.div`
  height: 2.125rem;
  font-style: italic;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1rem;
  color: ${({ theme }: PalletteProps) => theme.gray3};
  span {
    font-size: .875rem;
    font-weight: 700;
    line-height: 1rem;
    font-style: normal;
    margin-right: 4px;
    color: ${({ theme }: PalletteProps) => theme.primaryColor};
  }
`;

export const ButtonsWrapper = styled.div`
  max-width: 100%;
  margin: 0 0 26px 0;
  display: flex;
  gap: 10px;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  ${ButtonElement} {
    min-width: 60px;
    min-height: 22px;
    width: 60px;
    height: 22px;
    font-weight: 400;
    font-size: .6875rem;
    text-transform: capitalize;
    font-family: "Inter";
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
      min-width: 80px;
      min-height: 32px;
      width: 80px;
      height: 32px;
      font-weight: 600;
      font-size: .875rem;
    }
  }
`;

export const CancelButton = styled(Button)`
  background-color: ${({ theme }: PalletteProps) => theme.gray4};
  color: ${({ theme }: PalletteProps) => theme.gray2};
  &:hover {
    background: ${({ theme }: PalletteProps) => theme.gray4};
  }
`;