import { Button, Input } from '../../../shared';
import {
  ErrorText,
  FullRow,
  RecoverPassAnchor,
  Title,
  Wrapper,
} from '../Forms.styled';
import { FormEvent, useState } from 'react';
import {
  ValidationLoginType,
  defaultLoginState,
} from '../types/userValidations';

import Link from 'next/dist/client/link';
import { setToken } from '../../../services/auth';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { userLogin } from '../../../api/users';

export const LoginForm = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const [errors, setErrors] = useState<ValidationLoginType>(defaultLoginState);

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setErrors(defaultLoginState);

    const target = event.target as any;

    try {
      const results = await userLogin({
        params: {
          username: target.username.value,
          password: target.password.value,
        },
      });
      await setToken(results.key);
      await router.push('/');
    } catch (error: any) {
      if (error.response.status === 400) {
        setErrors({
          ...error,
          ...error.response.data,
        });
        return;
      }
      throw new Error(error);
    }
  };

  return (
    <Wrapper>
      <Title>{t('login.account.title')}</Title>
      <form onSubmit={onSubmit}>
        <FullRow>
          <Input
            label={t('login.userName.title')}
            name="username"
            type="text"
            placeholder={t('login.userName.placeholder')}
            error={errors.username}
            required
          />
        </FullRow>
        <FullRow>
          <Input
            label={t('login.userPass.title')}
            name="password"
            placeholder={t('login.userPass.placeholder')}
            type="password"
            error={errors.username}
            required
          />
        </FullRow>
        {errors.nonFieldErrors.length ? (
          <ErrorText>{errors.nonFieldErrors[0]}</ErrorText>
        ) : null}
        <Button title={t('header.action.logged.logIn')} type="submit" />
        <Link href="/request-recover-password" passHref>
          <RecoverPassAnchor>
            {t('login.recover.action.forgotPassword')}
          </RecoverPassAnchor>
        </Link>
      </form>
    </Wrapper>
  );
};
