import {
  FooterBsScheduleTableWrapper,
  FooterBsScheduleTr,
  FooterColumnsTitles,
} from './Footer.styled';

import React from 'react';
import dayjs from 'dayjs';
import type { state } from '../../types/store/state';
import { useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';

export const FooterBsSchedule = () => {
  const { t } = useTranslation();
  const branchStoreSchedule = useSelector(
    (storeSchedule: state) => storeSchedule.branchStoreSchedule
  );

  return (
    <div>
      <FooterColumnsTitles>{t('footer.schedule.title')}</FooterColumnsTitles>
      <FooterBsScheduleTableWrapper>
        <table>
          <tbody>
            {branchStoreSchedule.map((schedule: any) => (
              <FooterBsScheduleTr key={schedule.id}>
                <th align="left">
                  {schedule.day === 'Monday'
                    ? 'Mo'
                    : schedule.day === 'Tuesday'
                    ? 'Tu'
                    : schedule.day === 'Wednesday'
                    ? 'We'
                    : schedule.day === 'Thursday'
                    ? 'Th'
                    : schedule.day === 'Friday'
                    ? 'Fr'
                    : schedule.day === 'Saturday'
                    ? 'Sa'
                    : schedule.day === 'Sunday'
                    ? 'Su'
                    : ''}
                  :
                </th>
                <td>&nbsp;&nbsp;</td>
                <td>
                  {dayjs(schedule.fromTime).format('HH:mm')}
                  {t('footer.schedule.description.to')}{' '}
                  {dayjs(schedule.toTime).format('HH:mm')}
                </td>
              </FooterBsScheduleTr>
            ))}
          </tbody>
        </table>
      </FooterBsScheduleTableWrapper>
    </div>
  );
};
