import {
  AnchorButton,
  ClickButton,
} from '../../../shared/Anchor/Anchor.styled';

import { PalletteProps } from '../../../types/theme/pallette';
import styled from 'styled-components';

export const Main = styled.section``;

export const Top = styled.div`
  margin-bottom: 25px;
  padding-left: 25px;
`;

export const TopRow = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  margin-bottom: 13px;
  align-items: center;
  ${ClickButton} {
    padding: 9px 8px;
    margin-right: 20px;
    font-size: 0.625rem;
    width: 130px;
    .faIcon {
      margin-right: 8px;
      width: 14px;
    }
  }
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    padding: 0px 39px 0px 87px;
    ${ClickButton} {
    font-size: 0.75rem;
    width: 176px;
  }
  }
  @media ${({ theme }: PalletteProps) => theme.breakpoints.tablet} {
    grid-template-columns: 10fr 3fr;
  }
`;

export const Title = styled.h1`
  font-size: 1.5625rem;
  font-weight: 900;
  color: ${({ theme }: PalletteProps) => theme.gray2};
  @media ${({ theme }: PalletteProps) => theme.breakpoints.tablet} {
    font-size: 1.875rem;
  }
`;

export const Subtitle = styled.p`
  font-size: 1rem;
  font-weight: 300;
  color: ${({ theme }: PalletteProps) => theme.gray2};
  span {
    color: ${({ theme }: PalletteProps) => theme.primaryColor};
    font-weight: 500;
  }
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    padding-left: 87px;
  }
`;

export const Content = styled.div`
  .bottomContent {
    padding: 30px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    ${AnchorButton} {
      width: 260px;
    }
    .keepBuyingButton {
      margin-top: 20px;
      ${AnchorButton} {
        text-transform: capitalize;
        font-weight: 700;
      }
    }
    .faIcon {
      margin-right: 8px;
    }
    @media ${({ theme }: PalletteProps) => theme.breakpoints.tablet} {
      padding: 0px 0px;
      display: flex;
      ${AnchorButton} {
        width: 100%;
      }
    }
  }
  @media ${({ theme }: PalletteProps) => theme.breakpoints.tablet} {
    padding: 0px 0px;
    display: grid;
    grid-template-columns: 8fr 4fr;
    grid-gap: 45px;
  }
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    padding: 20px 20px;
    ${AnchorButton} {
      width: 346px;
      justify-content: center;
    }
  }
`;

export const CartItems = styled.div`
  background-color: ${({ theme }: PalletteProps) => theme.white};
  box-shadow: 0px 0px 50px rgba(200, 200, 200, 0.2);
  border-radius: 5px;
  padding: 30px;
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    padding: 45px;
  }
`;

export const SubtotalWrapper = styled.div`
  background-color: ${({ theme }: PalletteProps) => theme.white};
  box-shadow: 0px 0px 50px rgba(200, 200, 200, 0.2);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 35px 70px;
  margin-bottom: 14px;
  width: 100%;
`;

export const SubtotalText = styled.p`
  color: ${({ theme }: PalletteProps) => theme.primaryColor};
  font-size: 1.125rem;
  font-weight: 700;
  @media ${({ theme }: PalletteProps) => theme.breakpoints.mobile} {
    font-size: 1.25rem;
  }
`;

export const Subtotal = styled.p`
  color: ${({ theme }: PalletteProps) => theme.primaryColor};
  font-size: 1.25rem;
  font-weight: 700;
`;
